import React, { useState, useEffect, useCallback } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  TextField, 
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  styled,
  Snackbar,
  Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import dayjs from 'dayjs';
import { 
  listCourses, 
  listTrainers, 
  register, 
  createStudentCourse,
  updateStudentCourse,
  listStudentCourses,
  listCoordinators,
  createCourseHold,
  assignCoordinator,
  removeCoordinator,
} from '../api';

// Styled components for glass effect
const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    backdropFilter: 'blur(8px)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  },
}));

const GlassTableContainer = styled(TableContainer)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.3)',
  borderRadius: theme.shape.borderRadius,
}));

function AddEditStudentModal({ open, onClose, student, onSave }) {
  const [studentData, setStudentData] = useState({
    username: '',
    email: '',
    password: '',
    full_name: '',
  });
  const [courses, setCourses] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [studentCourses, setStudentCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [editingCourse, setEditingCourse] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const [selectedCoordinator, setSelectedCoordinator] = useState('');
  const [courseHold, setCourseHold] = useState({
    start_date: null,
    end_date: null,
    reason: '',
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      const [coursesResponse, trainersResponse] = await Promise.all([
        listCourses(),
        listTrainers(),
      ]);
      setCourses(coursesResponse.data || []);
      setTrainers(trainersResponse.data || []);

      if (student && (student.id || student.user?.id)) {
        const studentId = student.user?.id || student.id;
        const studentCoursesResponse = await listStudentCourses({ student: studentId });
        setStudentCourses(studentCoursesResponse.data || []);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
      setCourses([]);
      setTrainers([]);
      setStudentCourses([]);
    } finally {
      setLoading(false);
    }
  }, [student]);

  const fetchCoordinators = useCallback(async () => {
    try {
      const coordinatorsResponse = await listCoordinators();
      setCoordinators(coordinatorsResponse || []);
    } catch (error) {
      console.error('Error fetching coordinators:', error);
      setError('Failed to fetch coordinators. Please try again later.');
      setCoordinators([]);
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetchData();
      fetchCoordinators();
    }
  }, [open, fetchData, fetchCoordinators]);

  useEffect(() => {
    if (student) {
      setStudentData({
        username: student.username || student.user?.username || '',
        email: student.email || student.user?.email || '',
        full_name: student.full_name || student.user?.full_name || '',
      });
    } else {
      setStudentData({
        username: '',
        email: '',
        password: '',
        full_name: '',
      });
      setStudentCourses([]);
    }
  }, [student]);

  const handleStudentDataChange = (e) => {
    const { name, value } = e.target;
    setStudentData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  const handleAssignCoordinator = async (userId, coordinatorId) => {
    try {
      if (coordinatorId) {
        await assignCoordinator(userId, coordinatorId);
        setSnackbar({ open: true, message: 'Coordinator assigned successfully', severity: 'success' });
      } else {
        await removeCoordinator(userId);
        setSnackbar({ open: true, message: 'Coordinator removed successfully', severity: 'success' });
      }
      fetchData(); // Refresh data after assignment
    } catch (error) {
      console.error('Error assigning/removing coordinator:', error);
      setSnackbar({ open: true, message: 'Failed to assign/remove coordinator. Please try again.', severity: 'error' });
    }
  };
  const handleEditCourse = (course) => {
    setEditingCourse({
      id: course.id,
      course_id: course.course?.id || '',
      trainer_id: course.trainer?.id || '',
      coordinator_id: course.coordinator?.id || '',
      start_date: course.start_date ? dayjs(course.start_date) : null,
      end_date: course.end_date ? dayjs(course.end_date) : null,
      class_time: course.class_time || '',
    });
    setSelectedCourse(course.course);
  };

  const handleAddNewCourse = () => {
    setEditingCourse({
      course_id: '',
      trainer_id: '',
      coordinator_id: '',
      start_date: null,
      end_date: null,
      class_time: '',
    });
    setSelectedCourse(null);
  };

  const handleCourseChange = (e) => {
    const { name, value } = e.target;
    if (name === 'course_id') {
      const course = courses.find(c => c.id === parseInt(value));
      setSelectedCourse(course);
      setEditingCourse(prevCourse => ({
        ...prevCourse,
        course_id: parseInt(value),
        trainer_id: course?.is_group_class ? '' : prevCourse.trainer_id,
        class_time: course?.is_group_class ? '' : prevCourse.class_time,
      }));
    } else {
      setEditingCourse(prevCourse => ({
        ...prevCourse,
        [name]: value
      }));
    }
  };

  const handleDateChange = (name, value) => {
    setEditingCourse(prevCourse => ({
      ...prevCourse,
      [name]: value
    }));
  };

  const handleSaveCourse = async () => {
    setLoading(true);
    setError('');

    try {
      const studentId = student.user?.id || student.id;
      const courseData = {
        student: studentId,
        course_id: editingCourse.course_id,
        start_date: editingCourse.start_date?.format('YYYY-MM-DD'),
        end_date: editingCourse.end_date?.format('YYYY-MM-DD'),
        coordinator_id: editingCourse.coordinator_id || null,
      };

      if (selectedCourse && !selectedCourse.is_group_class) {
        courseData.trainer_id = editingCourse.trainer_id || null;
        courseData.class_time = editingCourse.class_time;
      }

      let response;
      if (editingCourse.id) {
        response = await updateStudentCourse(editingCourse.id, courseData);
      } else {
        response = await createStudentCourse(courseData);
      }

      if (response && response.data) {
        setStudentCourses(prevCourses => {
          const updatedCourses = editingCourse.id
            ? prevCourses.map(c => c.id === editingCourse.id ? response.data : c)
            : [...prevCourses, response.data];
          return updatedCourses;
        });
        setEditingCourse(null);
        setSelectedCourse(null);
        setSnackbar({ open: true, message: 'Course saved successfully', severity: 'success' });
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error saving student course:', error);
      if (error.response && error.response.data) {
        const errorMessages = Object.entries(error.response.data)
          .map(([key, value]) => `${key}: ${value.join(', ')}`)
          .join('; ');
        setError(`Failed to save student course. ${errorMessages}`);
      } else {
        setError(`Failed to save student course. ${error.message || 'Please try again.'}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      let userId;

      if (student) {
        userId = student.user?.id || student.id;
      } else {
        const newUser = await register({
          ...studentData,
          role: 'STUDENT',
        });
        if (newUser && newUser.data && newUser.data.id) {
          userId = newUser.data.id;
        } else {
          throw new Error('Failed to create new user');
        }
      }

      onSave({ ...studentData, id: userId, courses: studentCourses });
      onClose();
    } catch (error) {
      console.error('Error saving student:', error);
      setError(`Failed to save student. ${error.message || 'Please try again.'}`);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyCourseHold = async () => {
    if (!editingCourse.id) {
      setError('Please save the course before applying a hold.');
      return;
    }

    try {
      const response = await createCourseHold({
        student_course: editingCourse.id,
        start_date: courseHold.start_date.format('YYYY-MM-DD'),
        end_date: courseHold.end_date.format('YYYY-MM-DD'),
        reason: courseHold.reason,
      });

      if (response && response.data) {
        setSnackbar({ open: true, message: 'Course hold applied successfully', severity: 'success' });
        setCourseHold({ start_date: null, end_date: null, reason: '' });
      }
    } catch (error) {
      console.error('Error applying course hold:', error);
      setError(`Failed to apply course hold. ${error.message || 'Please try again.'}`);
    }
  };

  const getTrainerName = (trainerId) => {
    if (!trainerId) return 'Not assigned';
    const trainer = trainers.find(t => t.user?.id === trainerId || t.id === trainerId);
    return trainer ? (trainer.user?.full_name || trainer.user?.username || `Trainer ${trainer.id}`) : 'Not assigned';
  };

  const getCoordinatorName = (coordinatorId) => {
    if (!coordinatorId) return 'Not assigned';
    const coordinator = coordinators.find(c => c.id === coordinatorId);
    return coordinator ? coordinator.name : 'Not assigned';
  };

  return (
    <GlassDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{student ? 'Edit Student' : 'Add Student'}</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            {!student && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Username"
                    name="username"
                    value={studentData.username}
                    onChange={handleStudentDataChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    type="email"
                    value={studentData.email}
                    onChange={handleStudentDataChange}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Password"
                    name="password"
                    type="password"
                    value={studentData.password}
                    onChange={handleStudentDataChange}
                    required
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Full Name"
                name="full_name"
                value={studentData.full_name}
                onChange={handleStudentDataChange}
                required
              />
            </Grid>
          </Grid>

          <Typography variant="h6" style={{ marginTop: '2rem', marginBottom: '1rem' }}>Enrolled Courses</Typography>
          {studentCourses && studentCourses.length > 0 ? (
            <GlassTableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Course</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Trainer</TableCell>
                    <TableCell>Coordinator</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Class Time</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {studentCourses.map((course) => (
                    <TableRow key={course.id}>
                      <TableCell>{course.course?.name || 'Unknown Course'}</TableCell>
                      <TableCell>{course.course?.is_group_class ? 'Group' : 'Individual'}</TableCell>
                      <TableCell>{course.trainer ? course.trainer.full_name : 'Not assigned'}</TableCell>
                      <TableCell>{course.coordinator ? getCoordinatorName(course.coordinator.id) : 'Not assigned'}</TableCell>
                      <TableCell>{course.start_date ? dayjs(course.start_date).format('MMM D, YYYY') : 'N/A'}</TableCell>
                      <TableCell>{course.end_date ? dayjs(course.end_date).format('MMM D, YYYY') : 'N/A'}</TableCell>
                      <TableCell>{course.class_time || 'Not set'}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleEditCourse(course)}>
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </GlassTableContainer>
          ) : (
            <Typography>No courses enrolled yet.</Typography>
          )}
          
          <Button 
            startIcon={<AddIcon />} 
            onClick={handleAddNewCourse}
            style={{ marginTop: '1rem' }}
          >Add New Course
          </Button>

          {editingCourse && (
            <Grid container spacing={2} style={{ marginTop: '2rem' }}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {editingCourse.id ? 'Edit Course' : 'Add New Course'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Course</InputLabel>
                  <Select
                    name="course_id"
                    value={editingCourse.course_id || ''}
                    onChange={handleCourseChange}
                    required
                  >
                    {courses && courses.length > 0 ? (
                      courses.map((course) => (
                        <MenuItem key={course.id} value={course.id}>
                          {course.name} ({course.is_group_class ? 'Group' : 'Individual'})
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No courses available</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Course Start Date"
                  value={editingCourse.start_date}
                  onChange={(newValue) => handleDateChange('start_date', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Course End Date"
                  value={editingCourse.end_date}
                  onChange={(newValue) => handleDateChange('end_date', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                />
              </Grid>
              {selectedCourse && !selectedCourse.is_group_class && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel>Trainer</InputLabel>
                      <Select
                        name="trainer_id"
                        value={editingCourse.trainer_id || ''}
                        onChange={handleCourseChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {trainers && trainers.length > 0 ? (
                          trainers.map((trainer) => (
                            <MenuItem key={trainer.user?.id || trainer.id} value={trainer.user?.id || trainer.id}>
                              {trainer.user?.full_name || trainer.user?.username || `Trainer ${trainer.id}`}
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem disabled>No trainers available</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Class Time"
                      name="class_time"
                      type="time"
                      value={editingCourse.class_time || ''}
                      onChange={handleCourseChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
        <FormControl fullWidth>
          <InputLabel>Coordinator</InputLabel>
          <Select
            value={selectedCoordinator}
            onChange={(e) => setSelectedCoordinator(e.target.value)}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {coordinators.map((coordinator) => (
              <MenuItem key={coordinator.id} value={coordinator.id}>
                {coordinator.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={() => handleAssignCoordinator(student.id, selectedCoordinator)}
          color="primary"
          variant="contained"
          style={{ marginTop: '1rem' }}
        >
          {selectedCoordinator ? 'Assign Coordinator' : 'Remove Coordinator'}
        </Button>
      </Grid>
              <Grid item xs={12}>
                <Button onClick={handleSaveCourse} color="primary" variant="contained" disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : (editingCourse.id ? 'Update Course' : 'Add Course')}
                </Button>
                <Button onClick={() => {setEditingCourse(null); setSelectedCourse(null);}} color="secondary" style={{ marginLeft: '1rem' }}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          )}

          {editingCourse && editingCourse.id && (
            <Grid container spacing={2} style={{ marginTop: '2rem' }}>
              <Grid item xs={12}>
                <Typography variant="h6">Course Hold</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Hold Start Date"
                  value={courseHold.start_date}
                  onChange={(newValue) => setCourseHold(prev => ({ ...prev, start_date: newValue }))}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label="Hold End Date"
                  value={courseHold.end_date}
                  onChange={(newValue) => setCourseHold(prev => ({ ...prev, end_date: newValue }))}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Hold Reason"
                  value={courseHold.reason}
                  onChange={(e) => setCourseHold(prev => ({ ...prev, reason: e.target.value }))}
                  multiline
                  rows={2}
                />
              </Grid>
              <Grid item xs={12}>
                <Button 
                  onClick={handleApplyCourseHold} 
                  color="secondary" 
                  variant="contained" 
                  disabled={!courseHold.start_date || !courseHold.end_date || !courseHold.reason}
                >
                  Apply Course Hold
                </Button>
              </Grid>
            </Grid>
          )}

          {error && (
            <Typography color="error" style={{ marginTop: '1rem' }}>
              {error}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" color="primary" variant="contained" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Save Student'}
          </Button>
        </DialogActions>
      </form>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </GlassDialog>
  );
}

export default AddEditStudentModal;