import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Input,
  Snackbar,
  Alert
} from '@mui/material';
import { updateTrainer,updateUser,updateTrainerUser,createTrainer, createUser, handleApiError } from '../api';

function AddEditTrainerModal({ open, onClose, trainer, onSave }) {
  const [formData, setFormData] = useState({
    display_name: '',
    email: '',
    username: '',
    phone: '',
    contract_type: '',
    approved_hours: '',
    salary: '',
    bank_name: '',
    account_number: '',
    ifsc_code: '',
    google_meet_link: '',
    zoom_meeting_link: ''
  });
  const [qrImage, setQrImage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  useEffect(() => {
    if (trainer && trainer.data) {
      setFormData({
        display_name: trainer.data.display_name || '',
        email: trainer.data.user_email || '',
        username: trainer.data.user_login || '',
        phone: trainer.data.phone || '',
        contract_type: trainer.data.contract_type || '',
        approved_hours: trainer.data.approved_hours || '',
        salary: trainer.data.salary || '',
        bank_name: trainer.data.bank_name || '',
        account_number: trainer.data.account_number || '',
        ifsc_code: trainer.data.ifsc_code || '',
        google_meet_link: trainer.data.google_meet_link || '',
        zoom_meeting_link: trainer.data.zoom_meeting_link || ''
      });
    } else {
      setFormData({
        display_name: '',
        email: '',
        username: '',
        phone: '',
        contract_type: '',
        approved_hours: '',
        salary: '',
        bank_name: '',
        account_number: '',
        ifsc_code: '',
        google_meet_link: '',
        zoom_meeting_link: ''
      });
    }
    setQrImage(null);
  }, [trainer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleQrImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setQrImage(e.target.files[0]);
    }
  };

  const handleSave = async () => {
    try {
      if (!trainer || !trainer.user || !trainer.user.id) {
        throw new Error('Invalid trainer data');
      }
  
      const userDataToSend = {};
      if (formData.phone !== trainer.user.phone) userDataToSend.phone = formData.phone;
      if (formData.city !== trainer.user.city) userDataToSend.city = formData.city;
      if (formData.about_me !== trainer.user.about_me) userDataToSend.about_me = formData.about_me;
      if (formData.date_of_birth !== trainer.user.date_of_birth) userDataToSend.date_of_birth = formData.date_of_birth;
  
      const trainerDataToSend = {};
      const trainerFields = ['contract_type', 'approved_hours', 'salary', 'bank_name', 'account_number', 'ifsc_code', 'google_meet_link', 'zoom_meeting_link'];
      trainerFields.forEach(field => {
        if (formData[field] !== trainer[field]) trainerDataToSend[field] = formData[field];
      });
  
      if (qrImage) {
        trainerDataToSend.upi_qr_image = qrImage;
      }
  
      // Update user data
      if (Object.keys(userDataToSend).length > 0) {
        await updateTrainerUser(trainer.user.id, userDataToSend);
      }
  
      // Update trainer data
      if (Object.keys(trainerDataToSend).length > 0 || qrImage) {
        await updateTrainer(trainer.id, trainerDataToSend);
      }
  
      onSave({ ...trainer, ...trainerDataToSend, user: { ...trainer.user, ...userDataToSend } });
      setSnackbar({ open: true, message: 'Trainer updated successfully', severity: 'success' });
      onClose();
    } catch (error) {
      console.error('Error saving trainer:', error);
      const errorMessage = handleApiError(error);
      setSnackbar({ open: true, message: `Error: ${errorMessage}`, severity: 'error' });
    }
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{trainer ? 'Edit Trainer' : 'Add Trainer'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Display Name"
                name="display_name"
                value={formData.display_name}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Contract Type</InputLabel>
                <Select
                  name="contract_type"
                  value={formData.contract_type}
                  onChange={handleChange}
                >
                  <MenuItem value="FREELANCER">Freelancer</MenuItem>
                  <MenuItem value="SALARIED">Salaried</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Approved Hours"
                name="approved_hours"
                type="number"
                value={formData.approved_hours}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Salary"
                name="salary"
                type="number"
                value={formData.salary}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Bank Name"
                name="bank_name"
                value={formData.bank_name}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Account Number"
                name="account_number"
                value={formData.account_number}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="IFSC Code"
                name="ifsc_code"
                value={formData.ifsc_code}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Google Meet Link"
                name="google_meet_link"
                value={formData.google_meet_link}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Zoom Meeting Link"
                name="zoom_meeting_link"
                value={formData.zoom_meeting_link}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">UPI QR Code</Typography>
              <Input
                type="file"
                onChange={handleQrImageChange}
                accept="image/*"
              />
              {trainer && trainer.data && trainer.data.upi_qr_image && (
                <img 
                  src={trainer.data.upi_qr_image} 
                  alt="Current UPI QR Code" 
                  style={{ maxWidth: '100px', maxHeight: '100px', marginTop: '10px' }} 
                />
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            {trainer ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AddEditTrainerModal;