import React, { useState, useEffect } from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Snackbar,
  Alert,
} from '@mui/material';
import { getAttendance, updateAttendance } from '../api';
import { formatDate } from '../utils/dateUtils';
import AttendanceCalendar from './AttendanceCalendar';

const ClassContentSection = ({ studentId }) => {
  const [classContents, setClassContents] = useState([]);
  const [displayedContents, setDisplayedContents] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const fetchClassContents = async () => {
      try {
        const response = await getAttendance({ student: studentId });
        const contentsWithClassContent = response.data.filter(attendance => attendance.class_content);
        setClassContents(contentsWithClassContent);
        setDisplayedContents(contentsWithClassContent.slice(0, 5));
      } catch (error) {
        console.error('Error fetching class contents:', error);
        setSnackbar({ open: true, message: 'Failed to fetch class contents', severity: 'error' });
      }
    };

    fetchClassContents();
  }, [studentId]);

  const handleFeedback = async (attendanceId, feedback) => {
    try {
      const attendanceToUpdate = classContents.find(content => content.id === attendanceId);
      if (!attendanceToUpdate) {
        throw new Error('Attendance record not found');
      }

      await updateAttendance(attendanceId, {
        student: studentId,
        status: attendanceToUpdate.status,
        student_feedback: feedback
      });

      const updatedContents = classContents.map(content => 
        content.id === attendanceId ? { ...content, student_feedback: feedback } : content
      );
      setClassContents(updatedContents);
      setDisplayedContents(showAll ? updatedContents : updatedContents.slice(0, 5));
      setSnackbar({ open: true, message: `Class content ${feedback.toLowerCase()}ed`, severity: 'success' });
    } catch (error) {
      console.error('Error updating feedback:', error);
      setSnackbar({ open: true, message: 'Failed to update feedback', severity: 'error' });
    }
  };

  const handleShowMore = () => {
    setShowAll(true);
    setDisplayedContents(classContents);
  };

  return (
    <Box mt={4}>
      <Typography variant="h6" gutterBottom>Class Content Updates</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Class Content</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedContents.map((content) => (
              <TableRow key={content.id}>
                <TableCell>{formatDate(content.timestamp)}</TableCell>
                <TableCell>{content.class_content}</TableCell>
                <TableCell>{content.student_feedback || 'Pending'}</TableCell>
                <TableCell>
                  {content.student_feedback === 'NO_ACTION' && (
                    <>
                      <Button 
                        onClick={() => handleFeedback(content.id, 'ACCEPTED')} 
                        color="primary" 
                        variant="contained" 
                        size="small" 
                        style={{ marginRight: '8px' }}
                      >
                        Accept
                      </Button>
                      <Button 
                        onClick={() => handleFeedback(content.id, 'REJECTED')} 
                        color="secondary" 
                        variant="contained" 
                        size="small"
                      >
                        Reject
                      </Button>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!showAll && classContents.length > 5 && (
        <Box mt={2} display="flex" justifyContent="center">
          <Button onClick={handleShowMore} variant="outlined" color="primary">
            Show More
          </Button>
        </Box>
      )}
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ClassContentSection;