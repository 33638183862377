import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, Table, TableBody, TableCell, 
  TableContainer, TableHead, TableRow, Paper, Button, Typography, CircularProgress
} from '@mui/material';
import { getUser, getCourse } from '../api';

const AuditHistoryModal = ({ open, onClose, audits, onViewDetails }) => {
  const [auditorNames, setAuditorNames] = useState({});
  const [courseNames, setCourseNames] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      const newAuditorNames = { ...auditorNames };
      const newCourseNames = { ...courseNames };

      for (const audit of audits) {
        console.log('Processing audit:', audit);

        if (audit.auditor && !newAuditorNames[audit.auditor]) {
          try {
            console.log('Fetching auditor data for ID:', audit.auditor);
            const response = await getUser(audit.auditor);
            console.log('Auditor response:', response);
            if (response && response.full_name) {
              newAuditorNames[audit.auditor] = response.full_name;
            } else {
              console.warn('Unexpected auditor response structure:', response);
              newAuditorNames[audit.auditor] = 'Unknown';
            }
          } catch (error) {
            console.error(`Error fetching auditor name for ID ${audit.auditor}:`, error);
            newAuditorNames[audit.auditor] = 'Unknown';
          }
        }

        if (audit.course && !newCourseNames[audit.course]) {
          try {
            console.log('Fetching course data for ID:', audit.course);
            const response = await getCourse(audit.course);
            console.log('Course response:', response);
            if (response && response.name) {
              newCourseNames[audit.course] = response.name;
            } else {
              console.warn('Unexpected course response structure:', response);
              newCourseNames[audit.course] = 'Unknown Course';
            }
          } catch (error) {
            console.error(`Error fetching course name for ID ${audit.course}:`, error);
            newCourseNames[audit.course] = 'Unknown Course';
          }
        }
      }

      console.log('Final auditorNames:', newAuditorNames);
      console.log('Final courseNames:', newCourseNames);

      setAuditorNames(newAuditorNames);
      setCourseNames(newCourseNames);
      setLoading(false);
    };

    if (open) {
      fetchDetails();
    }
  }, [audits, open]);

  const formatDate = (dateString) => {
    return dateString ? new Date(dateString).toLocaleDateString() : 'N/A';
  };

  if (loading) {
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <CircularProgress />
          <Typography>Loading audit data...</Typography>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Audit History</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Audit Date</TableCell>
                <TableCell>Class Date</TableCell>
                <TableCell>Overall Score</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Auditor</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {audits.map((audit) => (
                <TableRow key={audit.id}>
                  <TableCell>{formatDate(audit.audit_date)}</TableCell>
                  <TableCell>{formatDate(audit.class_date)}</TableCell>
                  <TableCell>{audit.overall_score}</TableCell>
                  <TableCell>{audit.student_name || 'N/A'}</TableCell>
                  <TableCell>{audit.course ? (courseNames[audit.course] || 'Unknown Course') : 'N/A'}</TableCell>
                  <TableCell>{auditorNames[audit.auditor] || 'Unknown Auditor'}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onViewDetails(audit)}
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {audits.length === 0 && (
          <Typography variant="body1" style={{ textAlign: 'center', marginTop: '20px' }}>
            No audit history available.
          </Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AuditHistoryModal;