import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Button,
  Box,
  CircularProgress,
  Avatar,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Badge,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Paper,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import EditIcon from '@mui/icons-material/Edit';
import { getCurrentUser, listStudentCourses,getImageUrl, getStudyMaterialsForCourse, joinBBBRoom, getTrainerGroupCourses, listInboxMessages, listSentMessages, listUnreadMessages, sendMessage, markMessageAsRead, getMessage, listUsers, getTrainer, updateUserMe, listStudyMaterials, createStudyMaterial, getStudyMaterialsForStudent, getUser, getAttendance, getTrainerOccupation, updateAttendance, createAttendanceReview, listNotices, listGroupCourses, listAttendanceReviews, getAttendanceReviewHistory } from '../api';

import AttendanceCalendar from '../components/AttendanceCalendar'; 
import MessagingComponent from '../components/MessagingComponent';
import NoticeCard from './NoticeCard';

const ScrollableList = styled(List)({
  maxHeight: '200px',
  overflow: 'auto',
});

const GlowingCard = styled(Paper)(({ theme }) => ({
  background: '#1e1e1e',
  borderRadius: '10px',
  padding: '2px',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '2px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));

const CardInner = styled(Box)({
  background: '#1e1e1e',
  borderRadius: '8px',
  padding: '20px',
  position: 'relative',
  zIndex: 1,
  height: '100%',
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  background: '#1e1e1e',
  borderRadius: '10px',
  overflow: 'hidden',
  position: 'relative',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '1px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    zIndex: 0,
  },
}));

const GlassModal = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
  },
}));

const NoticeExcerpt = styled(Typography)({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  marginBottom: '8px',
});

const TrainerDashboard = () => {
  const [trainerData, setTrainerData] = useState({
    profile: null,
    associatedStudents: [],
    groupCourses: [],
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [attendanceData, setAttendanceData] = useState({});
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [reviewRemark, setReviewRemark] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [newProfileImage, setNewProfileImage] = useState(null);
  const [materialModalOpen, setMaterialModalOpen] = useState(false);
  const [selectedStudentForMaterial, setSelectedStudentForMaterial] = useState(null);
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [newMaterials, setNewMaterials] = useState({ topic: '', files: [] });
  const [studentDetailsModalOpen, setStudentDetailsModalOpen] = useState(false);
  const [selectedStudentDetails, setSelectedStudentDetails] = useState(null);
  const [messages, setMessages] = useState({ inbox: [], sent: [] });
  const [unreadCount, setUnreadCount] = useState(0);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [newMessage, setNewMessage] = useState({ recipient: '', subject: '', body: '' });
  const [adminManagerList, setAdminManagerList] = useState([]);
  const [messageTab, setMessageTab] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [notices, setNotices] = useState([]);
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [groupCourses, setGroupCourses] = useState([]);
  const [occupation, setOccupation] = useState(null);
  const [attendanceReviews, setAttendanceReviews] = useState([]);
  const [attendanceReviewHistory, setAttendanceReviewHistory] = useState([]);
  const [students, setStudents] = useState([]);

  const fetchTrainerData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const userResponse = await getCurrentUser();
      const trainerId = userResponse.data.id;
      
      const today = new Date().toISOString().split('T')[0];
      
      const [
        noticesResponse,
        groupCoursesResponse,
        studentsResponse,
        occupationResponse,
        messagesResponse,
        adminManagersResponse,
        reviewsResponse,
        reviewHistoryResponse
      ] = await Promise.all([
        listNotices(),
        getTrainerGroupCourses(),
        listStudentCourses({ trainer: trainerId }),
        getTrainerOccupation(trainerId, today),
        Promise.all([listUnreadMessages(), listInboxMessages(), listSentMessages()]),
        listUsers({ role: ['ADMIN', 'MANAGER'] }),
        listAttendanceReviews(),
        getAttendanceReviewHistory()
      ]);

      const studentsWithUserData = await Promise.all(
        (studentsResponse.data ?? []).map(async (studentCourse) => {
          try {
            const userResponse = await getUser(studentCourse.student);
            return {
              ...studentCourse,
              studentData: userResponse.data ?? {}
            };
          } catch (error) {
            console.error(`Failed to fetch data for student ${studentCourse.student}:`, error);
            return studentCourse;
          }
        })
      );
      
      
      
      setTrainerData({
        profile: userResponse.data,
        associatedStudents: studentsResponse.data,
        groupCourses: groupCoursesResponse.data,
      });
      
      setOccupation(occupationResponse.data);
      setNotices(noticesResponse.data || []);
      setStudents(studentsWithUserData);
      
      const [unreadResponse, inboxResponse, sentResponse] = messagesResponse;
      setUnreadCount(unreadResponse.data.length);
      setMessages({
        inbox: inboxResponse.data,
        sent: sentResponse.data,
      });
      
      setAdminManagerList(adminManagersResponse.data);
      setAttendanceReviews(reviewsResponse.data);
      setAttendanceReviewHistory(reviewHistoryResponse.data);

    } catch (err) {
      console.error('Error fetching trainer data:', err);
      setError('Failed to fetch trainer data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTrainerData();
  }, [fetchTrainerData]);

  

  const handleJoinClass = (joinUrl) => {
    if (joinUrl) {
      window.open(joinUrl, '_blank');
    } else {
      setSnackbar({ open: true, message: 'Join URL not available', severity: 'error' });
    }
  };

  const handleViewAttendance = async (student) => {
    setSelectedStudent(student);
    setAttendanceModalOpen(true);
  };

  const handleRequestReview = (attendance) => {
    setSelectedAttendance(attendance);
    setReviewModalOpen(true);
  };

  
  

  const handleEditProfile = () => {
    setEditedProfile({
      first_name: trainerData.profile.first_name,
      last_name: trainerData.profile.last_name,
      email: trainerData.profile.email,
      phone: trainerData.profile.phone,
      city: trainerData.profile.city,
      about_me: trainerData.profile.about_me,
      date_of_birth: trainerData.profile.date_of_birth,
    });
    setEditProfileModalOpen(true);
  };

  const handleProfileInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProfile(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleProfileImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewProfileImage(e.target.files[0]);
    }
  };

  const handleSaveProfile = async () => {
    try {
      const formData = new FormData();
      
      Object.keys(editedProfile).forEach(key => {
        if (editedProfile[key] !== trainerData.profile[key]) {
          formData.append(key, editedProfile[key]);
        }
      });
  
      if (newProfileImage) {
        formData.append('profile_image', newProfileImage);
      }
  
      const response = await updateUserMe(formData);
  
      setTrainerData(prev => ({
        ...prev,
        profile: {
          ...prev.profile,
          ...response.data
        }
      }));
  
      setEditProfileModalOpen(false);
      setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
    } catch (err) {
      console.error('Error updating profile:', err);
      setSnackbar({ open: true, message: 'Failed to update profile. Please try again.', severity: 'error' });
    }
  };

  const handleOpenMaterialModal = async (courseOrStudentCourse) => {
    setSelectedStudentForMaterial(courseOrStudentCourse);
    try {
      let materials;
      let courseId;
  
      if (courseOrStudentCourse.course) {
        courseId = courseOrStudentCourse.course.id;
        if (courseOrStudentCourse.course.is_group_class) {
          materials = await getStudyMaterialsForCourse(courseId);
        } else {
          materials = await getStudyMaterialsForStudent(courseOrStudentCourse.student);
        }
      } else {
        courseId = courseOrStudentCourse.id;
        materials = await getStudyMaterialsForCourse(courseId);
      }
  
      setStudyMaterials(materials.data);
      setMaterialModalOpen(true);
    } catch (err) {
      console.error('Error fetching study materials:', err);
      setSnackbar({ open: true, message: 'Failed to fetch study materials. Please try again.', severity: 'error' });
    }
  };

  const handleFileChange = (event) => {
    setNewMaterials(prev => ({ ...prev, files: [...prev.files, ...event.target.files] }));
  };

  const handleAddMaterial = async () => {
    try {
      const formData = new FormData();
      formData.append('topic', newMaterials.topic);
      newMaterials.files.forEach((file, index) => {
        formData.append(`uploaded_files[${index}]`, file);
        
        // Limit file type length to 50 characters
        const fileType = file.type.split('/')[1].substring(0, 50);
        formData.append(`file_types[${index}]`, fileType);
      });
  
      if (selectedStudentForMaterial.course) {
        if (selectedStudentForMaterial.course.is_group_class) {
          formData.append('course', selectedStudentForMaterial.course.id);
        } else {
          formData.append('student_course', selectedStudentForMaterial.id);
        }
      } else {
        formData.append('course', selectedStudentForMaterial.id);
      }
  
      await createStudyMaterial(formData);
      setSnackbar({ open: true, message: 'Study materials added successfully', severity: 'success' });
  
      let updatedMaterials;
      if (selectedStudentForMaterial.course) {
        if (selectedStudentForMaterial.course.is_group_class) {
          updatedMaterials = await getStudyMaterialsForCourse(selectedStudentForMaterial.course.id);
        } else {
          updatedMaterials = await getStudyMaterialsForStudent(selectedStudentForMaterial.student);
        }
      } else {
        updatedMaterials = await getStudyMaterialsForCourse(selectedStudentForMaterial.id);
      }
  
      setStudyMaterials(updatedMaterials.data);
      setNewMaterials({ topic: '', files: [] });
    } catch (err) {
      console.error('Error adding study materials:', err);
      setSnackbar({ open: true, message: 'Failed to add study materials. Please try again.', severity: 'error' });
    }
  };
  

  const handleStudentClick = (studentCourse) => {
    setSelectedStudentDetails(studentCourse.studentData);
    setStudentDetailsModalOpen(true);
  };

  const handleSendMessage = async () => {
    try {
      await sendMessage(newMessage);
      setMessageModalOpen(false);
      setNewMessage({ recipient: '', subject: '', body: '' });
      fetchTrainerData();
      setSnackbar({ open: true, message: 'Message sent successfully', severity: 'success' });
    } catch (error) {
      console.error('Error sending message:', error);
      setSnackbar({ open: true, message: 'Failed to send message. Please try again.', severity: 'error' });
    }
  };

  const handleOpenMessage = async (message) => {
    try {
      const fullMessage = await getMessage(message.id);
      setSelectedMessage(fullMessage.data);
      if (!message.read_at) {
        await markMessageAsRead(message.id);
        fetchTrainerData();
      }
    } catch (error) {
      console.error('Error opening message:', error);
      setSnackbar({ open: true, message: 'Failed to open message. Please try again.', severity: 'error' });
    }
  };

  const handleCloseMessage = () => {
    setSelectedMessage(null);
  };

  const handleChangeTab = (event, newValue) => {
    setMessageTab(newValue);
  };

  const handleNoticeClick = (notice) => {
    setSelectedNotice(notice);
  };

  const handleCloseNotice = () => {
    setSelectedNotice(null);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Box my={4}>
        <Typography variant="h4" gutterBottom color="white">My Dashboard</Typography>
        <Grid container spacing={3}>
          {/* Trainer Profile */}
          <Grid item xs={12} md={6}>
            <GlowingCard>
              <CardInner>
                <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
                  <Avatar src={getImageUrl(trainerData.profile?.profile_image)} sx={{ width: 100, height: 100, mb: 2 }} />
                  <Typography variant="h5" color="white">{`${trainerData.profile?.first_name} ${trainerData.profile?.last_name}`}</Typography>
                  <Typography variant="body1" color="white">{trainerData.profile?.email}</Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="white">Role: {trainerData.profile?.role}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="white">Phone: {trainerData.profile?.phone}</Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="white">City: {trainerData.profile?.city}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2" color="white">
                      DOB: {trainerData.profile?.date_of_birth ? dayjs(trainerData.profile.date_of_birth).format('D-MMM-YYYY') : 'N/A'}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography variant="body2" color="white">About: {trainerData.profile?.about_me}</Typography>
                <IconButton onClick={handleEditProfile} sx={{ position: 'absolute', top: 10, right: 10 }}>
                  <EditIcon />
                </IconButton>
              </CardInner>
            </GlowingCard>
          </Grid>

          {/* Summary and Notices */}
          <Grid item xs={12} md={6}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" color="white" gutterBottom>Summary & Notices</Typography>
                <Typography variant="body1" color="white">
                  Total Students: {trainerData.associatedStudents.length}
                </Typography>
                <Typography variant="body1" color="white">
                  Occupied Hours: {occupation?.occupied_hours ?? 'N/A'}
                </Typography>
                <Divider sx={{ my: 2 }} />
                <NoticeCard notices={notices} />
              </CardInner>
            </GlowingCard>
          </Grid>

          {/* Messaging Card */}
          <Grid item xs={12} md={6} lg={12}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">Messaging</Typography>
                <MessagingComponent />
              </CardInner>
            </GlowingCard>
          </Grid>

          {/* Associated Students */}
      {/* Associated Students */}
<Grid item xs={12}>
  <GlowingCard>
    <CardInner>
      <Typography variant="h6" gutterBottom color="white">My Students</Typography>
      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student </TableCell>
              <TableCell>Course</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Class Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((studentCourse) => (
              <TableRow key={studentCourse.id}>
                <TableCell>
                
  <Link
    component="button"
    variant="body2"
    onClick={() => handleStudentClick(studentCourse)}
  >
    {studentCourse.studentData?.full_name || `Student ID: ${studentCourse.student}`}
  </Link>
</TableCell>
                <TableCell>{studentCourse.course.name}</TableCell>
                <TableCell>{dayjs(studentCourse.start_date).format('MMMM D, YYYY')}</TableCell>
                <TableCell>{dayjs(studentCourse.end_date).format('MMMM D, YYYY')}</TableCell>
                <TableCell>{dayjs(studentCourse.class_time, 'HH:mm:ss').format('hh:mm A')}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleViewAttendance(studentCourse.student)}>
                    Attendance
                  </Button>
                  <Button 
                    variant="outlined" 
                    onClick={() => handleOpenMaterialModal(studentCourse)}
                    style={{ marginLeft: '10px' }}
                  >
                    Material
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => handleJoinClass(studentCourse.bbb_join_url)}
                    disabled={!studentCourse.bbb_join_url}
                    style={{ marginLeft: '10px' }}
                  >
                    Join Room
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </CardInner>
  </GlowingCard>
</Grid>

          {/* Group Courses Table */}
      <Grid item xs={12}>
        <GlowingCard>
          <CardInner>
            <Typography variant="h6" gutterBottom color="white">Group Courses</Typography>
            <StyledTableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Course Name</TableCell>
                    <TableCell>Class Time</TableCell>
                    <TableCell>Assigned Time</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trainerData.groupCourses.map((course) => (
                    <TableRow key={course.id}>
                      <TableCell>{course.name}</TableCell>
                      <TableCell>{course.class_time}</TableCell>
                      <TableCell>
                        {course.trainer_assignments.map((assignment, index) => (
                          <div key={index}>{`${assignment.start_time} - ${assignment.end_time}`}</div>
                        ))}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="outlined"
                          onClick={() => handleJoinClass(course.bbb_join_url)}
                          disabled={!course.bbb_join_url}
                        >
                          Join Room
                        </Button>
                        <Button 
                          variant="outlined" 
                          onClick={() => handleOpenMaterialModal(course)}
                          style={{ marginLeft: '10px' }}
                        >
                          Material
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </CardInner>
        </GlowingCard>
      </Grid>

          {/* Attendance Reviews Section */}
          <Grid item xs={12}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">Attendance Reviews</Typography>
                <ScrollableList>
                  {attendanceReviews.slice(0, 3).map((review) => (
                    <ListItem key={review.id}>
                      <ListItemText
                        primary={`Review for ${review.attendance.student} on ${dayjs(review.created_at).format('MMMM D, YYYY')}`}
                        secondary={review.remark}
                      />
                      <Chip label={review.status} color={review.status === 'PENDING' ? 'warning' : 'success'} />
                    </ListItem>
                  ))}
                </ScrollableList>
              </CardInner>
            </GlowingCard>
          </Grid>

          {/* Attendance Review History Section */}
          <Grid item xs={12}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">Review History</Typography>
                <ScrollableList>
                  {attendanceReviewHistory.slice(0, 3).map((review) => (
                    <ListItem key={review.id}>
                      <ListItemText
                        primary={`Review for attendance on ${dayjs(review.created_at).format('MMMM D, YYYY')}`}
                        secondary={`Status: ${review.status}, Processed on: ${dayjs(review.processed_at).format('MMMM D, YYYY')}`}
                      />
                    </ListItem>
                  ))}
                </ScrollableList>
              </CardInner>
            </GlowingCard>
          </Grid>
        </Grid>
      </Box>

      {/* Attendance Calendar Modal */}
      <Dialog
        open={attendanceModalOpen}
        onClose={() => setAttendanceModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Attendance for {selectedStudent?.full_name}
        </DialogTitle>
        <DialogContent>
          <AttendanceCalendar
            studentId={selectedStudent?.id}
            trainerId={trainerData.profile?.id}
            userRole="TRAINER"
            onRequestReview={handleRequestReview}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAttendanceModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Edit Profile Modal */}
      <Dialog open={editProfileModalOpen} onClose={() => setEditProfileModalOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle>Edit Profile</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="First Name"
            name="first_name"
            value={editedProfile.first_name || ''}
            onChange={handleProfileInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Last Name"
            name="last_name"
            value={editedProfile.last_name || ''}
            onChange={handleProfileInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Email"
            name="email"
            value={editedProfile.email || ''}
            onChange={handleProfileInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={editedProfile.phone || ''}
            onChange={handleProfileInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="City"
            name="city"
            value={editedProfile.city || ''}
            onChange={handleProfileInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="About Me"
            name="about_me"
            value={editedProfile.about_me || ''}
            onChange={handleProfileInputChange}
            margin="normal"
            multiline
            rows={4}
          />
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={handleProfileImageChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              Upload Profile Image
            </Button>
          </label>
          {newProfileImage && (
            <Typography variant="body2">New image selected: {newProfileImage.name}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditProfileModalOpen(false)}>Cancel</Button>
          <Button onClick={handleSaveProfile} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      {/* Study Material Modal */}
      <Dialog
        open={materialModalOpen}
        onClose={() => setMaterialModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Study Materials for {selectedStudentForMaterial?.course
            ? (selectedStudentForMaterial.course.is_group_class 
              ? `${selectedStudentForMaterial.course.name} (Group Course)` 
              : selectedStudentForMaterial.studentDetails?.full_name)
            : `${selectedStudentForMaterial?.name} (Group Course)`}
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>Assigned Materials</Typography>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Topic</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>File</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studyMaterials.map((material) => (
                  <TableRow key={material.id}>
                    <TableCell>{material.topic}</TableCell>
                    <TableCell>{dayjs(material.created_at).format('MMMM D, YYYY HH:mm')}</TableCell>
                    <TableCell>
                      {material.files.map((file, index) => (
                        <Button key={index} href={file.file} target="_blank" rel="noopener noreferrer">
                          View File {index + 1}
                        </Button>
                      ))}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>Add New Material</Typography>
          <TextField
            fullWidth
            label="Topic"
            value={newMaterials.topic}
            onChange={(e) => setNewMaterials({ ...newMaterials, topic: e.target.value })}
            margin="normal"
          />
          <input
            accept="*/*"
            style={{ display: 'none' }}
            id="material-files"
            type="file"
            multiple
            onChange={handleFileChange}
          />
          <label htmlFor="material-files">
            <Button variant="contained" component="span">
              Upload Files
            </Button>
          </label>
          {newMaterials.files.map((file, index) => (
            <Typography key={index} variant="body2">{file.name}</Typography>
          ))}
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleAddMaterial}
            style={{ marginTop: '10px' }}
            disabled={!newMaterials.topic || newMaterials.files.length === 0}
          >
            Add Material
          </Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMaterialModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Student Details Modal */}
      <Dialog open={studentDetailsModalOpen} onClose={() => setStudentDetailsModalOpen(false)} fullWidth maxWidth="sm">
  <DialogTitle>Student Details</DialogTitle>
  <DialogContent>
    {selectedStudentDetails && (
      <>
        <Avatar src={selectedStudentDetails.profile_image} sx={{ width: 100, height: 100, mb: 2 }} />
        <Typography variant="h6">{selectedStudentDetails.full_name}</Typography>
        <Typography>Email: {selectedStudentDetails.email}</Typography>
        <Typography>Phone: {selectedStudentDetails.phone}</Typography>
        <Typography>Date of Birth: {dayjs(selectedStudentDetails.date_of_birth).format('MMMM D, YYYY')}</Typography>
        <Typography>City: {selectedStudentDetails.city}</Typography>
        <Typography>About: {selectedStudentDetails.about_me}</Typography>
      </>
    )}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setStudentDetailsModalOpen(false)}>Close</Button>
  </DialogActions>
</Dialog>

      {/* Notice Modal */}
      <GlassModal open={!!selectedNotice} onClose={handleCloseNotice} maxWidth="sm" fullWidth>
        <DialogTitle>{selectedNotice?.title}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedNotice?.content}</Typography>
          {selectedNotice?.attachments && selectedNotice.attachments.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Attachments:</Typography>
              {selectedNotice.attachments.map((attachment, index) => (
                <Chip
                  key={index}
                  label={attachment.file_name}
                  onClick={() => window.open(attachment.file, '_blank')}
                  style={{ margin: '4px' }}
                />
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotice} color="primary">
            Close
          </Button>
        </DialogActions>
      </GlassModal>

      {/* Message Modal */}
      <Dialog open={messageModalOpen} onClose={() => setMessageModalOpen(false)} fullWidth maxWidth="md">
        <DialogTitle>Messages</DialogTitle>
        <DialogContent>
          <Tabs value={messageTab} onChange={handleChangeTab}>
            <Tab label="Inbox" />
            <Tab label="Sent" />
            <Tab label="New Message" />
          </Tabs>
          {messageTab === 0 && (
            <List>
              {messages.inbox?.map((message) => (
                <ListItem key={message.id} button onClick={() => handleOpenMessage(message)}>
                  <ListItemAvatar>
                    <Avatar>{message.sender.full_name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={message.subject} 
                    secondary={message.body.substring(0, 50) + '...'}
                  />
                  {!message.read_at && <Chip label="Unread" color="primary" size="small" />}
                </ListItem>
              ))}
            </List>
          )}
          {messageTab === 1 && (
            <List>
              {messages.sent?.map((message) => (
                <ListItem key={message.id} button onClick={() => handleOpenMessage(message)}>
                  <ListItemAvatar>
                    <Avatar>{message.recipient.full_name[0]}</Avatar>
                  </ListItemAvatar>
                  <ListItemText 
                    primary={message.subject} 
                    secondary={message.body.substring(0, 50) + '...'}
                  />
                </ListItem>
              ))}
            </List>
          )}
          {messageTab === 2 && (
            <Box mt={2}>
              <FormControl fullWidth>
                <InputLabel>Recipient</InputLabel>
                <Select
                  value={newMessage.recipient}
                  onChange={(e) => setNewMessage({ ...newMessage, recipient: e.target.value })}
                >
                  {adminManagerList.map((user) => (
                    <MenuItem key={user.id} value={user.id}>{user.full_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                label="Subject"
                value={newMessage.subject}
                onChange={(e) => setNewMessage({ ...newMessage, subject: e.target.value })}
                margin="normal"
              />
              <TextField
                fullWidth
                label="Message"
                value={newMessage.body}
                onChange={(e) => setNewMessage({ ...newMessage, body: e.target.value })}
                margin="normal"
                multiline
                rows={4}
              />
              <Button onClick={handleSendMessage} variant="contained" color="primary">
                Send
              </Button>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMessageModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Selected Message Dialog */}
      <Dialog open={!!selectedMessage} onClose={handleCloseMessage} fullWidth maxWidth="sm">
        <DialogTitle>{selectedMessage?.subject}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary">
            From: {selectedMessage?.sender.full_name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            To: {selectedMessage?.recipient.full_name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Date: {new Date(selectedMessage?.created_at).toLocaleString()}
          </Typography>
          <Typography variant="body1" style={{ marginTop: '16px' }}>
            {selectedMessage?.body}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default TrainerDashboard;