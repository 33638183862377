import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Grid,
  TextField,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Collapse,
  IconButton,
  Box,
  Tabs,
  Tab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonIcon from '@mui/icons-material/Person';
import ScheduleIcon from '@mui/icons-material/Schedule';
import dayjs from 'dayjs';
import {
  getAvailableTrainersExtended,
  getUser,
  getTrainerSuggestionsForExternalLead,
  assignTrainerToCourse,
  listExternalLeads,
  searchUserByEmail,
  deleteExternalLead,
} from '../api';
import AddEditStudentModal from '../components/AddEditStudentModal';

const formatTime = (time24) => {
  return dayjs(time24, 'HH:mm').format('h:mm A');
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = dayjs(dateString);
  return date.isValid() ? date.format('D-MMM-YYYY') : 'Invalid Date';
};

const ExternalLeadRow = ({ lead, onAssignTrainer, onDelete }) => {
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showNoProfileModal, setShowNoProfileModal] = useState(false);
  const [expandedTrainer, setExpandedTrainer] = useState(null);

  const handleGetSuggestions = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await getTrainerSuggestionsForExternalLead(lead.id);
      setSuggestions(response.data);
      setOpen(true);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setShowNoProfileModal(true);
      } else {
        setError('Failed to fetch trainer suggestions. Please try again.');
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getTrainerAvailabilitySummary = (availability) => {
    const dates = Object.keys(availability).sort();
    const startDate = dayjs(dates[0]).format('D-MMM-YYYY');
    const totalDays = dates.length;
    const daysWithAllSlots = dates.filter(date => 
      availability[date].length === 3  // Assuming 3 slots per day
    ).length;
    
    const availabilityText = daysWithAllSlots === totalDays 
      ? "all slots" 
      : daysWithAllSlots === 0 
        ? "some slots" 
        : `all slots on ${daysWithAllSlots} days, some slots on ${totalDays - daysWithAllSlots} days`;

    // Get unique slot times
    const allSlots = dates.flatMap(date => availability[date]);
    const uniqueSlots = [...new Set(allSlots)].sort();
    const slotTimes = uniqueSlots.map(slot => formatTime(slot)).join(', ');

    return `Available from ${startDate} for ${availabilityText}\nSlot times: ${slotTimes}`;
  };


  const handleTrainerClick = (trainerId) => {
    setExpandedTrainer(expandedTrainer === trainerId ? null : trainerId);
  };

  const formatDateForDisplay = (date) => dayjs(date).format('ddd, MMM D');

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{lead.name}</TableCell>
        <TableCell>{lead.email || 'N/A'}</TableCell>
        <TableCell>{lead.phone}</TableCell>
        <TableCell>{lead.course}</TableCell>
        <TableCell>{lead.duration} minutes</TableCell>
        <TableCell>
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleGetSuggestions}
            disabled={loading}
            style={{ marginRight: '8px' }}
          >
            {loading ? <CircularProgress size={24} /> : 'Get Suggestions'}
          </Button>
          <IconButton
            color="error"
            onClick={() => onDelete(lead.id)}
            disabled={loading}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Additional Information
              </Typography>
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">Slot 1</TableCell>
                    <TableCell>{formatTime(lead.slot_1)}</TableCell>
                    <TableCell component="th" scope="row">Slot 2</TableCell>
                    <TableCell>{formatTime(lead.slot_2)}</TableCell>
                    <TableCell component="th" scope="row">Slot 3</TableCell>
                    <TableCell>{formatTime(lead.slot_3)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Remarks</TableCell>
                    <TableCell colSpan={5}>{lead.remarks || 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">Coordinator Name</TableCell>
                    <TableCell>{lead.coordinator_name || 'N/A'}</TableCell>
                    <TableCell component="th" scope="row">Created At</TableCell>
                    <TableCell>{formatDate(lead.created_at)} {formatTime(lead.created_at)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              
              <Typography variant="h6" gutterBottom component="div" style={{ marginTop: '20px' }}>
                Trainer Suggestions
              </Typography>
              {error && <Alert severity="error">{error}</Alert>}
              {suggestions.length > 0 ? (
                <List>
                  {suggestions.map((suggestion) => (
                    <Paper key={suggestion.trainer.id} elevation={3} style={{ marginBottom: '16px', overflow: 'hidden' }}>
                      <ListItem 
                        button 
                        onClick={() => handleTrainerClick(suggestion.trainer.id)}
                        style={{ backgroundColor: '##181818' }}
                      >
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText 
                          primary={`${suggestion.trainer.first_name} ${suggestion.trainer.last_name}`} 
                          secondary={
                            <>
                              <Typography component="span" variant="body2" color="textPrimary">
                                Available: {Object.keys(suggestion.availability).length} day(s)
                              </Typography>
                              <br />
                              <Typography component="span" variant="body2" color="textSecondary">
                                {getTrainerAvailabilitySummary(suggestion.availability)}
                              </Typography>
                            </>
                          }
                        />
                        {expandedTrainer === suggestion.trainer.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </ListItem>
                      <Collapse in={expandedTrainer === suggestion.trainer.id} timeout="auto" unmountOnExit>
                        <Box padding={2}>
                          <Grid container spacing={2}>
                            {Object.entries(suggestion.availability).map(([date, slots]) => (
                              <Grid item xs={12} sm={6} md={4} lg={3} key={date}>
                                <Paper elevation={1} style={{ padding: '8px', height: '100%' }}>
                                  <Typography variant="subtitle2" gutterBottom>
                                    {formatDateForDisplay(date)}
                                  </Typography>
                                  {slots.length > 0 ? (
                                    slots.map((slot, index) => (
                                      <Typography key={index} variant="body2">
                                        {formatTime(slot)}
                                      </Typography>
                                    ))
                                  ) : (
                                    <Typography variant="body2" color="textSecondary">
                                      No available slots
                                    </Typography>
                                  )}
                                </Paper>
                              </Grid>
                            ))}
                          </Grid>
                          <Button 
                            variant="contained" 
                            color="secondary"
                            onClick={() => onAssignTrainer(lead, suggestion.trainer)}
                            style={{ marginTop: '16px' }}
                          >
                            Assign
                          </Button>
                        </Box>
                      </Collapse>
                    </Paper>
                  ))}
                </List>
              ) : (
                <Typography>No suggestions available.</Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog open={showNoProfileModal} onClose={() => setShowNoProfileModal(false)}>
        <DialogTitle>User Profile Not Found</DialogTitle>
        <DialogContent>
          <Typography>
            The user hasn't made a profile yet. Please ask the user to create a profile or check if the email is correct.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNoProfileModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AllotmentPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [externalLeads, setExternalLeads] = useState([]);
  const [availableTrainers, setAvailableTrainers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [date, setDate] = useState(dayjs());
  const [startTime, setStartTime] = useState(dayjs().startOf('hour'));
  const [duration, setDuration] = useState('');
  const [isAddEditModalOpen, setIsAddEditModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedTrainer, setSelectedTrainer] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);

  useEffect(() => {
    fetchExternalLeads();
  }, []);

  const fetchExternalLeads = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listExternalLeads();
      setExternalLeads(response.data);
    } catch (err) {
      setError('Failed to fetch external leads. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteLead = async (leadId) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      await deleteExternalLead(leadId);
      setSuccess('External lead deleted successfully.');
      fetchExternalLeads(); // Refresh the list after deletion
    } catch (err) {
      setError('Failed to delete external lead. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAssignTrainer = async (lead, trainer) => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      // Search for user by email
      const userResponse = await searchUserByEmail(lead.email);
      let student = null;

      if (userResponse.data.length > 0) {
        student = userResponse.data[0];
      } else {
        // If user not found, we'll create a new one in the AddEditStudentModal
        student = {
          email: lead.email,
          full_name: lead.name,
          username: lead.email.split('@')[0], // Use part of email as username
        };
      }

      setSelectedStudent(student);
      setSelectedTrainer(trainer);
      setSelectedCourse(lead.course);
      setIsAddEditModalOpen(true);
    } catch (err) {
      setError('Failed to assign trainer. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddEditModalClose = () => {
    setIsAddEditModalOpen(false);
    setSelectedStudent(null);
    setSelectedTrainer(null);
    setSelectedCourse(null);
  };

  const handleAddEditModalSave = async (savedData) => {
    setSuccess(`Successfully assigned trainer to student and created course.`);
    fetchExternalLeads(); // Refresh the external leads list
    handleAddEditModalClose();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setSuccess(null);
    try {
      const response = await getAvailableTrainersExtended(
        startTime.format('HH:mm'),
        parseInt(duration),
        date.format('YYYY-MM-DD')
      );
      setAvailableTrainers(response.data);
      setSuccess('Successfully fetched available trainers.');
    } catch (err) {
      setError('Failed to fetch available trainers. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Typography variant="h4" gutterBottom>Trainer Allotment</Typography>
      
      <Tabs value={tabValue} onChange={handleTabChange} aria-label="allotment tabs">
        <Tab label="External Leads" />
        <Tab label="Manual Search" />
      </Tabs>

      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      {success && <Alert severity="success">{success}</Alert>}

      {tabValue === 0 && (
        <>
          <Typography variant="h5" gutterBottom style={{ marginTop: '20px' }}>External Leads</Typography>
          {externalLeads.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Course</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {externalLeads.map((lead) => (
                    <ExternalLeadRow 
                      key={lead.id} 
                      lead={lead} 
                      onAssignTrainer={handleAssignTrainer}
                      onDelete={handleDeleteLead}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No external leads available.</Typography>
          )}
        </>
      )}

{tabValue === 1 && (
        <>
          <Paper elevation={3} style={{ padding: '20px', marginTop: '20px', marginBottom: '20px' }}>
            <Typography variant="h5" gutterBottom>Search Available Trainers</Typography>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={3}>
                <DatePicker
                  label="Date"
                  value={date}
                  onChange={(newDate) => setDate(newDate)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  format="DD-MMM-YYYY"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TimePicker
                  label="Start Time"
                  value={startTime}
                  onChange={(newTime) => setStartTime(newTime)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  format="hh:mm A"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  fullWidth
                  label="Duration (minutes)"
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSearch}
                  fullWidth
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Paper>

          {availableTrainers.length > 0 && (
            <>
              <Typography variant="h5" gutterBottom>Available Trainers</Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Trainer Name</TableCell>
                      <TableCell>Available Today</TableCell>
                      <TableCell>Available Within Week</TableCell>
                      <TableCell>Availability</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {availableTrainers.map((trainer) => (
                      <TableRow key={trainer.trainer.id}>
                        <TableCell>{trainer.trainer.full_name || trainer.trainer.username}</TableCell>
                        <TableCell>{trainer.available_today ? 'Yes' : 'No'}</TableCell>
                        <TableCell>{trainer.available_within_week ? 'Yes' : 'No'}</TableCell>
                        <TableCell>
                          {trainer.availability.map((day, index) => (
                            <div key={index}>
                              {formatDate(day.date)}: {day.is_available ? 'Available' : 'Not Available'}
                            </div>
                          ))}
                        </TableCell>
                        <TableCell>
                          <Button 
                            variant="contained" 
                            color="secondary"
                            onClick={() => handleAssignTrainer(null, trainer.trainer)}
                          >
                            Assign
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}

      <AddEditStudentModal
        open={isAddEditModalOpen}
        onClose={handleAddEditModalClose}
        student={selectedStudent}
        onSave={handleAddEditModalSave}
        initialTrainer={selectedTrainer}
        initialCourse={selectedCourse}
      />
    </Container>
  );
};

export default AllotmentPage;