import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, CircularProgress, Box } from '@mui/material';
import LoginPage from './LoginPage';
import AdminDashboard from './pages/AdminDashboard';
import TrainerDashboard from './pages/TrainerDashboard';
import StudentDashboard from './pages/StudentDashboard';
import CoursesPage from './pages/CoursesPage';
import StudentsPage from './pages/StudentsPage';
import TrainersPage from './pages/TrainersPage';
import CoordinatorsPage from './pages/CoordinatorsPage';
import TopNavBar from './components/TopNavBar';
import { getCurrentUser } from './api';
import customDarkTheme from './theme';
import { startTokenValidityCheck, checkTokenValidity } from './api';
import TeamUpdatesPage from './pages/TeamUpdatesPage';
import ClassRecordingsPage from './pages/ClassRecordingsPage';
import MyEmploymentPage from './pages/MyEmploymentPage';
import ManagersDashboard from './pages/ManagersDashboard';
import AllotmentPage from './pages/AllotmentPage';
import CourseCompletionPage from './pages/CourseCompletionPage';
import StudentDetails from './pages/StudentDetails';




startTokenValidityCheck();

const ProtectedRoute = ({ children, allowedRoles }) => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
    loading: true,
  });
  const navigate = useNavigate();
  const location = useLocation();

  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const isValid = await checkTokenValidity();
        if (isValid) {
          const userResponse = await getCurrentUser();
          const userRole = userResponse.data.role;
          setAuthState({
            isAuthenticated: true,
            userRole: userRole,
            loading: false,
          });
        } else {
          throw new Error('Token is invalid');
        }
      } catch (error) {
        console.error('Authentication failed:', error);
        localStorage.removeItem('token');
        setAuthState({ isAuthenticated: false, userRole: null, loading: false });
        navigate('/login', { replace: true, state: { from: location } });
      }
    } else {
      setAuthState({ isAuthenticated: false, userRole: null, loading: false });
      navigate('/login', { replace: true, state: { from: location } });
    }
  }, [navigate, location]);

  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (authState.loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!authState.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles && !allowedRoles.includes(authState.userRole)) {
    return <Navigate to="/" replace />;
  }

  return children;
};
   

const AppContent = () => {
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    userRole: null,
    loading: true,
  });
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setAuthState({
      isAuthenticated: false,
      userRole: null,
      loading: false,
    });
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const isValid = await checkTokenValidity();
          if (isValid) {
            const userResponse = await getCurrentUser();
            const userRole = userResponse.data.role;
            setAuthState({
              isAuthenticated: true,
              userRole: userRole,
              loading: false,
            });
          } else {
            throw new Error('Token is invalid');
          }
        } catch (error) {
          console.error('Authentication failed:', error);
          localStorage.removeItem('token');
          setAuthState({ isAuthenticated: false, userRole: null, loading: false });
          navigate('/login');
        }
      } else {
        setAuthState({ isAuthenticated: false, userRole: null, loading: false });
      }
    };

    checkAuth();
  }, [navigate]);

  if (authState.loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      {authState.isAuthenticated && <TopNavBar userRole={authState.userRole} onLogout={handleLogout} />}
      <Container>
        <Routes>
          <Route path="/login" element={<LoginPage setAuthState={setAuthState} />} />
          <Route
            path="/"
            element={
              <ProtectedRoute>
                {authState.userRole === 'ADMIN' ? <AdminDashboard /> :
                 authState.userRole === 'MANAGER' ? <ManagersDashboard /> :
                 authState.userRole === 'TRAINER' ? <TrainerDashboard /> :
                 authState.userRole === 'STUDENT' ? <StudentDashboard /> :
                 <Navigate to="/login" replace />}
              </ProtectedRoute>
            }
          />
          <Route
            path="/courses"
            element={
              <ProtectedRoute>
                <CoursesPage />
              </ProtectedRoute>
            }
          />
           <Route
            path="/allotment"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <AllotmentPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/students"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <StudentsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/trainers"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <TrainersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/trainers/:id"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <TrainersPage />
              </ProtectedRoute>
            }
          />
          <Route
  path="/student-details/:studentId"
  element={
    <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}>
      <StudentDetails />
    </ProtectedRoute>
  }
/>
          <Route
  path="/course-completion"
  element={
    <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
      <CourseCompletionPage />
    </ProtectedRoute>
  }
/>
          <Route
            path="/coordinators"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER']}>
                <CoordinatorsPage />
              </ProtectedRoute>
            }
          />
          <Route
  path="/recordings"
  element={
    <ProtectedRoute>
      <ClassRecordingsPage />
    </ProtectedRoute>
  }
/>
          <Route
            path="/team-updates"
            element={
              <ProtectedRoute allowedRoles={['ADMIN', 'MANAGER', 'TRAINER']}>
                <TeamUpdatesPage />
              </ProtectedRoute>
            }
          />


          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/my-employment" element={<MyEmploymentPage />} />
        </Routes>
      </Container>
    </>
  );
};

const App = () => {
 
  return (
    <ThemeProvider theme={customDarkTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CssBaseline />
        <Router>
          <AppContent />
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;