import axios from 'axios';
import axiosInstance from 'axios'

const BASE_URL = 'https://dj.nilconnect.in'; // or your actual backend URL
const API_URL = `${BASE_URL}/api`;

const api = axios.create({
  baseURL: API_URL,
  withCredentials: true, // You might not need this for token auth
  headers: {
    'Content-Type': 'application/json',
  }
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Token ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export const fetchCSRFToken = async () => {
  try {
    await api.get('/get-csrf-token/');
    console.log('CSRF token fetched successfully');
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};
// Authentication
export const login = async (username, password) => {
  try {
    const response = await api.post('/auth/login/', { username, password });
    console.log('Login response:', response.data);
    
    if (response.data && response.data.key) {
      const { key } = response.data;
      localStorage.setItem('token', key);
      api.defaults.headers.common['Authorization'] = `Token ${key}`;
    } else {
      throw new Error('No token received from server');
    }
    
    return response;
  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      console.error('Error status:', error.response.status);
      console.error('Error data:', error.response.data);
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error', error.message);
    }
    throw error;
  }
};

export const getImageUrl = (imageUrl) => {
  if (!imageUrl) return '';
  if (imageUrl.startsWith('http')) return imageUrl;
  return `${BASE_URL}${imageUrl}`;
};
export const checkTokenValidity = async () => {
  try {
    await getCurrentUser();
    return true;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      delete api.defaults.headers.common['Authorization'];
    }
    return false;
  }
};

export const startTokenValidityCheck = (intervalInMinutes = 5) => {
  setInterval(async () => {
    const isValid = await checkTokenValidity();
    if (!isValid) {
      // Optionally, you can trigger a logout action or redirect to login page
      console.log('Token is no longer valid');
    }
  }, intervalInMinutes * 60 * 1000);
};
export const register = (userData) => {
  const registrationData = {
    ...userData,
    role: 'STUDENT'  // Always set role to STUDENT
  };
  return api.post('/register/', registrationData);
};

export const logout = () => api.post('/accounts/logout/');
export const googleAuth = (idToken) => api.post('/auth/google/', { id_token: idToken });

export const updateUserMe = (formData) => {
  return api.put('/me/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getTrainerMonthlyHours = async (trainerId, year, month) => {
  try {
    const response = await api.get(`/trainers/${trainerId}/monthly_hours/`, {
      params: { year, month }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching trainer monthly hours:', error);
    throw error;
  }
};

export const getCurrentMonthBaseSalary = (userId) => {
  return api.get(`/users/${userId}/current_month_base_salary/`);
};
// User Management
export const getCurrentUser = () => api.get('/me/');
export const getUser = (id) => api.get(`/users/${id}/`);
export const updateUser = (id, userData) => api.put(`/users/${id}/`, userData);


export const deleteUser = (id) => api.delete(`/users/${id}/`);
export const listUsers = async ({ page = 1, page_size = 10, roles = [], search = '' } = {}) => {
  const params = new URLSearchParams({
    page: page,
    page_size: page_size,
  });

  if (roles && roles.length > 0) {
    roles.forEach(role => params.append('role', role));
  }

  if (search) {
    params.append('search', search);
  }

  const response = await api.get(`/users/?${params.toString()}`);
  return response.data;
};
export const createUser = (userData) => api.post('/users/', userData);

export const changeUserRole = (id, role) => api.patch(`/users/${id}/change_role/`, { role });
export const assignCoordinator = (userId, coordinatorId) => 
  api.post(`/users/${userId}/assign_coordinator/`, { coordinator_id: coordinatorId });
export const removeCoordinator = (userId) => api.post(`/users/${userId}/remove_coordinator/`);

// Trainer Management
export const listTrainers = () => api.get('/trainers/');
export const getTrainer = (id) => api.get(`/trainers/${id}/`).catch(error => {
  console.error('Error fetching trainer:', error.response?.data || error.message);
  throw error;
});
export const updateTrainer = (id, trainerData) => {
  const formData = new FormData();
  Object.keys(trainerData).forEach(key => {
    if (key === 'user') {
      Object.keys(trainerData.user).forEach(userKey => {
        formData.append(`user.${userKey}`, trainerData.user[userKey]);
      });
    } else if (key === 'upi_qr_image' && trainerData[key] instanceof File) {
      formData.append(key, trainerData[key], trainerData[key].name);
    } else {
      formData.append(key, trainerData[key]);
    }
  });

  return api.put(`/trainers/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const createTrainer = (trainerData) => api.post('/trainers/', trainerData);
export const updateTrainerMeetingLinks = (id, linkData) => api.patch(`/trainers/${id}/update_meeting_links/`, linkData);

export const assignTrainerToCourse = (courseId, trainerId) => 
  api.post(`/student-courses/${courseId}/assign_trainer/`, { trainer_id: trainerId });

export const searchUserByEmail = (email) => {
  return api.get('/users/search_by_email/', { params: { email } });
};

// Student Management
export const listStudents = () => api.get('/student-profiles/');
export const getStudent = (id) => api.get(`/student-profiles/${id}/`);
export const updateStudent = (id, userData) => api.put(`/users/${id}/`, userData);
export const createStudentProfile = (studentData) => api.post('/student-profiles/', studentData);

// Coordinator Management
export const listCoordinators = () => {
  return api.get('/coordinators/').then(response => response.data);
};
export const getCoordinator = (id) => api.get(`/coordinators/${id}/`);
export const createCoordinator = (formData) => {
  return api.post('/coordinators/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const updateCoordinator = (id, formData) => {
  return api.put(`/coordinators/${id}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const deleteCoordinator = (id) => api.delete(`/coordinators/${id}/`);

// Course Management
export const listCourses = () => api.get('/courses/');
export const getCourse = (id) => api.get(`/courses/${id}/`);
export const createCourse = (courseData) => api.post('/courses/', courseData);
export const updateCourse = (id, courseData) => api.put(`/courses/${id}/`, courseData);
export const deleteCourse = (id) => api.delete(`/courses/${id}/`);
export const assignTrainersToCourse = (courseId, trainerAssignments) => 
  api.post(`/courses/${courseId}/assign_trainers/`, { trainer_assignments: trainerAssignments });
export const assignTrainerToGroupCourse = (courseId, trainerAssignments) => 
  api.post(`/courses/${courseId}/assign_trainers/`, { trainer_assignments: trainerAssignments });
export const assignStudentToGroupCourse = (courseId, studentId, startDate, endDate) => 
  api.post('/student-courses/', { 
    course: courseId, 
    student: studentId, 
    start_date: startDate, 
    end_date: endDate 
  });

  export const listGroupCourses = () => {
    return api.get('/courses/', { params: { is_group_class: true } });
  };

export const updateTrainerUser = (userId, userData) => {
    return api.put(`/users/${userId}/`, userData);
  };
// Student Courses
export const listStudentCourses = (params) => api.get('/student-courses/', { params });
export const getStudentCourse = (id) => api.get(`/student-courses/${id}/`);
export const getStudentCourses = (studentId) => api.get('/student-courses/', { params: { student: studentId } });
export const createStudentCourse = (studentCourseData) => api.post('/student-courses/', studentCourseData);
export const updateStudentCourse = (id, studentCourseData) => api.put(`/student-courses/${id}/`, studentCourseData);
export const extendStudentCourse = (id, newEndDate) => api.post(`/student-courses/${id}/extend_course/`, { new_end_date: newEndDate });
export const reassignTrainer = (id, newTrainerId) => api.post(`/student-courses/${id}/reassign_trainer/`, { trainer_id: newTrainerId });
export const listTrainersForCourse = (courseId) => api.get(`/student-courses/${courseId}/list_trainers/`);
export const addTrainerToCourse = (courseId, trainerId) => api.post(`/student-courses/${courseId}/add_trainer/`, { trainer_id: trainerId });
export const removeTrainerFromCourse = (courseId, trainerId) => api.post(`/student-courses/${courseId}/remove_trainer/`, { trainer_id: trainerId });
export const updateCourseTrainers = (courseId, trainerIds) => api.post(`/student-courses/${courseId}/update_trainers/`, { trainer_ids: trainerIds });

//for studentsdetailspage specifically
export const getStudentCourse1 = (studentId) => {
  return api.get(`/student-courses`, { params: { student: studentId } });
}

// Course Holds
export const listCourseHolds = () => api.get('/course-holds/');
export const getCourseHold = (id) => api.get(`/course-holds/${id}/`);
export const createCourseHold = (holdData) => api.post('/course-holds/', holdData);
export const approveCourseHold = (id) => api.post(`/course-holds/${id}/approve/`);
export const rejectCourseHold = (id) => api.post(`/course-holds/${id}/reject/`);

// Study Materials
export const listStudyMaterials = () => api.get('/study-materials/');
export const getStudyMaterial = (id) => api.get(`/study-materials/${id}/`);
export const createStudyMaterial = (formData) => 
  api.post('/study-materials/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
export const getStudyMaterialsForStudent = (studentId) => api.get('/study-materials/for_student/', { params: { student_id: studentId } });
export const getStudyMaterialsForCourse = (courseId) => api.get('/study-materials/for_course/', { params: { course_id: courseId } });

// Student Feedback
export const listStudentFeedbacks = () => api.get('/student-feedback/');
export const listStudentFeedbacks1 = (studentId) => api.get('/student-feedback/', { params: { student: studentId } });
export const getStudentFeedback = (id) => api.get(`/student-feedback/${id}/`);
export const createStudentFeedback = (feedbackData) => api.post('/student-feedback/', feedbackData);
export const respondToStudentFeedback = (id, responseData) => api.post(`/student-feedback/${id}/respond/`, responseData);
export const getStudentFeedbackHistory = () => api.get('/student-feedback/history/');


// Trainer Assignments
export const listTrainerAssignments = () => api.get('/trainer-assignments/');
export const getTrainerAssignment = (id) => api.get(`/trainer-assignments/${id}/`);
export const createTrainerAssignment = (assignmentData) => api.post('/trainer-assignments/', assignmentData);
export const getTrainerOccupation = (trainerId, date) => api.get(`/trainer-assignments/trainer_occupation/`, { params: { trainer_id: trainerId, date } });
export const getTrainerAvailability = (trainerId, date) => api.get(`/trainer-assignments/trainer_availability/`, { params: { trainer_id: trainerId, date } });
export const getAvailableTrainers = (classTime, duration, date) => api.get('/trainer-assignments/available_trainers/', { params: { class_time: classTime, duration, date } });
export const getAvailableTrainersExtended = (startTime, duration, startDate) => api.get('/trainer-assignments/available_trainers_extended/', { params: { start_time: startTime, duration, start_date: startDate } });


// Attendance
export const listAttendances = (params) => api.get('/attendances/', { params });
export const getAttendance = (params) => api.get('/attendances/', { params });
export const getStudentAttendances = (params) => api.get('/attendances/', { params });
export const createAttendance = (attendanceData) => api.post('/attendances/', attendanceData);
export const updateAttendance = async (attendanceId, data) => {
  return api.put(`/attendances/${attendanceId}/`, {
    ...data,
    student: Number(data.student) // Ensure it's a number
  });
};export const addClassContent = (id, contentData) => api.post(`/attendances/${id}/add_class_content/`, contentData);
export const provideStudentFeedback = (id, feedbackData) => api.post(`/attendances/${id}/provide_student_feedback/`, feedbackData);
export const changeAttendanceStatus = (attendanceId, newStatus) => {
  return api.post(`/attendances/${attendanceId}/change_status/`, { status: newStatus });
};
export const getPresentAttendancesYesterday = () => api.get('/attendances/present_yesterday/');
export const getTrainerAbsentAttendancesYesterday = () => api.get('/attendances/trainer_absent_yesterday/');
export const requestAttendanceReview = (id) => api.post(`/attendances/${id}/request_review/`);

export const getAttendanceReview = (id) => api.get(`/attendance-reviews/${id}/`);

export const updateAttendanceReview = async (reviewId, data) => {
  const response = await axiosInstance.patch(`/api/attendance-reviews/${reviewId}/`, data);
  return response.data;
};
export const listAttendanceReviews = () => api.get('/attendance-reviews/');
export const createAttendanceReview = (reviewData) => api.post('/attendance-reviews/', reviewData);
export const processAttendanceReview = async (reviewId, status) => {
  return api.post(`/attendance-reviews/${reviewId}/process/`, { status });
};
export const getAttendanceReviewHistory = () => api.get('/attendance-reviews/history/');


// BigBlueButton Integration
export const listBBBRooms = () => api.get('/bbb/rooms/');
export const getBBBRoom = (id) => api.get(`/bbb/rooms/${id}/`);
export const joinBBBRoom = (id) => api.get(`/bbb/rooms/${id}/join/`);
export const isBBBRoomRunning = (id) => api.get(`/bbb/rooms/${id}/is_running/`);
export const getBBBRoomAccessInfo = (id) => api.get(`/bbb/rooms/${id}/access_info/`);

export const listBBBRecordings = (params) => {
  return api.get('/bbb/recordings/', { params: {
    trainer: params.trainer,
    student: params.student,
    start_date: params.startDate,
    end_date: params.endDate,
    ...params
  }});
};
export const getTrainerGroupCourses = (trainerId) => {
  return api.get(`/courses/trainer_group_courses/`, { params: { trainer_id: trainerId } });
};


export const deleteBBBRecording = (recordingId) => api.delete(`/bbb/recordings/${recordingId}/`);
export const updateBBBRecording = (recordingId, updateData) => api.put(`/bbb/recordings/${recordingId}/`, updateData);
export const getBBBRecordingPlayback = (recordingId) => api.get(`/bbb/recordings/${recordingId}/playback/`);

// Messaging
export const listMessages = () => api.get('/messages/');
export const getMessage = (id) => api.get(`/messages/${id}/`);
export const sendMessage = (formData, config) => {
  return api.post('/messages/', formData, config);
};
export const markMessageAsRead = (id) => api.post(`/messages/${id}/mark_as_read/`);
export const listInboxMessages = () => api.get('/messages/inbox/');
export const listSentMessages = () => api.get('/messages/sent/');
export const listUnreadMessages = () => api.get('/messages/unread/');
export const pinMessage = (id) => api.post(`/messages/${id}/pin/`);
export const unpinMessage = (id) => api.post(`/messages/${id}/unpin/`);
export const softDeleteMessage = (id) => api.post(`/messages/${id}/soft_delete/`);
export const getConversation = (id) => api.get(`/messages/${id}/conversation/`);
export const listPinnedMessages = () => api.get('/messages/pinned/');

// Team Communication
export const listTeamUpdates = () => api.get('/team-updates/');
export const getTeamUpdate = (id) => api.get(`/team-updates/${id}/`);
export const createTeamUpdate = (updateData, files, links) => {
  const formData = new FormData();
  formData.append('content', updateData.content);
  
  files.forEach((file, index) => {
    formData.append(`attachments`, file);
  });

  links.forEach((link, index) => {
    formData.append(`links`, JSON.stringify(link));
  });

  return api.post('/team-updates/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const likeTeamUpdate = (id) => api.post(`/team-updates/${id}/like/`);
export const commentOnTeamUpdate = (id, commentData) => api.post(`/team-updates/${id}/comment/`, commentData);
export const pinUnpinTeamUpdate = (id) => api.post(`/team-updates/${id}/pin/`);
export const deleteTeamUpdate = (updateId) => api.delete(`/team-updates/${updateId}/`);
export const createUpdateAttachment = (updateId, formData) => {
  return api.post(`/team-updates/${updateId}/attachments/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


// Notices
export const listNotices = () => api.get('/notices/');
export const getNotice = (id) => api.get(`/notices/${id}/`);
export const createNotice = (formData) => api.post('/notices/', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});


export const deleteNotice = (noticeId) => {
  return api.delete(`/notices/${noticeId}/`);
};

// Calculate Salary
export const calculateSalary = (userId, params) => 
  api.get(`/users/${userId}/calculate_salary/`, { params });

// Update Salary
export const updateSalary = (userId, data) => 
  api.post(`/users/${userId}/update_salary/`, data);

// Get Salary History
export const getSalaryHistory = (userId, params) => 
  api.get(`/users/${userId}/salary_history/`, { params });

// Download Salary Slip
export const downloadSalarySlip = (userId, params) => 
  api.get(`/users/${userId}/download_salary_slip/`, { 
    params, 
    responseType: 'blob' 
  });

// Set Fixed Salary (for Admin and Manager)
export const setFixedSalary = (userId, data) => 
  api.post(`/users/${userId}/set_fixed_salary/`, data);

// Notifications
export const listNotifications = () => api.get('/notifications/');
export const getNotification = (id) => api.get(`/notifications/${id}/`);
export const markNotificationAsRead = (id) => api.post(`/notifications/${id}/mark_as_read/`);


export const handleApiError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("Error data:", error.response.data);
    console.error("Error status:", error.response.status);
    console.error("Error headers:", error.response.headers);
    
    // You can handle specific error codes here
    if (error.response.status === 401) {
      // Unauthorized - you might want to redirect to login page
      // or refresh the token
    }
    
    return error.response.data.error;
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response received:", error.request);
    return { message: "No response received from server" };
  } else {
    // Something happened in setting up the request that triggered an Error
    console.error("Error message:", error.message);
    return { message: error.message };
  }
};


// Dashboard Statistics
export const getDashboardStats = (date) => api.get('/dashboard-stats/', { params: { date } });



export const listLeaveRequests = () => api.get('/leave-requests/');
export const getLeaveRequest = (id) => api.get(`/leave-requests/${id}/`);
export const approveLeaveRequest = (id, remarks) => api.post(`/leave-requests/${id}/approve/`, { admin_remarks: remarks });
export const rejectLeaveRequest = (id, remarks) => api.post(`/leave-requests/${id}/reject/`, { admin_remarks: remarks });
export const getLeaveHistory = () => api.get('/leave-history/');
export const getLeaveRequestHistory = () => api.get('/leave-request-history/');




export const requestLeave = (leaveData) => {
  const formData = new FormData();
  Object.keys(leaveData).forEach(key => {
    if (key === 'uploaded_files') {
      leaveData[key].forEach(file => {
        formData.append('uploaded_files', file);
      });
    } else {
      formData.append(key, leaveData[key]);
    }
  });
  return api.post('/leave-requests/', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


export const createTrainerAudit = (auditData) => api.post('/trainer-audits/', auditData);
export const listTrainerAudits = (trainerId) => api.get('/trainer-audits/', { params: { trainer: trainerId } });
export const getTrainerAudit = (id) => api.get(`/trainer-audits/${id}/`);
export const updateTrainerAudit = (id, auditData) => api.put(`/trainer-audits/${id}/`, auditData);
export const addTrainerRemarks = (id, remarks) => api.post(`/trainer-audits/${id}/add_trainer_remarks/`, { remarks });
export const getTrainerSuggestionsForExternalLead = (leadId) => {
  return api.get(`/external/external-leads/${leadId}/get_trainer_suggestions/`);
};

export const getExternalLead = (leadId) => {
  return api.get(`/external/external-leads/${leadId}/`);
};

export const updateExternalLead = (leadId, data) => {
  return api.put(`/external/external-leads/${leadId}/`, data);
};

export const createExternalLead = (data) => {
  return api.post('/external/external-leads/', data);
};

export const listExternalLeads = (params) => {
  return api.get('/external/external-leads/', { params });
};

export const deleteExternalLead = (leadId) => {
  return api.delete(`/external/external-leads/${leadId}/`);
};

export const syncBBBRecordings = () => {
  return api.post('/bbb-recordings/sync_recordings/');
};

export const getCoursesEndingSoon = (params) => {
  return api.get('/courses/ending_soon/', { params });
};


export const getCourseHoldHistory = async () => {
  return api.get('/course-holds/history/');
};

export const getCourseHoldHistory1 = async (studentId) => {
  return api.get('/course-holds/history/',{ params: { student_id: studentId } });
};