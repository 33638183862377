import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Chip,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs from 'dayjs';
import { 
  listCourses, 
  createCourse, 
  updateCourse, 
  deleteCourse, listTrainers
} from '../api';
import AssignTrainersModal from '../components/AssignTrainersModal';
import { assignTrainersToCourse } from '../api'


const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  background: '#1e1e1e',
  borderRadius: '10px',
  overflow: 'hidden',
  position: 'relative',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '1px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    zIndex: 0,
  },
}));

function CoursesPage() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAssignTrainersModal, setOpenAssignTrainersModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [openModal, setOpenModal] = useState(false);
  const [editingCourse, setEditingCourse] = useState(null);
  const [allTrainers, setAllTrainers] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    class_duration: '',
    is_group_class: false,
    class_time: null,
  });
  const [searchTerm, setSearchTerm] = useState('');

  const fetchCourses = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listCourses();
      console.log('API response:', response);
      if (response && Array.isArray(response.data)) {
        setCourses(response.data);
      } else {
        console.error('Unexpected API response structure:', response);
        setCourses([]);
        setError('Received unexpected data format from the server.');
      }
    } catch (error) {
      console.error('Error fetching courses:', error);
      setCourses([]);
      setError('Failed to fetch courses. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const fetchTrainers = useCallback(async () => {
    try {
      const response = await listTrainers();
      setAllTrainers(response.data);
    } catch (error) {
      console.error('Error fetching trainers:', error);
      setSnackbar({ open: true, message: 'Failed to fetch trainers', severity: 'error' });
    }
  }, []);

  useEffect(() => {
    fetchTrainers();
  }, [fetchTrainers]);

  const handleOpenAssignTrainersModal = (course) => {
    setSelectedCourse(course);
    setOpenAssignTrainersModal(true);
  };


  

  useEffect(() => {
    fetchTrainers();
  }, [fetchTrainers]);

  const handleAssignTrainers = async (trainerAssignments) => {
    try {
      await assignTrainersToCourse(selectedCourse.id, trainerAssignments);
      setSnackbar({ open: true, message: 'Trainers assigned successfully', severity: 'success' });
      fetchCourses();
    } catch (error) {
      console.error('Error assigning trainers:', error);
      setSnackbar({ open: true, message: 'Failed to assign trainers', severity: 'error' });
    }
    setOpenAssignTrainersModal(false);
  };

  const handleOpenModal = (course = null) => {
    if (course) {
      setFormData({
        name: course.name,
        description: course.description,
        class_duration: course.class_duration,
        is_group_class: course.is_group_class,
        class_time: course.class_time ? dayjs(course.class_time, 'HH:mm:ss') : null,
      });
      setEditingCourse(course);
    } else {
      setFormData({
        name: '',
        description: '',
        class_duration: '',
        is_group_class: false,
        class_time: null,
      });
      setEditingCourse(null);
    }
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingCourse(null);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleTimeChange = (newValue) => {
    setFormData(prev => ({ ...prev, class_time: newValue }));
  };

  const handleSave = async () => {
    try {
      const courseData = {
        ...formData,
        class_time: formData.class_time ? formData.class_time.format('HH:mm:ss') : null,
      };

      if (editingCourse) {
        await updateCourse(editingCourse.id, courseData);
      } else {
        await createCourse(courseData);
      }

      setSnackbar({ 
        open: true, 
        message: `Course ${editingCourse ? 'updated' : 'created'} successfully`, 
        severity: 'success' 
      });
      handleCloseModal();
      fetchCourses();
    } catch (error) {
      console.error('Error saving course:', error);
      setSnackbar({ 
        open: true, 
        message: `Failed to ${editingCourse ? 'update' : 'create'} course. Please try again.`, 
        severity: 'error' 
      });
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      try {
        await deleteCourse(id);
        setSnackbar({ open: true, message: 'Course deleted successfully', severity: 'success' });
        fetchCourses();
      } catch (error) {
        console.error('Error deleting course:', error);
        setSnackbar({ open: true, message: 'Failed to delete course. Please try again.', severity: 'error' });
      }
    }
  };

  const filteredCourses = courses.filter(course =>
    course.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    course.description.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          Courses
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal()}
          variant="contained"
          color="primary"
        >
          Add Course
        </Button>
      </Box>

      <TextField
        fullWidth
        label="Search Courses"
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        margin="normal"
      />

      <StyledTableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Class Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCourses.length > 0 ? (
              filteredCourses.map((course) => (
                <TableRow key={course.id}>
                  <TableCell>{course.name}</TableCell>
                  <TableCell>{course.description}</TableCell>
                  <TableCell>{course.class_duration}</TableCell>
                  <TableCell>
                    <Chip 
                      label={course.is_group_class ? "Group" : "Individual"}
                      color={course.is_group_class ? "primary" : "secondary"}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>{course.class_time || 'Not set'}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit">
                      <IconButton onClick={() => handleOpenModal(course)}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton onClick={() => handleDelete(course.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
  {course.is_group_class && (
    <Button
      variant="outlined"
      size="small"
      onClick={() => handleOpenAssignTrainersModal(course)}
    >
      Assign Trainers
    </Button>
  )}
</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  No courses found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </StyledTableContainer>

      <Dialog open={openModal} onClose={handleCloseModal} fullWidth maxWidth="md">
        <DialogTitle>{editingCourse ? 'Edit Course' : 'Add New Course'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Course Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            margin="normal"
            multiline
            rows={3}
          />
          <TextField
            fullWidth
            label="Duration (HH:MM:SS)"
            name="class_duration"
            value={formData.class_duration}
            onChange={handleInputChange}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Course Type</InputLabel>
            <Select
              name="is_group_class"
              value={formData.is_group_class}
              onChange={handleInputChange}
            >
              <MenuItem value={false}>Individual</MenuItem>
              <MenuItem value={true}>Group</MenuItem>
            </Select>
          </FormControl>
          <TimePicker
            label="Class Time"
            value={formData.class_time}
            onChange={handleTimeChange}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSave} color="primary">
            {editingCourse ? 'Update' : 'Create'}
          </Button>
        </DialogActions>
      </Dialog>
      <AssignTrainersModal
        open={openAssignTrainersModal}
        onClose={() => setOpenAssignTrainersModal(false)}
        course={selectedCourse}
        onAssign={handleAssignTrainers}
        allTrainers={allTrainers}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CoursesPage;