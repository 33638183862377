import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  Pagination,
  Chip,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon } from '@mui/icons-material';
import AddEditStudentModal from '../components/AddEditStudentModal';
import { 
  listUsers, 
  updateUser, 
  createStudentProfile,
  listCoordinators,
  assignCoordinator,
  removeCoordinator,
  changeUserRole,
} from '../api';
import { formatDate } from '../utils/dateUtils';

function UsersPage() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const [selectedRoles, setSelectedRoles] = useState(['STUDENT']);
  const [coordinatorModalOpen, setCoordinatorModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedCoordinator, setSelectedCoordinator] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [usersResponse, coordinatorsResponse] = await Promise.all([
        listUsers({ 
          page, 
          page_size: 10, 
          roles: selectedRoles,
          search: searchTerm  // T
        }),
        listCoordinators(),
      ]);

      setUsers(usersResponse.results || []);
      setTotalPages(usersResponse.total_pages || 1);
      setTotalCount(usersResponse.count || 0);
      setCoordinators(Array.isArray(coordinatorsResponse) ? coordinatorsResponse : []);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
      setUsers([]);
      setCoordinators([]);
    } finally {
      setLoading(false);
    }
  }, [page, selectedRoles, searchTerm]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddEdit = (user = null) => {
    if (user && user.role === 'STUDENT') {
      setEditingUser(user);
      setOpenModal(true);
    }
  };

  const handleStudentClick = (user) => {
    navigate(`/student-details/${user.id}`);
  };


  const handleSave = async (userData) => {
    try {
      let response;
      if (userData.id) {
        response = await updateUser(userData.id, userData);
      } else {
        response = await createStudentProfile(userData);
      }
      
      setSnackbar({ open: true, message: `Student ${userData.id ? 'updated' : 'added'} successfully`, severity: 'success' });
      setOpenModal(false);
      fetchData();
    } catch (error) {
      console.error('Error saving student:', error);
      setSnackbar({ open: true, message: `Failed to ${userData.id ? 'update' : 'add'} student. Please try again.`, severity: 'error' });
    }
  };

  

  

  const handleRoleChange = async (userId, newRole) => {
    try {
      await changeUserRole(userId, newRole);
      setSnackbar({ open: true, message: 'User role updated successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error changing user role:', error);
      setSnackbar({ open: true, message: 'Failed to update user role. Please try again.', severity: 'error' });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRoleFilter = (role) => {
    setSelectedRoles(prevRoles => 
      prevRoles.includes(role)
        ? prevRoles.filter(r => r !== role)
        : [...prevRoles, role]
    );
    setPage(1);
  };

  const handleSearch = (event) => {
    if (event.key === 'Enter') {
      setSearchTerm(searchInput);
      setPage(1);  // Reset to first page when new search is performed
    }
  };

  const handleSearchInputChange = (event) => {
    setSearchInput(event.target.value);
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Container>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          Users
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={() => handleAddEdit()}
          variant="contained"
          color="primary"
        >
          Add Student
        </Button>
      </Box>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Search by Name, Email, or Username"
          value={searchInput}
          onChange={handleSearchInputChange}
          onKeyPress={handleSearch}
          margin="normal"
        />
        <Box mt={2}>
          {['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'].map(role => (
            <Chip
              key={role}
              label={role}
              onClick={() => handleRoleFilter(role)}
              color={selectedRoles.includes(role) ? "primary" : "default"}
              style={{ margin: '0 4px 4px 0' }}
            />
          ))}
        </Box>
      </Box>
      {users.length === 0 ? (
        <Alert severity="info">No users found matching your search criteria.</Alert>
      ) : (
        <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Username</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Coordinator</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell>
                  <Button
                    variant="text"
                    onClick={() => handleStudentClick(user)}
                    style={{ textTransform: 'none' }}
                  >
                    {user.username}
                  </Button>
                </TableCell>
                <TableCell>{user.full_name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell>
                  <FormControl>
                    <Select
                      value={user.role}
                      onChange={(e) => handleRoleChange(user.id, e.target.value)}
                    >
                      <MenuItem value="STUDENT">Student</MenuItem>
                      <MenuItem value="TRAINER">Trainer</MenuItem>
                      <MenuItem value="ADMIN">Admin</MenuItem>
                      <MenuItem value="MANAGER">Manager</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  {user.coordinator ? (
                    <Box display="flex" alignItems="center">
                      <Avatar src={user.coordinator.profile_image} alt={user.coordinator.name} sx={{ width: 24, height: 24, mr: 1 }} />
                      {user.coordinator.name}
                    </Box>
                  ) : (
                    'Not assigned'
                  )}
                </TableCell>
                <TableCell>
                  {user.role === 'STUDENT' && (
                    <>
                      <Tooltip title="Edit">
                        <IconButton onClick={() => handleAddEdit(user)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      )}
      <Box mt={2} display="flex" justifyContent="center">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handleChangePage}
          color="primary"
        />
      </Box>
      <AddEditStudentModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        student={editingUser}
        onSave={handleSave}
      />
     
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default UsersPage;