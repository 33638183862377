import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Link,
  Box
} from '@mui/material';
import { getSalaryHistory, downloadSalarySlip, getTrainerMonthlyHours, handleApiError } from '../api';
import dayjs from 'dayjs';
import SalaryBreakdownModal from './SalaryBreakdownModal';

function SalaryHistoryModal({ open, onClose, trainerId, trainerName }) {
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [monthlyHours, setMonthlyHours] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [breakdownModalOpen, setBreakdownModalOpen] = useState(false);

  useEffect(() => {
    if (open && trainerId) {
      fetchSalaryHistory();
    }
  }, [open, trainerId]);

  const fetchSalaryHistory = async () => {
    try {
      setLoading(true);
      const response = await getSalaryHistory(trainerId);
      setSalaryHistory(response.data || []);

      // Fetch monthly hours for each salary record
      const hoursPromises = response.data.map(salary => 
        getTrainerMonthlyHours(trainerId, salary.year, salary.month)
      );
      const hoursResults = await Promise.all(hoursPromises);
      
      const hoursMap = {};
      hoursResults.forEach((result, index) => {
        const salary = response.data[index];
        hoursMap[`${salary.year}-${salary.month}`] = result.total_hours;
      });
      setMonthlyHours(hoursMap);
    } catch (error) {
      const errorMessage = handleApiError(error);
      setError(errorMessage.message || 'Failed to fetch salary history. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSlip = async (year, month) => {
    try {
      const response = await downloadSalarySlip(trainerId, { year, month });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `salary_slip_${year}_${month}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      const errorMessage = handleApiError(error);
      setError(errorMessage.message || 'Error downloading salary slip. Please try again.');
    }
  };

  const handleMonthClick = (salary) => {
    setSelectedSalary({
      ...salary,
      total_hours: monthlyHours[`${salary.year}-${salary.month}`] || 'N/A'
    });
    setBreakdownModalOpen(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Salary History for {trainerName}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : salaryHistory.length > 0 ? (
          <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  <TableCell>Month</TableCell>
                  <TableCell>Total Salary</TableCell>
                  <TableCell>Total Hours</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salaryHistory.map((salary) => (
                  <TableRow key={`${salary.year}-${salary.month}`}>
                    <TableCell>{salary.year}</TableCell>
                    <TableCell>
                      <Link
                        component="button"
                        variant="body2"
                        onClick={() => handleMonthClick(salary)}
                      >
                        {dayjs().month(salary.month - 1).format('MMMM')}
                      </Link>
                    </TableCell>
                    <TableCell>₹{parseFloat(salary.total_salary).toFixed(2)}</TableCell>
                    <TableCell>{monthlyHours[`${salary.year}-${salary.month}`] || 'N/A'}</TableCell>
                    <TableCell>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleDownloadSlip(salary.year, salary.month)}
                        size="small"
                      >
                        Download Slip
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No salary history available for this trainer.</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
      <SalaryBreakdownModal
        open={breakdownModalOpen}
        onClose={() => setBreakdownModalOpen(false)}
        salaryData={selectedSalary}
      />
    </Dialog>
  );
}

export default SalaryHistoryModal;