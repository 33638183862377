import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, 
  Select, MenuItem, FormControl, InputLabel, Grid, Typography 
} from '@mui/material';
import { listCourses } from '../api';

const AddAuditModal = ({ open, onClose, onSubmit }) => {
  const [auditData, setAuditData] = useState({
    audit_date: '',
    class_date: '',
    overall_score: '',
    student_name: '',
    course: '',
    grammar_theory_covered: '',
    vocabulary_covered: '',
    speaking_activity: '',
    feedback_shared: '',
    assessment_of_student: '',
    error_rectification_done: '',
    webcam_on: '',
    class_on_portal: '',
    login_on_time: '',
    full_class_duration: '',
    session_on_time: '',
    use_of_whiteboard: '',
    study_material_shared: '',
    feedback: ''
  });
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await listCourses();
        setCourses(response.data);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };
    fetchCourses();
  }, []);

  const handleChange = (e) => {
    setAuditData({ ...auditData, [e.target.name]: e.target.value });
  };

  const renderSelect = (name, label, options) => (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={auditData[name]} onChange={handleChange}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Add New Audit</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Audit Date"
              type="date"
              name="audit_date"
              value={auditData.audit_date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Class Date"
              type="date"
              name="class_date"
              value={auditData.class_date}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Overall Score"
              type="number"
              name="overall_score"
              value={auditData.overall_score}
              onChange={handleChange}
              inputProps={{ min: 0, max: 10 }}
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              margin="normal"
              label="Student Name"
              name="student_name"
              value={auditData.student_name}
              onChange={handleChange}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            {renderSelect('course', 'Course', courses.map(course => ({ value: course.id, label: course.name })))}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Audit Parameters</Typography>
          </Grid>
          {[
            ['grammar_theory_covered', 'Grammar/Theory'],
            ['vocabulary_covered', 'Vocabulary'],
            ['speaking_activity', 'Speaking'],
            ['feedback_shared', 'Feedback'],
            ['assessment_of_student', 'Assessment'],
            ['error_rectification_done', 'Error Rectification'],
            ['webcam_on', 'Webcam'],
            ['class_on_portal', 'Class on Portal'],
            ['login_on_time', 'Login Time'],
            ['full_class_duration', 'Full Duration'],
            ['session_on_time', 'Session Time'],
            ['use_of_whiteboard', 'Whiteboard'],
            ['study_material_shared', 'Study Material'],
          ].map(([name, label]) => (
            <Grid item xs={6} sm={4} md={3} key={name}>
              {renderSelect(name, label, [
                { value: 'YES', label: 'Yes' },
                { value: 'NO', label: 'No' },
                { value: 'NA', label: 'N/A' }
              ])}
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextField
              fullWidth
              margin="normal"
              label="Feedback"
              multiline
              rows={4}
              name="feedback"
              value={auditData.feedback}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSubmit(auditData)} color="primary">Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddAuditModal;