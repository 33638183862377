import React, { useState, useCallback, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Button, 
  Box, 
  IconButton, 
  Drawer, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemIcon,
  Badge,
  Menu,
  MenuItem,
  Typography
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link, useLocation } from 'react-router-dom';
import { SitemarkIcon } from '../CustomIcons';
import { styled } from '@mui/material/styles';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import BookIcon from '@mui/icons-material/Book';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GroupIcon from '@mui/icons-material/Group';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import WorkIcon from '@mui/icons-material/Work';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';


import { listNotifications, markNotificationAsRead } from '../api';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.drawer + 1,
  background: 'linear-gradient(to bottom, #0f172a, black)',
  boxShadow: '0 4px 10px rgba(0, 150, 255, 0.6), 0 0 20px rgba(0, 150, 255, 0.5)',
  borderBottom: '0.2px solid white',
}));

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 20px',
});

const WhiteTextListItem = styled(ListItem)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: 'white',
  },
  '& .MuiListItemIcon-root': {
    color: 'white',
  },
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  background: 'linear-gradient(to bottom, #0f172a, black)',
  zIndex: theme.zIndex.drawer + 1,
  position: 'sticky',
  top: 0,
}));

const TopNavBar = ({ userRole, onLogout }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null);
  const location = useLocation();

  const toggleDrawer = useCallback((open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  }, []);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await listNotifications();
        // Filter out notifications where is_read is true
        const unreadNotifications = Array.isArray(response.data) 
          ? response.data.filter(notification => !notification.is_read)
          : [];
        setNotifications(unreadNotifications);
      } catch (error) {
        console.error('Error fetching notifications:', error);
        setNotifications([]);
      }
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);

    return () => clearInterval(intervalId);
  }, []);

  const handleNotificationClick = (event) => {
    setNotificationAnchorEl(event.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleNotificationRead = async (id) => {
    try {
      await markNotificationAsRead(id);
      setNotifications(prevNotifications => 
        prevNotifications.filter(notification => notification.id !== id)
      );
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  };

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/', roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'] },
    { text: 'Students', icon: <PeopleIcon />, link: '/students', roles: ['ADMIN', 'MANAGER'] },
    { text: 'Courses', icon: <BookIcon />, link: '/courses', roles: ['ADMIN', 'MANAGER'] },
    { text: 'Coordinators', icon: <SupervisorAccountIcon />, link: '/coordinators', roles: ['ADMIN', 'MANAGER'] },
    { text: 'Team Updates', icon: <GroupIcon />, link: '/team-updates', roles: ['ADMIN', 'MANAGER', 'TRAINER'] },
    { text: 'Class Recordings', icon: <VideoLibraryIcon />, link: '/recordings', roles: ['ADMIN', 'MANAGER', 'TRAINER', 'STUDENT'] },
    { text: 'My Employment', icon: <WorkIcon />, link: '/my-employment', roles: ['TRAINER'] },
    { text: 'Allotment', icon: <WorkIcon />, link: '/allotment', roles: ['ADMIN', 'MANAGER'] },
    { text: 'Course Completion', icon: <AssignmentTurnedInIcon />, link: '/course-completion', roles: ['ADMIN', 'MANAGER'] },
  ];

  const filteredMenuItems = menuItems.filter(item => item.roles.includes(userRole));

  const drawerContent = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <DrawerHeader>
        <SitemarkIcon sx={{ height: 40 }} />
      </DrawerHeader>
      <List>
        {filteredMenuItems.map((item) => (
          <WhiteTextListItem 
            button 
            key={item.text} 
            component={Link} 
            to={item.link}
            selected={location.pathname === item.link}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </WhiteTextListItem>
        ))}
        <WhiteTextListItem button onClick={onLogout}>
          <ListItemIcon><ExitToAppIcon /></ListItemIcon>
          <ListItemText primary="Logout" />
        </WhiteTextListItem>
      </List>
    </Box>
  );
  return (
    <>
      <StyledAppBar elevation={3}>
        <StyledToolbar>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SitemarkIcon sx={{ height: 40 }} />
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
            {filteredMenuItems.map((item) => (
              <Button 
                key={item.text} 
                color="inherit" 
                component={Link} 
                to={item.link}
                sx={{ 
                  backgroundColor: location.pathname === item.link ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.2)',
                  }
                }}
              >
                {item.text}
              </Button>
            ))}
            <IconButton color="inherit" onClick={handleNotificationClick}>
              <Badge badgeContent={notifications.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            <Button color="inherit" onClick={onLogout}>
              Logout
            </Button>
          </Box>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{ display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </StyledToolbar>
      </StyledAppBar>
      <Toolbar /> {/* This empty Toolbar acts as a spacer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            background: 'radial-gradient(circle, #0f172a 0%, #000000 100%)',
            backgroundAttachment: 'fixed',
          },
        }}
      >
        {drawerContent}
      </Drawer>
      <Menu
        anchorEl={notificationAnchorEl}
        open={Boolean(notificationAnchorEl)}
        onClose={handleNotificationClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {notifications.length === 0 ? (
          <MenuItem>No new notifications</MenuItem>
        ) : (
          Array.isArray(notifications) && notifications.map((notification) => (
            <MenuItem key={notification.id} onClick={() => handleNotificationRead(notification.id)}>
              <ListItemText 
                primary={notification.message} 
                secondary={new Date(notification.created_at).toLocaleString()}
              />
            </MenuItem>
          ))
        )}
      </Menu>
    </>
  );
};

export default React.memo(TopNavBar);