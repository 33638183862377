import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box, Grid, Paper, styled, Typography, CircularProgress, List, ListItem, ListItemAvatar,
  ListItemText, Avatar, Button, Divider, Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Chip, useMediaQuery, useTheme, Autocomplete, IconButton, Tooltip, Snackbar,
  Alert, Drawer, AppBar, Toolbar, InputBase,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PushPinIcon from '@mui/icons-material/PushPin';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import CloseIcon from '@mui/icons-material/Close';
import MailIcon from '@mui/icons-material/Mail';
import SendIcon from '@mui/icons-material/Send';
import StarIcon from '@mui/icons-material/Star';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import debounce from 'lodash/debounce';
import axios from 'axios';

import {
  listInboxMessages,
  listSentMessages,
  sendMessage,
  markMessageAsRead,
  getMessage,
  listUsers,
  getCurrentUser,
  pinMessage,
  unpinMessage,
  softDeleteMessage,
  getConversation,
  listPinnedMessages,
} from '../api';

dayjs.extend(relativeTime);

const ExpandableContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: 'rgba(18, 18, 18, 0.8)',
  backdropFilter: 'blur(10px)',
  borderRadius: 16,
  padding: theme.spacing(2),
  height: '80vh',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    height: '100vh',
    borderRadius: 0,
    padding: 0,
  },
}));

const StyledListItem = styled(ListItem)(({ theme, selected, read }) => ({
  borderRadius: 8,
  marginBottom: theme.spacing(1),
  background: selected ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)' : 'rgba(255, 255, 255, 0.08)',
  fontWeight: read ? 'normal' : 'bold',
  '&:hover': {
    background: selected ? 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)' : 'rgba(255, 255, 255, 0.12)',
  },
}));

const ScrollableBox = styled(Box)({
  overflowY: 'auto',
  flexGrow: 1,
  '&::-webkit-scrollbar': {
    width: '0.4em',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    outline: '1px solid slategrey',
  },
});

const ConversationList = ({ conversations, selectedConversation, onSelectConversation, onComposeClick, currentUserId, showComposeButton }) => {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      {showComposeButton && (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" color="white">
            Conversations
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={onComposeClick}
            style={{ borderRadius: 20, backgroundColor: '#00bcd4' }}
          >
            COMPOSE
          </Button>
        </Box>
      )}
      <ScrollableBox>
        <List>
          {conversations.map((conversation) => {
            // Add null checks
            const isUserSender = conversation.sender?.id === currentUserId;
            const displayName = isUserSender 
              ? conversation.recipient?.full_name 
              : conversation.sender?.full_name;
            
            // If we don't have a valid displayName, skip this conversation
            if (!displayName) return null;

            return (
              <StyledListItem
                key={conversation.id}
                selected={selectedConversation && selectedConversation.id === conversation.id}
                onClick={() => onSelectConversation(conversation)}
                read={conversation.read_at !== null}
              >
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'grey.600' }}>{displayName.charAt(0)}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" color="white">
                      {displayName}
                    </Typography>
                  }
                  secondary={
                    <Typography variant="body2" color="grey.400" noWrap>
                      {isUserSender ? "You: " : ""}{conversation.subject} — {conversation.body?.substring(0, 30)}...
                    </Typography>
                  }
                />
                <Box display="flex" flexDirection="column" alignItems="flex-end">
                  <Typography variant="caption" color="grey.400">
                    {dayjs(conversation.created_at).fromNow()}
                  </Typography>
                  {conversation.is_pinned && <PushPinIcon color="primary" fontSize="small" />}
                  {conversation.attachments && conversation.attachments.length > 0 && (
                    <AttachFileIcon color="action" fontSize="small" />
                  )}
                </Box>
              </StyledListItem>
            );
          })}
        </List>
      </ScrollableBox>
    </Box>
  );
};

const ReplyBox = ({ onSend, currentUserId, conversation }) => {
  const [message, setMessage] = useState('');
  const [attachments, setAttachments] = useState([]);

  const handleSend = () => {
    if (message.trim() || attachments.length > 0) {
      const formData = new FormData();
      formData.append('body', message);
      formData.append('parent_message', conversation.id);
      
      // Get the subject from the first message or the conversation subject
      const subject = conversation.messages?.[0]?.subject || conversation.subject;
      formData.append('subject', `Re: ${subject}`);
      
      // Set the recipient as the sender of the first message if it's not the current user
      const recipientId = conversation.sender.id === currentUserId 
        ? conversation.recipient.id 
        : conversation.sender.id;
      formData.append('recipient', recipientId);

      attachments.forEach((file, index) => {
        formData.append(`attachments`, file);
      });
      onSend(formData);
      setMessage('');
      setAttachments([]);
    }
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setAttachments((prev) => [...prev, ...files]);
  };

  const handleDeleteAttachment = (indexToDelete) => {
    setAttachments((prev) => prev.filter((_, index) => index !== indexToDelete));
  };

  return (
    <Box mt={2} p={2} bgcolor="background.paper" borderRadius={2}>
      <Box mb={2}>
        {attachments.map((file, index) => (
          <Chip
            key={index}
            label={file.name}
            onDelete={() => handleDeleteAttachment(index)}
            style={{ marginRight: 8, marginBottom: 8 }}
          />
        ))}
      </Box>
      <Box display="flex" alignItems="center">
        <InputBase
          fullWidth
          multiline
          rows={2}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          sx={{ mr: 1 }}
        />
        <input
          accept="*/*"
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="raised-button-file">
          <IconButton component="span" color="primary">
            <AttachFileIcon />
          </IconButton>
        </label>
        <IconButton color="primary" onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

const ConversationView = ({ conversation, onDelete, onPin, currentUserId, onSend }) => {

  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const observerTarget = useRef(null);

  const loadConversation = useCallback(async () => {
    if (!conversation || loading || !hasMore) return;
    setLoading(true);
    try {
      const response = await getConversation(conversation.id, { page, page_size: 20 });
      const newMessages = Array.isArray(response.data) ? response.data : [response.data];
      setMessages((prevMessages) => {
        const existingIds = new Set(prevMessages.map((msg) => msg.id));
        const uniqueNewMessages = newMessages.filter((msg) => !existingIds.has(msg.id));
        return [...prevMessages, ...uniqueNewMessages];
      });
      setHasMore(newMessages.length === 20);
      setPage((prevPage) => prevPage + 1);
    } catch (error) {
      console.error('Error loading conversation:', error);
    } finally {
      setLoading(false);
    }
  }, [conversation, loading, hasMore, page]);

  useEffect(() => {
    if (conversation) {
      setMessages([]);
      setPage(1);
      setHasMore(true);
      loadConversation();
    }
  }, [conversation]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore && !loading) {
          loadConversation();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [loadConversation, hasMore, loading]);

  const renderAttachments = (attachments) => (
    <Box mt={2}>
      <Typography variant="subtitle2" gutterBottom>
        Attachments:
      </Typography>
      <Box display="flex" flexWrap="wrap">
        {attachments.map((attachment, index) => {
          const isImage = attachment.file_name.match(/\.(jpeg|jpg|gif|png)$/i);
          return (
            <Box key={index} m={1}>
              {isImage ? (
                <Box>
                  <img 
                    src={attachment.file} 
                    alt={attachment.file_name} 
                    style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'contain' }} 
                  />
                  <Typography variant="caption" display="block">
                    {attachment.file_name}
                  </Typography>
                </Box>
              ) : (
                <Button
                  variant="outlined"
                  startIcon={<DownloadIcon />}
                  onClick={() => window.open(attachment.file, '_blank')}
                >
                  {attachment.file_name}
                </Button>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  const renderMessage = (msg) => {
    const isCurrentUser = msg.sender.id === currentUserId;
    return (
      <ListItem 
        key={msg.id} 
        alignItems="flex-start"
        sx={{
          flexDirection: isCurrentUser ? 'row-reverse' : 'row',
          '& .MuiListItemText-root': {
            textAlign: isCurrentUser ? 'right' : 'left',
          },
          marginBottom: 2,
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ marginRight: isCurrentUser ? 0 : 2, marginLeft: isCurrentUser ? 2 : 0 }}>
            {msg.sender?.full_name.charAt(0)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box display="flex" justifyContent={isCurrentUser ? "flex-end" : "flex-start"} alignItems="center">
              <Typography variant="subtitle1" color="white">
                {isCurrentUser ? "You" : msg.sender?.full_name}
              </Typography>
            </Box>
          }
          secondary={
            <Box>
              <Typography component="span" variant="body2" color="text.primary">
                {msg.body}
              </Typography>
              {msg.attachments && msg.attachments.length > 0 && renderAttachments(msg.attachments)}
              <Typography variant="caption" color="grey.400" display="block">
                {dayjs(msg.created_at).format('MMM D, YYYY h:mm A')}
              </Typography>
              <Box mt={1}>
                <IconButton onClick={() => onDelete(msg.id)} size="small">
                  <DeleteIcon fontSize="small" />
                </IconButton>
                <IconButton onClick={() => onPin(msg.id, !msg.is_pinned)} size="small">
                  <PushPinIcon fontSize="small" color={msg.is_pinned ? "primary" : "inherit"} />
                </IconButton>
              </Box>
            </Box>
          }
        />
      </ListItem>
    );
  };

  if (!conversation) return null;

  return (
    <Box display="flex" flexDirection="column" color="white" height="100%">
      <Typography variant="h5" gutterBottom>
        {conversation.subject}
      </Typography>
      <ScrollableBox flexGrow={1}>
        <List>
          {messages.map(renderMessage)}
        </List>
        {loading && <CircularProgress />}
        {hasMore && <div ref={observerTarget} style={{ height: '20px' }} />}
      </ScrollableBox>
      <ReplyBox 
        onSend={onSend} 
        currentUserId={currentUserId} 
        conversation={conversation} 
      />
    </Box>
  );
};

const ComposeMessage = ({
  open,
  onClose,
  onSend,
  replyTo,
  userList,
  currentUserRole,
  onSearchChange,
  onLoadMore,
  nextPage,
  isSearching,
}) => {
  const [message, setMessage] = useState({ recipient: null, subject: '', body: '' });
  const [attachments, setAttachments] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (replyTo) {
      setMessage((prev) => ({
        ...prev,
        recipient: replyTo.sender,
        subject: `Re: ${replyTo.subject}`,
        body: `\n\nOn ${dayjs(replyTo.created_at).format('MMM D, YYYY h:mm A')}, ${
          replyTo.sender.full_name
        } wrote:\n> ${replyTo.body}`,
      }));
    }
  }, [replyTo]);

  const handleSend = () => {
    const formData = new FormData();
    
    formData.append('recipient', message.recipient?.id || '');
    formData.append('subject', message.subject);
    formData.append('body', message.body);
    if (replyTo) {
      formData.append('parent_message', replyTo.id);
    }
  
    attachments.forEach((file, index) => {
      formData.append(`attachments`, file);
    });
  
    onSend(formData);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setAttachments((prev) => [...prev, ...files]);
  };

  const handleDeleteAttachment = (indexToDelete) => {
    setAttachments((prev) => prev.filter((_, index) => index !== indexToDelete));
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {replyTo ? 'Reply to Message' : 'Compose Message'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Autocomplete
          options={userList}
          getOptionLabel={(option) => `${option.full_name} (${option.email})`}
          renderInput={(params) => (
            <TextField
              {...params}
              label="To"
              margin="normal"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {`${option.full_name} (${option.email})`}
            </li>
          )}
          value={message.recipient}
          onChange={(event, newValue) => {
            setMessage({ ...message, recipient: newValue });
          }}
          onInputChange={(event, newValue) => {
            setInputValue(newValue);
            onSearchChange(newValue);
          }}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          fullWidth
          loading={isSearching}
          loadingText="Searching users..."
          noOptionsText={inputValue.length < 2 ? 'Type to search users' : 'No users found'}
          disabled={!!replyTo}
        />
        {nextPage && (
          <Button onClick={onLoadMore}>
            Load More Users
          </Button>
        )}
        <TextField
          fullWidth
          label="Subject"
          value={message.subject}
          onChange={(e) => setMessage({ ...message, subject: e.target.value })}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Message"
          value={message.body}
          onChange={(e) => setMessage({ ...message, body: e.target.value })}
          multiline
          rows={4}
          margin="normal"
        />
        <Box mt={2}>
          <input
            accept="*/*"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="outlined" component="span" startIcon={<AttachFileIcon />}>
              Attach Files
            </Button>
          </label>
          <Box mt={1}>
            {attachments.map((file, index) => (
              <Chip
                key={index}
                label={file.name}
                onDelete={() => handleDeleteAttachment(index)}
                style={{ marginRight: 8, marginBottom: 8 }}
              />
            ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSend} color="primary" variant="contained" startIcon={<SendIcon />}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MessagingComponent = () => {
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [composeOpen, setComposeOpen] = useState(false);
  const [replyTo, setReplyTo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userList, setUserList] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [mobileView, setMobileView] = useState('list');
  const [activeTab, setActiveTab] = useState('all');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [isSearching, setIsSearching] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchConversations = useCallback(async () => {
    setLoading(true);
    try {
      let fetchedConversations = [];
      switch (activeTab) {
        case 'all':
          const inboxResponse = await listInboxMessages();
          const sentResponse = await listSentMessages();
          fetchedConversations = [
            ...(Array.isArray(inboxResponse.data) ? inboxResponse.data : [inboxResponse.data]),
            ...(Array.isArray(sentResponse.data) ? sentResponse.data : [sentResponse.data])
          ];
          break;
        case 'pinned':
          const pinnedResponse = await listPinnedMessages();
          fetchedConversations = Array.isArray(pinnedResponse.data) ? pinnedResponse.data : [pinnedResponse.data];
          break;
        default:
          fetchedConversations = [];
      }
      fetchedConversations.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      const uniqueConversations = fetchedConversations.filter((conv, index, self) =>
        index === self.findIndex((t) => t.id === conv.id)
      );
      setConversations(uniqueConversations);

      if (!isMobile && uniqueConversations.length > 0 && !selectedConversation) {
        handleSelectConversation(uniqueConversations[0]);
      }
    } catch (err) {
      console.error('Error fetching conversations:', err);
      setError('Failed to fetch conversations. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [activeTab, isMobile, selectedConversation]);

  const fetchUserList = useCallback(
    async (url = null, search = '') => {
      try {
        setIsSearching(true);
        let userData;
        if (url) {
          const response = await fetch(url);
          userData = await response.json();
        } else {
          userData = await listUsers({ search: search });
        }
        if (userData && Array.isArray(userData.results)) {
          setUserList((prevList) => {
            const newList = url ? [...prevList, ...userData.results] : userData.results;
            return newList;
          });
          setNextPage(userData.links.next);
        } else {
          console.warn('User list is undefined or not an array:', userData);
          setUserList([]);
        }
      } catch (error) {
        console.error('Error in fetchUserList:', error);
        setUserList([]);
      } finally {
        setIsSearching(false);
      }
    },
    []
  );

  const debouncedFetchUserList = useCallback(
    debounce((search) => {
      fetchUserList(null, search);
    }, 300),
    [fetchUserList]
  );

  useEffect(() => {
    fetchConversations();
    fetchUserList();
    getCurrentUser()
      .then((response) => {
        setCurrentUser(response.data);
      })
      .catch((error) => {
        console.error('Error fetching current user:', error);
      });
  }, [fetchConversations, fetchUserList]);

  const handleSelectConversation = useCallback(async (conversation) => {
    if (selectedConversation && selectedConversation.id === conversation.id) return;
    
    setSelectedConversation(null);
    setLoading(true);
    try {
      // Only mark as read if the user is the recipient and it's unread
      if (!conversation.read_at && activeTab === 'all' && conversation.recipient?.id === currentUser?.id) {
        await markMessageAsRead(conversation.id);
        fetchConversations();
      }
      setSelectedConversation(conversation);
      if (isMobile) {
        setMobileView('conversation');
      }
    } catch (err) {
      console.error('Error fetching conversation:', err);
      setError('Failed to load the conversation. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [selectedConversation, activeTab, fetchConversations, currentUser, isMobile]);

  const handleBackToList = () => {
    setMobileView('list');
    setSelectedConversation(null);
  };

  const handleSendMessage = async (formData) => {
    try {
      console.log("FormData entries:");
      for (let [key, value] of formData.entries()) {
        if (value instanceof File) {
          console.log(`${key}: File - name: ${value.name}, type: ${value.type}, size: ${value.size} bytes`);
        } else {
          console.log(`${key}: ${value}`);
        }
      }
  
      const response = await sendMessage(formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      setSnackbar({ open: true, message: 'Message sent successfully.', severity: 'success' });
      
      if (formData.get('parent_message')) {
        // If it's a reply, update the existing conversation
        setSelectedConversation(prevConversation => ({
          ...prevConversation,
          messages: [...(prevConversation.messages || []), response.data]
        }));
      } else {
        // If it's a new conversation, add it to the list
        const newConversation = response.data;
        setConversations(prevConversations => [newConversation, ...prevConversations]);
      }
      
      await fetchConversations();
    } catch (err) {
      console.error('Error sending message:', err);
      let errorMessage = 'Failed to send the message. Please try again.';
      if (err.response && err.response.data) {
        console.error('Server error response:', err.response.data);
        if (typeof err.response.data === 'object') {
          errorMessage = Object.entries(err.response.data)
            .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
            .join('; ');
        } else if (typeof err.response.data === 'string') {
          errorMessage = err.response.data;
        }
      }
      setError(errorMessage);
      setSnackbar({ open: true, message: errorMessage, severity: 'error' });
    }
  };

  const handlePinMessage = async (id, isPinned) => {
    try {
      if (isPinned) {
        await pinMessage(id);
      } else {
        await unpinMessage(id);
      }
      fetchConversations();
      if (selectedConversation && selectedConversation.id === id) {
        setSelectedConversation((prev) => ({ ...prev, is_pinned: isPinned }));
      }
    } catch (err) {
      console.error('Error pinning/unpinning message:', err);
      setError('Failed to pin/unpin the message. Please try again.');
    }
  };

  const handleDeleteMessage = async (id) => {
    try {
      await softDeleteMessage(id);
      fetchConversations();
      if (selectedConversation && selectedConversation.id === id) {
        setSelectedConversation(null);
      }
      setSnackbar({ open: true, message: 'Message deleted successfully.', severity: 'success' });
    } catch (err) {
      console.error('Error deleting message:', err);
      setError('Failed to delete the message. Please try again.');
      setSnackbar({ open: true, message: 'Failed to delete the message. Please try again.', severity: 'error' });
    }
  };

  const handleSearchChange = (newValue) => {
    setSearchTerm(newValue);
    if (newValue.length >= 2) {
      setIsSearching(true);
      debouncedFetchUserList(newValue);
    } else {
      setUserList([]);
      setNextPage(null);
      setIsSearching(false);
    }
  };

  const handleLoadMore = () => {
    if (nextPage) {
      fetchUserList(nextPage, searchTerm);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  useEffect(() => {
    fetchConversations();
  }, [activeTab, fetchConversations]);

  if (loading && !selectedConversation) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <>
      <ExpandableContainer>
        {isMobile ? (
          <>
            <AppBar position="static" color="default">
              <Toolbar>
                {mobileView === 'conversation' && (
                  <IconButton edge="start" color="inherit" onClick={handleBackToList}>
                    <ArrowBackIcon />
                  </IconButton>
                )}
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                  {mobileView === 'list' ? 'Messages' : selectedConversation?.subject}
                </Typography>
                {mobileView === 'list' && (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setReplyTo(null);
                      setComposeOpen(true);
                    }}
                  >
                    Compose
                  </Button>
                )}
              </Toolbar>
            </AppBar>
            {mobileView === 'list' ? (
              <Box height="calc(100% - 64px)" overflow="auto">
                <Box display="flex" justifyContent="space-between" p={2}>
                  <Button
                    startIcon={<MailIcon />}
                    onClick={() => setActiveTab('all')}
                    color={activeTab === 'all' ? 'primary' : 'inherit'}
                  >
                    All
                  </Button>
                  <Button
                    startIcon={<StarIcon />}
                    onClick={() => setActiveTab('pinned')}
                    color={activeTab === 'pinned' ? 'primary' : 'inherit'}
                  >
                    Pinned
                  </Button>
                </Box>
                <ConversationList
                  conversations={conversations}
                  selectedConversation={selectedConversation}
                  onSelectConversation={handleSelectConversation}
                  currentUserId={currentUser?.id}
                  showComposeButton={false}  // Don't show Compose button in mobile view
                />
              </Box>
            ) : (
              <Box height="calc(100% - 64px)" overflow="auto">
                <ConversationView
                  conversation={selectedConversation}
                  onDelete={handleDeleteMessage}
                  onPin={handlePinMessage}
                  currentUserId={currentUser?.id}
                  onSend={handleSendMessage}
                />
              </Box>
            )}
          </>
        ) : (
          <Grid container spacing={2} style={{ height: '100%' }}>
            <Grid item xs={12} md={4} style={{ height: '100%' }}>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Button
                  startIcon={<MailIcon />}
                  onClick={() => setActiveTab('all')}
                  color={activeTab === 'all' ? 'primary' : 'inherit'}
                >
                  All
                </Button>
                <Button
                  startIcon={<StarIcon />}
                  onClick={() => setActiveTab('pinned')}
                  color={activeTab === 'pinned' ? 'primary' : 'inherit'}
                >
                  Pinned
                </Button>
              </Box>
              <ConversationList
                conversations={conversations}
                selectedConversation={selectedConversation}
                onSelectConversation={handleSelectConversation}
                onComposeClick={() => {
                  setReplyTo(null);
                  setComposeOpen(true);
                }}
                currentUserId={currentUser?.id}
                showComposeButton={true}  // Show Compose button in desktop view
              />
            </Grid>
            <Grid item xs={12} md={8} style={{ height: '100%' }}>
              {selectedConversation ? (
                <ConversationView
                  conversation={selectedConversation}
                  onDelete={handleDeleteMessage}
                  onPin={handlePinMessage}
                  currentUserId={currentUser?.id}
                  onSend={handleSendMessage}
                />
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                  <Typography variant="h6" color="grey.500">
                    Select a conversation to view
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        )}
        <ComposeMessage
          open={composeOpen}
          onClose={() => {
            setComposeOpen(false);
            setReplyTo(null);
          }}
          onSend={handleSendMessage}
          replyTo={replyTo}
          userList={userList}
          currentUserRole={currentUser?.role}
          onSearchChange={handleSearchChange}
          onLoadMore={handleLoadMore}
          nextPage={nextPage}
          isSearching={isSearching}
        />
      </ExpandableContainer>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MessagingComponent;