import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
  Box,
  CircularProgress,
  Avatar,
  TextField,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Input,
  Tabs,
  Tab,
  Paper,
  ButtonGroup,DialogContentText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  getCurrentUser,
  listStudentCourses,
  changeAttendanceStatus,
  createAttendance,
  getAttendance,
  updateUserMe,
  createStudentFeedback,
  listStudentFeedbacks,
  createCourseHold,
  getStudyMaterialsForStudent,
  getStudentFeedbackHistory,
  getImageUrl,
  getCoordinator,
  getStudentAbsences,
} from '../api';
import { formatDate } from '../utils/dateUtils';
import AttendanceCalendar from '../components/AttendanceCalendar';
import { Description as DescriptionIcon } from '@mui/icons-material';
import ClassContentSection from '../components/ClassContentSection';
import MessagingComponent from '../components/MessagingComponent';
import { Warning as WarningIcon } from '@mui/icons-material';

import { listAttendances } from '../api';

const GlowingCard = styled(Card)(({ theme }) => ({
  background: '#1e1e1e',
  borderRadius: '10px',
  padding: '2px',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '2px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));

const ButtonContainer = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  gap: '12px',
  marginBottom: '16px',
});

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: 'white',
  borderRadius: '50px',
  padding: '8px 16px',
  fontSize: '14px',
  fontWeight: 'bold',
  textTransform: 'none',
  border: 'none',
  boxShadow: '0 4px 15px rgba(255, 255, 255, 0.1)',
  transition: 'all 0.3s ease',
  margin: '0 8px 8px 0',
  minWidth: '120px',
  '&:hover': {
    background: 'black',
    color: 'white',
    border: '1px solid white',
  },
  '&:disabled': {
    background: 'grey',
    color: 'rgba(255, 255, 255, 0.5)',
    border: 'none',
  },
}));

const CardInner = styled(CardContent)({
  background: '#1e1e1e',
  borderRadius: '8px',
  position: 'relative',
  zIndex: 1,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const ProfileImage = styled(Avatar)({
  width: 100,
  height: 100,
  margin: '0 auto 20px',
});

const ScrollableList = styled(List)({
  maxHeight: '300px',
  overflow: 'auto',
});

const StudentDashboard = () => {
  const [studentData, setStudentData] = useState(null);
  const [coursesData, setCoursesData] = useState([]);
  const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);
  const [coordinatorData, setCoordinatorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [updatedProfile, setUpdatedProfile] = useState({});
  const [attendanceData, setAttendanceData] = useState({});
  const [calendarModalOpen, setCalendarModalOpen] = useState(false);
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);
  const [feedbackDetailsModalOpen, setFeedbackDetailsModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [feedbacks, setFeedbacks] = useState([]);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [feedbackType, setFeedbackType] = useState('GENERAL');
  const [feedbackTopic, setFeedbackTopic] = useState('');
  const [feedbackContent, setFeedbackContent] = useState('');
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [studyMaterials, setStudyMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [courseHoldModalOpen, setCourseHoldModalOpen] = useState(false);
  const [showAbsenceWarning, setShowAbsenceWarning] = useState(false);
  const [absenceWarningMessage, setAbsenceWarningMessage] = useState('');
  
  const [courseHoldData, setCourseHoldData] = useState({
    start_date: null,
    end_date: null,
    reason: '',
  });
  const [newProfileImage, setNewProfileImage] = useState(null);

  const checkRecentAbsences = useCallback(async () => {
    if (!studentData) return;
  
    const endDate = dayjs().format('YYYY-MM-DD');
    const startDate = dayjs().subtract(30, 'days').format('YYYY-MM-DD');
  
    try {
      const response = await listAttendances({
        student: studentData.id,
        start_date: startDate,
        end_date: endDate,
      });
  
      const absences = response.data.filter(
        (attendance) => attendance.status === 'ABSENT'
      );
      const absenceCount = absences.length;
  
      if (absenceCount === 1) {
        setAbsenceWarningMessage(
          "You have already been marked absent once in the last 30 days. " +
            "Please make sure not to take any more leaves as it will impact your studies, " +
            "and more than 2 leaves will not be compensated."
        );
        setShowAbsenceWarning(true);
      } else if (absenceCount > 1) {
        setAbsenceWarningMessage(
          "You have been absent for more than 2 days now, and you will not be entitled to any more compensations. " +
            "Make sure to not take any more leaves to be able to complete your course on time.\n\n" +
            "आप अब 2 दिनों से ज़्यादा समय से अनुपस्थित हैं और आपको अब कोई अतिरिक्त क्लास नहीं मिलेगी। " +
            "सुनिश्चित करें कि आप समय पर अपना कोर्स पूरा करने के लिए अब और छुट्टी न लें।"
        );
        setShowAbsenceWarning(true);
      }
    } catch (error) {
      console.error('Error fetching recent attendance:', error);
    }
  }, [studentData]);
  
  useEffect(() => {
    if (studentData) checkRecentAbsences();
  }, [studentData, checkRecentAbsences]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [userResponse, coursesResponse, feedbacksResponse, feedbackHistoryResponse] = await Promise.all([
        getCurrentUser(),
        listStudentCourses({ student: studentData?.id }),
        listStudentFeedbacks(),
        getStudentFeedbackHistory(),
      ]);

      setStudentData(userResponse.data);
      setCoursesData(coursesResponse.data);
      setFeedbacks(feedbacksResponse.data);
      setFeedbackHistory(feedbackHistoryResponse.data);

      if (userResponse.data.coordinator) {
        const coordinatorResponse = await getCoordinator(userResponse.data.coordinator);
        setCoordinatorData(coordinatorResponse.data);
      } else {
        setCoordinatorData(null);
      }

      const materialsResponse = await getStudyMaterialsForStudent(userResponse.data.id);
      setStudyMaterials(materialsResponse.data);

      // Fetch attendance data for all courses
      const attendancePromises = coursesResponse.data.map(async (course) => {
        const today = dayjs().format('YYYY-MM-DD');
        const attendanceResponse = await getAttendance({
          student: userResponse.data.id,
          date: today,
          student_course: course.id
        });

        return {
          courseId: course.id,
          data: attendanceResponse.data[0] || null,
        };
      });
      
      const attendanceResults = await Promise.all(attendancePromises);
      const newAttendanceData = {};
      attendanceResults.forEach(result => {
        newAttendanceData[result.courseId] = result.data ? {
          marked: true,
          time: dayjs(result.data.timestamp),
          status: result.data.status,
          id: result.data.id
        } : {
          marked: false,
          time: null,
          status: null,
          id: null
        };
      });

      setAttendanceData(newAttendanceData);

    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [studentData?.id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const timer = setInterval(() => {
      const now = dayjs();
      if (now.hour() === 0 && now.minute() === 0) {
        setAttendanceData({});
      }
    }, 60000); // Check every minute
  
    return () => clearInterval(timer);
  }, []);
  const hasIndividualCourses = () => coursesData.some(course => !course.course.is_group_class);

  const isAnyIndividualCourseAttendanceMarked = () => {
    return coursesData.some(course => 
      !course.course.is_group_class && attendanceData[course.id]?.marked
    );
  };
  
  const shouldShowAttendanceButtons = (course) => {
    if (!course.course.is_group_class) {
      return true;  // Always show for individual courses
    }
    // For group courses, show only if there are no individual courses with marked attendance
    return !hasIndividualCourses() || !isAnyIndividualCourseAttendanceMarked();
  };
  
  const canJoinCourse = (course) => {
    if (!course.course.is_group_class) {
      return attendanceData[course.id]?.marked;
    }
    return isAnyIndividualCourseAttendanceMarked() || attendanceData[course.id]?.marked;
  };
  const handleProfileUpdate = async () => {
    try {
      const formData = new FormData();
      Object.keys(updatedProfile).forEach(key => {
        formData.append(key, updatedProfile[key]);
      });
      if (newProfileImage) {
        formData.append('profile_image', newProfileImage);
      }
      const response = await updateUserMe(formData);
      setStudentData({ ...studentData, ...response.data });
      setEditMode(false);
      setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
    } catch (err) {
      console.error('Error updating profile:', err);
      setSnackbar({ open: true, message: 'Failed to update profile. Please try again.', severity: 'error' });
    }
  };

  const handleProfileImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setNewProfileImage(e.target.files[0]);
    }
  };

  const handleMaterialClick = (material) => {
    setSelectedMaterial(material);
  };

  const handleCloseMaterialDialog = () => {
    setSelectedMaterial(null);
  };

  const handleRequestCourseHold = () => {
    setCourseHoldModalOpen(true);
  };

  const handleCourseHoldInputChange = (field, value) => {
    setCourseHoldData(prev => ({ ...prev, [field]: value }));
  };

  const handleSubmitCourseHold = async () => {
    try {
      const formattedHoldData = {
        student_course: coursesData[selectedCourseIndex].id,
        start_date: dayjs(courseHoldData.start_date).format('YYYY-MM-DD'),
        end_date: dayjs(courseHoldData.end_date).format('YYYY-MM-DD'),
        reason: courseHoldData.reason,
      };

      await createCourseHold(formattedHoldData);
      setSnackbar({ open: true, message: 'Course hold request submitted successfully', severity: 'success' });
      setCourseHoldModalOpen(false);
      setCourseHoldData({ start_date: null, end_date: null, reason: '' });
      fetchData();
    } catch (err) {
      console.error('Error submitting course hold request:', err);
      setSnackbar({ open: true, message: 'Failed to submit course hold request. Please try again.', severity: 'error' });
    }
  };

  const handleAttendanceMarking = async (status, courseId) => {
    try {
      const course = coursesData.find(c => c.id === courseId);
      const attendancePayload = {
        student: studentData.id,
        course: course.course.id,
        student_course: courseId,
        status: status,
        trainer: course.trainer?.id || null,
        date: dayjs().format('YYYY-MM-DD'),
      };
      
      const response = await createAttendance(attendancePayload);
      
      setAttendanceData(prev => ({
        ...prev,
        [courseId]: {
          marked: true,
          time: dayjs(),
          status: status,
          id: response.data.id
        }
      }));
      
      setSnackbar({ open: true, message: 'Attendance marked successfully', severity: 'success' });
    } catch (err) {
      console.error('Error marking attendance:', err.response?.data || err.message);
      setSnackbar({ open: true, message: 'Failed to mark attendance. Please try again.', severity: 'error' });
    }
  };

  const handleAttendanceChange = async (newStatus, courseId) => {
    try {
      const currentAttendance = attendanceData[courseId];
      
      let response;
      if (currentAttendance && currentAttendance.id) {
        // Change existing attendance
        response = await changeAttendanceStatus(currentAttendance.id, newStatus);
      } else {
        // Create new attendance
        response = await createAttendance({
          student_course: courseId,
          status: newStatus,
          date: dayjs().format('YYYY-MM-DD'),
        });
      }
  
      setAttendanceData(prev => ({
        ...prev,
        [courseId]: {
          marked: true,
          time: dayjs(),
          status: newStatus,
          id: response.data.id
        }
      }));
  
      setSnackbar({ open: true, message: 'Attendance updated successfully', severity: 'success' });
    } catch (err) {
      console.error('Error updating attendance:', err.response?.data || err.message);
      if (err.response?.status === 400) {
        setSnackbar({ open: true, message: err.response.data.error || 'Failed to update attendance.', severity: 'error' });
      } else {
        setSnackbar({ open: true, message: 'An error occurred while updating attendance.', severity: 'error' });
      }
    }
  };
  const handleJoinRoom = (courseId) => {
    const course = coursesData.find(c => c.id === courseId);
    if (course?.bbb_join_url) {
      window.open(course.bbb_join_url, '_blank');
    } else {
      setSnackbar({ open: true, message: 'Join URL not available. Please try again later.', severity: 'error' });
    }
  };

  const handleFeedbackSubmit = async () => {
    try {
      await createStudentFeedback({
        student: studentData.id,
        course: coursesData[selectedCourseIndex].course.id,
        feedback_type: feedbackType,
        topic: feedbackTopic,
        content: feedbackContent,
      });
      setFeedbackModalOpen(false);
      setFeedbackType('GENERAL');
      setFeedbackTopic('');
      setFeedbackContent('');
      setSnackbar({ open: true, message: 'Feedback submitted successfully', severity: 'success' });
      fetchData();
    } catch (err) {
      console.error('Error submitting feedback:', err);
      setSnackbar({ open: true, message: 'Failed to submit feedback. Please try again.', severity: 'error' });
    }
  };

  const handleFeedbackClick = (feedback) => {
    setSelectedFeedback(feedback);
    setFeedbackDetailsModalOpen(true);
  };

  const renderAttendanceButtons = (course) => {
    if (!shouldShowAttendanceButtons(course)) {
      return null;
    }
    
    const attendance = attendanceData[course.id];
    
    if (!attendance || !attendance.marked) {
      return (
        <ButtonContainer>
          <GradientButton onClick={() => handleAttendanceMarking('PRESENT', course.id)}>
            Mark Present
          </GradientButton>
          <GradientButton onClick={() => handleAttendanceMarking('TRAINER_ABSENT', course.id)}>
            Mark Trainer Absent
          </GradientButton>
        </ButtonContainer>
      );
    } else {
      return (
        <>
          <Box display="flex" alignItems="center" mb={1}>
            <Typography variant="body2" mr={1}>
              Current Status:
            </Typography>
            <Chip 
              label={attendance.status}
              color={attendance.status === 'PRESENT' ? 'success' : 'warning'}
              size="small"
            />
          </Box>
          <Typography variant="body2" mb={1}>
            Marked at: {attendance.time?.format('HH:mm:ss')}
          </Typography>
          <Typography variant="body2" mb={1}>
            Update attendance:
          </Typography>
          <ButtonContainer>
            <GradientButton 
              onClick={() => handleAttendanceChange('PRESENT', course.id)}
              disabled={attendance.status === 'PRESENT'}
            >
              Present
            </GradientButton>
            <GradientButton 
              onClick={() => handleAttendanceChange('ABSENT', course.id)}
              disabled={attendance.status === 'ABSENT'}
            >
              Absent
            </GradientButton>
            <GradientButton 
              onClick={() => handleAttendanceChange('TRAINER_ABSENT', course.id)}
              disabled={attendance.status === 'TRAINER_ABSENT'}
            >
              Trainer Absent
            </GradientButton>
          </ButtonContainer>
        </>
      );
    }
  };

  const renderClassButtons = (course) => {
    const isJoinEnabled = canJoinCourse(course);
    return (
      <Box mt={2}>
        <ButtonGroup variant="contained" color="primary" fullWidth>
          <Button 
            onClick={() => handleJoinRoom(course.id)}
            disabled={!isJoinEnabled || !course.bbb_join_url}
          >
            Join Class Room
          </Button>
          {course.course.is_group_class ? (
            course.course.trainers.map((trainer, index) => (
              <React.Fragment key={trainer.id}>
                {trainer.google_meet_link && (
                  <Button 
                    href={trainer.google_meet_link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    disabled={!isJoinEnabled}
                  >
                    Google Meet ({trainer.full_name})
                  </Button>
                )}
                {trainer.zoom_meeting_link && (
                  <Button 
                    href={trainer.zoom_meeting_link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    disabled={!isJoinEnabled}
                  >
                    Zoom ({trainer.full_name})
                  </Button>
                )}
              </React.Fragment>
            ))
          ) : course.trainer ? (
            <>
              {course.trainer.google_meet_link && (
                <Button 
                  href={course.trainer.google_meet_link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  disabled={!isJoinEnabled}
                >
                  Google Meet
                </Button>
              )}
              {course.trainer.zoom_meeting_link && (
                <Button 
                  href={course.trainer.zoom_meeting_link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  disabled={!isJoinEnabled}
                >
                  Zoom
                </Button>
              )}
            </>
          ) : null}
        </ButtonGroup>
      </Box>
    );
  };
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl" sx={{ paddingBottom: '400px' }}>
      <Box my={4}>
        <Typography variant="h4" gutterBottom color="white">Student Dashboard</Typography>
        <Grid container spacing={3}>
          {/* Left Column */}
          <Grid item xs={12} md={4}>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" gutterBottom>Profile</Typography>
        <ProfileImage src={getImageUrl(studentData?.profile_image)} alt={`${studentData?.first_name} ${studentData?.last_name}`} />
        {editMode ? (
          <>
            <TextField
              fullWidth
              label="First Name"
              value={updatedProfile.first_name || studentData?.first_name || ''}
              onChange={(e) => setUpdatedProfile({ ...updatedProfile, first_name: e.target.value })}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Last Name"
              value={updatedProfile.last_name || studentData?.last_name || ''}
              onChange={(e) => setUpdatedProfile({ ...updatedProfile, last_name: e.target.value })}
              margin="normal"
            />
                  <TextField
                    fullWidth
                    label="Email"
                    value={studentData?.email || ''}
                    margin="normal"
                    disabled
                  />
                  <TextField
                    fullWidth
                    label="About Me"
                    value={updatedProfile.about_me || studentData?.about_me || ''}
                    onChange={(e) => setUpdatedProfile({ ...updatedProfile, about_me: e.target.value })}
                    margin="normal"
                    multiline
                    rows={3}
                  />
                  <DatePicker
                    label="Date of Birth"
                    value={dayjs(updatedProfile.date_of_birth || studentData?.date_of_birth)}
                    onChange={(date) => setUpdatedProfile({ ...updatedProfile, date_of_birth: date?.format('YYYY-MM-DD') })}
                    renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                  />
                  <TextField
                    fullWidth
                    label="Phone"
                    value={updatedProfile.phone || studentData?.phone || ''}
                    onChange={(e) => setUpdatedProfile({ ...updatedProfile, phone: e.target.value })}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="City"
                    value={updatedProfile.city || studentData?.city || ''}
                    onChange={(e) => setUpdatedProfile({ ...updatedProfile, city: e.target.value })}
                    margin="normal"
                  />
                  <Input
                    type="file"
                    onChange={handleProfileImageChange}
                    style={{ display: 'none' }}
                    id="profile-image-input"
                  />
                  <label htmlFor="profile-image-input">
                    <Button variant="contained" component="span">
                      Upload Profile Image
                    </Button>
                  </label>
                  {newProfileImage && <Typography variant="caption">New image selected: {newProfileImage.name}</Typography>}
                  <Button onClick={handleProfileUpdate} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                    Save Changes
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h6">
  {`${studentData?.first_name || ''} ${studentData?.last_name || ''}`.trim()}
</Typography>
                  <Typography variant="body2" color="textSecondary" gutterBottom>{studentData?.email}</Typography>
                  <Typography variant="body1" paragraph>
                    <strong>About Me:</strong> {studentData?.about_me || 'No information provided'}
                  </Typography>
                  <Typography variant="body2">Date of Birth: {formatDate(studentData?.date_of_birth)}</Typography>
                  <Typography variant="body2">Phone: {studentData?.phone}</Typography>
                  <Typography variant="body2">City: {studentData?.city}</Typography>
                  <Button onClick={() => setEditMode(true)} variant="outlined" color="primary" style={{ marginTop: '10px' }}>
                    Edit Profile
                  </Button>
                </>
              )}
            </Paper>

            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
              <Typography variant="h6" gutterBottom>Course Information</Typography>
              {coursesData.length > 0 ? (
                <>
                  <Tabs
                    value={selectedCourseIndex}
                    onChange={(event, newValue) => setSelectedCourseIndex(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {coursesData.map((course, index) => (
                      <Tab key={course.id} label={course.course.name} />
                    ))}
                  </Tabs>
                  <Box mt={2}>
                    <Typography variant="body1">Course: {coursesData[selectedCourseIndex].course.name}</Typography>
                    <Typography variant="body2">Start Date: {formatDate(coursesData[selectedCourseIndex].start_date)}</Typography>
                    <Typography variant="body2">End Date: {formatDate(coursesData[selectedCourseIndex].end_date)}</Typography>
                    <Typography variant="body2">Class Time: {coursesData[selectedCourseIndex].course.class_time || coursesData[selectedCourseIndex].class_time}</Typography>
                    <Typography variant="body2">
                      Course Type: {coursesData[selectedCourseIndex].course.is_group_class ? 'Group' : 'Individual'}
                    </Typography>
                    <Button 
                      variant="contained" 
                      color="primary" 
                      onClick={handleRequestCourseHold}
                      style={{ marginTop: '10px' }}
                    >
                      Request Course Hold
                    </Button>
                  </Box>
                </>
              ) : (
                <Typography variant="body1">No course information available.</Typography>
              )}
            </Paper>

            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
              <Typography variant="h6" gutterBottom>Feedback/Complaints</Typography>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setFeedbackModalOpen(true)}
                sx={{ mb: 2 }}
              >
                Send Feedback
              </Button>
              <Typography variant="subtitle1" gutterBottom>Pending Feedback:</Typography>
              <ScrollableList>
                {feedbacks.filter(f => f.status !== 'RESOLVED').map((feedback) => (
                  <ListItem key={feedback.id} button onClick={() => handleFeedbackClick(feedback)}>
                    <ListItemText 
                      primary={feedback.topic} 
                      secondary={`${feedback.content.substring(0, 50)}...`}
                    />
                    <Chip label={feedback.status} color={feedback.status === 'PENDING' ? 'default' : 'primary'} />
                  </ListItem>
                ))}
              </ScrollableList>
              <Typography variant="subtitle1" gutterBottom>Recent Resolved Feedback:</Typography>
              {feedbackHistory.length > 0 && (
                <List>
                  <ListItem button onClick={() => handleFeedbackClick(feedbackHistory[0])}>
                    <ListItemText 
                      primary={feedbackHistory[0].topic} 
                      secondary={`${feedbackHistory[0].content.substring(0, 50)}...`}
                    />
                    <Chip label="RESOLVED" color="success" />
                  </ListItem>
                </List>
              )}
              {feedbackHistory.length > 1 && (
                <Button color="primary" onClick={() => setFeedbackDetailsModalOpen(true)}>
                  View All Feedback History
                </Button>
              )}
            </Paper>

            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h6" gutterBottom>Attendance Calendar</Typography>
              <Button onClick={() => setCalendarModalOpen(true)} variant="outlined" color="primary">
                View Full Calendar
              </Button>
            </Paper>
          </Grid>

          <Grid item xs={12} md={8}>
            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
              <Typography variant="h6" gutterBottom>Attendance and Class Access</Typography>
              {coursesData.map((course) => (
                <Box key={course.id} mb={2}>
                  <Typography variant="subtitle1">{course.course.name}</Typography>
                  {renderAttendanceButtons(course)}
                  {renderClassButtons(course)}
                </Box>
              ))}
            </Paper>

            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
              <Typography variant="h6" gutterBottom>Assigned Study Materials</Typography>
              <ScrollableList>
                {studyMaterials.map((material) => (
                  <ListItem 
                    key={material.id} 
                    button 
                    onClick={() => handleMaterialClick(material)}
                  >
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText 
                      primary={material.topic} 
                      secondary={`Available until: ${dayjs(material.expiry_date).format('MMMM D, YYYY')}`}
                    />
                  </ListItem>
                ))}
              </ScrollableList>
            </Paper>

            <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
              <Typography variant="h6" gutterBottom>Additional Information</Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Trainer Information</Typography>
                  {coursesData[selectedCourseIndex]?.trainer ? (
                    <>
                      <ProfileImage src={coursesData[selectedCourseIndex].trainer.profile_image} alt={coursesData[selectedCourseIndex].trainer.full_name} />
                      <Typography variant="body1">{coursesData[selectedCourseIndex].trainer.full_name}</Typography>
                      <Typography variant="body2">{coursesData[selectedCourseIndex].trainer.about_me}</Typography>
                      <Typography variant="body2">City: {coursesData[selectedCourseIndex].trainer.city}</Typography>
                      </>
                  ) : coursesData[selectedCourseIndex]?.course.is_group_class ? (
                    <>
                      <Typography variant="body1">Group Course Trainers:</Typography>
                      {coursesData[selectedCourseIndex].course.trainer_assignments.map((assignment, index) => (
                        <Box key={index} mb={2}>
                          <Typography variant="body2">{assignment.trainer_name}</Typography>
                          <Typography variant="caption">
                            Time: {assignment.start_time} - {assignment.end_time}
                          </Typography>
                        </Box>
                      ))}
                      <Chip label="Group Course" color="secondary" size="small" style={{ marginTop: '10px' }} />
                    </>
                  ) : (
                    <Typography variant="body1">No trainer assigned yet.</Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1" gutterBottom>Coordinator Profile</Typography>
                  {coordinatorData ? (
                    <>
                      <ProfileImage src={coordinatorData.profile_image} alt={coordinatorData.name} />
                      <Typography variant="body1">{coordinatorData.name}</Typography>
                      <Typography variant="body2">Phone: {coordinatorData.phone}</Typography>
                    </>
                  ) : (
                    <Typography variant="body1">No coordinator assigned yet.</Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>

            

            {/* Class Content Section */}
            <Paper elevation={3} style={{ padding: '20px' }}>
              <Typography variant="h6" gutterBottom>Class Content</Typography>
              <ClassContentSection studentId={studentData?.id} />
            </Paper>
          </Grid>
          
        </Grid>
        {/* Messaging Component */}
        {/* Messaging Component */}
        <Box mt={4}> {/* Add margin top */}
          <Paper 
            elevation={3} 
            style={{ 
              padding: '20px', 
              paddingTop: '40px', // Increase top padding
              marginBottom: '20px'
            }}
          >
            <Typography variant="h6" gutterBottom>Messaging</Typography>
            <MessagingComponent />
          </Paper>
        </Box>
      </Box>
     

      {/* Attendance Calendar Modal */}
      <Dialog
        open={calendarModalOpen}
        onClose={() => setCalendarModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Attendance Calendar
        </DialogTitle>
        <DialogContent>
          <AttendanceCalendar
            studentId={studentData?.id}
            trainerId={coursesData[selectedCourseIndex]?.trainer?.id}
            userRole="STUDENT"
            onRequestReview={() => {}} // Implement this if needed
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCalendarModalOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* Feedback Modal */}
      <Dialog
        open={feedbackModalOpen}
        onClose={() => setFeedbackModalOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Send Feedback</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Feedback Type</InputLabel>
            <Select
              value={feedbackType}
              onChange={(e) => setFeedbackType(e.target.value)}
            >
              <MenuItem value="GENERAL">General</MenuItem>
              <MenuItem value="COURSE">Course Related</MenuItem>
              <MenuItem value="TRAINER">Trainer Related</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            margin="normal"
            label="Feedback Topic"
            value={feedbackTopic}
            onChange={(e) => setFeedbackTopic(e.target.value)}
          />
          <TextField
            fullWidth
            margin="dense"
            label="Your Feedback"
            multiline
            rows={4}
            value={feedbackContent}
            onChange={(e) => setFeedbackContent(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbackModalOpen(false)} color="primary">Cancel</Button>
          <Button onClick={handleFeedbackSubmit} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      {/* Course Hold Request Modal */}
      <Dialog open={courseHoldModalOpen} onClose={() => setCourseHoldModalOpen(false)}>
        <DialogTitle>Request Course Hold</DialogTitle>
        <DialogContent>
          <DatePicker
            label="Start Date"
            value={courseHoldData.start_date}
            onChange={(date) => handleCourseHoldInputChange('start_date', date)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
          <DatePicker
            label="End Date"
            value={courseHoldData.end_date}
            onChange={(date) => handleCourseHoldInputChange('end_date', date)}
            renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
          />
          <TextField
            fullWidth
            multiline
            rows={4}
            margin="normal"
            label="Reason for Hold"
            value={courseHoldData.reason}
            onChange={(e) => handleCourseHoldInputChange('reason', e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCourseHoldModalOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmitCourseHold} color="primary">
            Submit Request
          </Button>
        </DialogActions>
      </Dialog>

      {/* Feedback Details Modal */}
      <Dialog
        open={feedbackDetailsModalOpen}
        onClose={() => setFeedbackDetailsModalOpen(false)}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Feedback Details</DialogTitle>
        <DialogContent>
          <Typography variant="h6" gutterBottom>Pending Feedbacks</Typography>
          <List>
            {feedbacks.filter(f => f.status !== 'RESOLVED').map((feedback) => (
              <ListItem key={feedback.id}>
                <ListItemText 
                  primary={feedback.topic}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        Type: {feedback.feedback_type}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textPrimary">
                        {feedback.content}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textSecondary">
                        Status: {feedback.status}
                      </Typography>
                      {feedback.admin_remarks && (
                        <>
                          <br />
                          <Typography component="span" variant="body2" color="textSecondary">
                            Admin Remarks: {feedback.admin_remarks}
                          </Typography>
                        </>
                      )}
                    </>
                  }
                />
                <Chip label={feedback.status} color={feedback.status === 'PENDING' ? 'default' : 'primary'} />
              </ListItem>
            ))}
          </List>
          <Typography variant="h6" gutterBottom>Feedback History</Typography>
          <List>
            {feedbackHistory.map((feedback) => (
              <ListItem key={feedback.id}>
                <ListItemText 
                  primary={feedback.topic}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="textPrimary">
                        Type: {feedback.feedback_type}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textPrimary">
                        {feedback.content}
                      </Typography>
                      <br />
                      <Typography component="span" variant="body2" color="textSecondary">
                        Status: RESOLVED
                      </Typography>
                      {feedback.admin_remarks && (
                        <>
                          <br />
                          <Typography component="span" variant="body2" color="textSecondary">
                            Admin Remarks: {feedback.admin_remarks}
                          </Typography>
                        </>
                      )}
                    </>
                  }
                />
                <Chip label="RESOLVED" color="success" />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeedbackDetailsModalOpen(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={Boolean(selectedMaterial)} onClose={handleCloseMaterialDialog} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedMaterial?.topic}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedMaterial?.course?.name}</Typography>
          <Typography variant="body2">
            Available until: {dayjs(selectedMaterial?.expiry_date).format('MMMM D, YYYY')}
          </Typography>
          <List>
            {selectedMaterial?.files.map((file) => (
              <ListItem key={file.id}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={file.file_name} />
                <Box>
                  {file.file_type.startsWith('image/') ? (
                    <img src={file.file} alt={file.file_name} style={{ maxWidth: '100%', maxHeight: '300px' }} />
                  ) : file.file_type === 'application/pdf' ? (
                    <iframe src={file.file} width="100%" height="500px" title={file.file_name} />
                  ) : (
                    <Typography variant="body2">Preview not available for this file type.</Typography>
                  )}
                </Box>
                <Button href={file.file} target="_blank" rel="noopener noreferrer">
                  Download
                </Button>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMaterialDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>{/* Absence Warning Dialog */}
      <Dialog
  open={showAbsenceWarning}
  onClose={() => setShowAbsenceWarning(false)}
  aria-labelledby="absence-warning-dialog-title"
  aria-describedby="absence-warning-dialog-description"
>
  <DialogTitle id="absence-warning-dialog-title">
    <Box display="flex" alignItems="center">
      <WarningIcon color="warning" sx={{ mr: 1 }} />
      Attendance Warning
    </Box>
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="absence-warning-dialog-description">
      <Typography>
        You have been absent for more than 2 days now, and you will not be entitled to any more compensations. 
        Make sure to not take any more leaves to be able to complete your course on time.
      </Typography>
      <br />
      <Typography>
        आप 2 दिनों से ज़्यादा समय से अनुपस्थित हैं और आपको अब कोई अतिरिक्त क्लास नहीं मिलेगी। 
        सुनिश्चित करें कि आप समय पर अपना कोर्स पूरा करने के लिए अब और छुट्टी न लें।
      </Typography>
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setShowAbsenceWarning(false)} color="primary">
      Understood
    </Button>
  </DialogActions>
</Dialog>


      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default StudentDashboard;