import React, { useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Grid, Typography, Alert
} from '@mui/material';
import { updateSalary, handleApiError } from '../api';

function UpdateSalaryModal({ open, onClose, trainerId, trainerName }) {
  const [salaryData, setSalaryData] = useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    group_class_compensation: 0,
    performance_incentive: 0,
    performance_depreciation: 0,
    arrears: 0,
    tds: 0,
    pf: 0,
    advance: 0,
    advance_recovery: 0,
    loss_recovery: 0
  });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSalaryData(prev => ({ ...prev, [name]: name === 'year' || name === 'month' ? parseInt(value) : parseFloat(value) || 0 }));
  };

  const handleSubmit = async () => {
    try {
      await updateSalary(trainerId, salaryData);
      setSuccess(true);
      setError(null);
      setTimeout(() => {
        onClose();
        setSuccess(false);
      }, 2000);
    } catch (error) {
      const errorMessage = handleApiError(error);
      setError(errorMessage.message || 'An error occurred while updating the salary.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Update Salary for {trainerName}</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>Salary updated successfully!</Alert>}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Year"
              name="year"
              type="number"
              value={salaryData.year}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Month"
              name="month"
              type="number"
              value={salaryData.month}
              onChange={handleInputChange}
              margin="normal"
              InputProps={{ inputProps: { min: 1, max: 12 } }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Group Class Compensation"
              name="group_class_compensation"
              type="number"
              value={salaryData.group_class_compensation}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Performance Incentive"
              name="performance_incentive"
              type="number"
              value={salaryData.performance_incentive}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Performance Depreciation"
              name="performance_depreciation"
              type="number"
              value={salaryData.performance_depreciation}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Arrears"
              name="arrears"
              type="number"
              value={salaryData.arrears}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="TDS"
              name="tds"
              type="number"
              value={salaryData.tds}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="PF"
              name="pf"
              type="number"
              value={salaryData.pf}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Advance"
              name="advance"
              type="number"
              value={salaryData.advance}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Advance Recovery"
              name="advance_recovery"
              type="number"
              value={salaryData.advance_recovery}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Loss Recovery"
              name="loss_recovery"
              type="number"
              value={salaryData.loss_recovery}
              onChange={handleInputChange}
              margin="normal"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Update Salary
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateSalaryModal;