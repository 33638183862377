import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import dayjs from 'dayjs';

function SalaryBreakdownModal({ open, onClose, salaryData }) {
  if (!salaryData) return null;

  const { year, month, total_salary, calculation_details } = salaryData;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Salary Breakdown for {dayjs().month(month - 1).format('MMMM')} {year}
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>
          Total Salary: ₹{parseFloat(total_salary).toFixed(2)}
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Category</TableCell>
                <TableCell>Item</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell rowSpan={5}>Earnings</TableCell>
                <TableCell>Base Salary</TableCell>
                <TableCell align="right">{calculation_details.base_salary}</TableCell>
              </TableRow>
              {Object.entries(calculation_details.additions).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2} align="right"><strong>Total Earnings</strong></TableCell>
                <TableCell align="right"><strong>{calculation_details.total_earnings}</strong></TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={Object.keys(calculation_details.deductions).length + 1}>Deductions</TableCell>
              </TableRow>
              {Object.entries(calculation_details.deductions).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}</TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={2} align="right"><strong>Total Deductions</strong></TableCell>
                <TableCell align="right"><strong>{calculation_details.total_deductions}</strong></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {calculation_details.trainer_details && (
          <Typography variant="body2" style={{ marginTop: '1rem' }}>
            Hourly Rate: {calculation_details.trainer_details.hourly_rate}, 
            Total Hours: {calculation_details.trainer_details.total_hours}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SalaryBreakdownModal;