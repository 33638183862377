import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Box, Paper, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, Button, CircularProgress, Alert, Link, Dialog, DialogTitle,
  DialogContent, DialogActions, TextField, Snackbar, Grid
} from '@mui/material';
import { 
  getSalaryHistory, 
  downloadSalarySlip, 
  getCurrentUser, 
  handleApiError, 
  requestLeave, 
  listLeaveRequests, 
  getLeaveHistory,
  getLeaveRequestHistory,
  listTrainerAudits,
  addTrainerRemarks, getCurrentMonthBaseSalary
} from '../api';
import dayjs from 'dayjs';
import SalaryBreakdownModal from './SalaryBreakdownModal';
import AuditDetailsModal from '../components/AuditDetailsModal';
import AddAuditModal from '../components/AddAuditModal'; // You'll need to create this component
import AuditHistoryModal from '../components/AuditHistoryModal'; // You'll need to create this component


function MyEmploymentPage() {
  const [salaryHistory, setSalaryHistory] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [leaveRequestHistory, setLeaveRequestHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedSalary, setSelectedSalary] = useState(null);
  const [salaryModalOpen, setSalaryModalOpen] = useState(false);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);
  const [leaveFormData, setLeaveFormData] = useState({
    start_date: '',
    end_date: '',
    reason: '',
    uploaded_files: []
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [audits, setAudits] = useState([]);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [auditDetailsModalOpen, setAuditDetailsModalOpen] = useState(false);
  const [currentMonthSalary, setCurrentMonthSalary] = useState(null);

  useEffect(() => {
    fetchCurrentUserAndData();
  }, []);

  const fetchCurrentUserAndData = async () => {
    try {
      setLoading(true);
      const userResponse = await getCurrentUser();
      setCurrentUser(userResponse.data);

      const [
        historyResponse, 
        leaveRequestsResponse, 
        leaveHistoryResponse, 
        leaveRequestHistoryResponse,
        currentMonthSalaryResponse  // Add this line
      ] = await Promise.all([
        getSalaryHistory(userResponse.data.id),
        listLeaveRequests(),
        getLeaveHistory(),
        getLeaveRequestHistory(),
        getCurrentMonthBaseSalary(userResponse.data.id)  // Add this line
      ]);

      setSalaryHistory(historyResponse.data || []);
      setLeaveRequests(leaveRequestsResponse.data || []);
      setLeaveHistory(leaveHistoryResponse.data || []);
      setLeaveRequestHistory(leaveRequestHistoryResponse.data || []);
      setCurrentMonthSalary(currentMonthSalaryResponse.data);  // Add this line

      if (userResponse.data.role === 'TRAINER') {
        const auditsResponse = await listTrainerAudits(userResponse.data.id);
        setAudits(auditsResponse.data || []);
      }
    } catch (error) {
      setError(handleApiError(error));
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSlip = async (year, month) => {
    try {
      if (!currentUser) {
        throw new Error('Current user not found');
      }
      const response = await downloadSalarySlip(currentUser.id, { year, month });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `salary_slip_${year}_${month}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setSnackbar({ open: true, message: 'Failed to download salary slip', severity: 'error' });
    }
  };

  const handleMonthClick = (salary) => {
    setSelectedSalary(salary);
    setSalaryModalOpen(true);
  };

  const handleLeaveRequest = async () => {
    try {
      await requestLeave(leaveFormData);
      setSnackbar({ open: true, message: 'Leave request submitted successfully', severity: 'success' });
      setLeaveDialogOpen(false);
      fetchCurrentUserAndData(); // Refresh data
    } catch (error) {
      const errorMessage = handleApiError(error);
      setSnackbar({ open: true, message: errorMessage || 'Failed to submit leave request. Please try again.', severity: 'error' });
    }
  };

  const handleLeaveFormChange = (event) => {
    const { name, value, files } = event.target;
    if (name === 'uploaded_files') {
      setLeaveFormData(prev => ({ ...prev, [name]: Array.from(files) }));
    } else {
      setLeaveFormData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleAddRemarks = async (auditId, remarks) => {
    try {
      await addTrainerRemarks(auditId, remarks);
      fetchCurrentUserAndData();
      setAuditDetailsModalOpen(false);
      setSnackbar({ open: true, message: 'Remarks added successfully', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: handleApiError(error), severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="calc(100vh - 64px)">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Box my={4}>
        <Typography variant="h4" gutterBottom>My Employment</Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        
        <Box my={4}>
          <Typography variant="h5" gutterBottom>Current Month's Base Salary (Tentative)</Typography>
          {currentMonthSalary ? (
            <Paper elevation={3} sx={{ p: 2 }}>
              <Typography variant="h6">
                Current Month Base Salary: {currentMonthSalary.current_month_base_salary}
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                Calculation Details:
              </Typography>
            
              <Typography>
                Hours Worked: {currentMonthSalary.calculation_details.hours_worked}
              </Typography>
              <Typography>
                As of: {new Date(currentMonthSalary.calculation_details.as_of_date).toLocaleDateString()}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 2, fontStyle: 'italic' }}>
                Note: This is a tentative amount and does not include compensation for group classes, or any deductions or incentives
              </Typography>
            </Paper>
          ) : (
            <Typography>Current month's salary information not available.</Typography>
          )}
        </Box>
        <Box my={4}>
          <Typography variant="h5" gutterBottom>Salary History</Typography>
          {salaryHistory.length > 0 ? (
            <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Year</TableCell>
                    <TableCell>Month</TableCell>
                    <TableCell>Total Salary</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {salaryHistory.map((salary) => (
                    <TableRow key={`${salary.year}-${salary.month}`}>
                      <TableCell>{salary.year}</TableCell>
                      <TableCell>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => handleMonthClick(salary)}
                        >
                          {dayjs().month(salary.month - 1).format('MMMM')}
                        </Link>
                      </TableCell>
                      <TableCell>₹{parseFloat(salary.total_salary).toFixed(2)}</TableCell>
                      <TableCell>
                        <Button 
                          variant="contained" 
                          color="primary" 
                          onClick={() => handleDownloadSlip(salary.year, salary.month)}
                        >
                          Download Slip
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No salary history available.</Typography>
          )}
        </Box>
        
        <Box my={4}>
          <Typography variant="h5" gutterBottom>Leave Requests</Typography>
          <Button variant="contained" color="primary" onClick={() => setLeaveDialogOpen(true)} sx={{ mb: 2 }}>
            Request Leave
          </Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveRequests.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{new Date(request.start_date).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(request.end_date).toLocaleDateString()}</TableCell>
                    <TableCell>{request.reason}</TableCell>
                    <TableCell>{request.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box my={4}>
          <Typography variant="h5" gutterBottom>Leave Request History</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Start Date</TableCell>
                  <TableCell>End Date</TableCell>
                  <TableCell>Reason</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Admin Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveRequestHistory.map((request) => (
                  <TableRow key={request.id}>
                    <TableCell>{new Date(request.start_date).toLocaleDateString()}</TableCell>
                    <TableCell>{new Date(request.end_date).toLocaleDateString()}</TableCell>
                    <TableCell>{request.reason}</TableCell>
                    <TableCell>{request.status}</TableCell>
                    <TableCell>{request.admin_remarks || '-'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Box my={4}>
          <Typography variant="h5" gutterBottom>Leave History</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Month</TableCell>
                  <TableCell>Year</TableCell>
                  <TableCell>Leaves Taken</TableCell>
                  <TableCell>Leaves Remaining</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leaveHistory.map((history) => (
                  <TableRow key={`${history.year}-${history.month}`}>
                    <TableCell>{dayjs().month(history.month - 1).format('MMMM')}</TableCell>
                    <TableCell>{history.year}</TableCell>
                    <TableCell>{history.leaves_taken}</TableCell>
                    <TableCell>{history.leaves_remaining}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        {currentUser && currentUser.role === 'TRAINER' && (
          <Box my={4}>
            <Typography variant="h5" gutterBottom>My Audits</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Audit Date</TableCell>
                    <TableCell>Overall Score</TableCell>
                    <TableCell>Auditor</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {audits.map((audit) => (
                    <TableRow key={audit.id}>
                      <TableCell>{new Date(audit.audit_date).toLocaleDateString()}</TableCell>
                      <TableCell>{audit.overall_score}</TableCell>
                      <TableCell>{audit.auditor.full_name}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setSelectedAudit(audit);
                            setAuditDetailsModalOpen(true);
                          }}
                        >
                          View Details
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>

      <SalaryBreakdownModal
        open={salaryModalOpen}
        onClose={() => setSalaryModalOpen(false)}
        salaryData={selectedSalary}
      />

      <Dialog open={leaveDialogOpen} onClose={() => setLeaveDialogOpen(false)}>
        <DialogTitle>Request Leave</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Start Date"
            type="date"
            name="start_date"
            value={leaveFormData.start_date}
            onChange={handleLeaveFormChange}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <TextField
            fullWidth
            label="End Date"
            type="date"
            name="end_date"
            value={leaveFormData.end_date}
            onChange={handleLeaveFormChange}
            InputLabelProps={{ shrink: true }}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Reason"
            multiline
            rows={4}
            name="reason"
            value={leaveFormData.reason}
            onChange={handleLeaveFormChange}
            margin="normal"
          />
          <input
            accept="image/*,application/pdf"
            style={{ display: 'none' }}
            id="raised-button-file"
            multiple
            type="file"
            name="uploaded_files"
            onChange={handleLeaveFormChange}
          />
          <label htmlFor="raised-button-file">
            <Button variant="contained" component="span">
              Upload Attachments
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setLeaveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleLeaveRequest} color="primary">
            Submit Request
          </Button>
        </DialogActions>
      </Dialog>

      <AuditDetailsModal
        open={auditDetailsModalOpen}
        onClose={() => setAuditDetailsModalOpen(false)}
        audit={selectedAudit}
        onAddRemarks={handleAddRemarks}
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default MyEmploymentPage;