import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  CircularProgress,
  Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import { listBBBRecordings, deleteBBBRecording, syncBBBRecordings } from '../api';
import { getCurrentUser } from '../api';
import { format, parseISO } from 'date-fns';
import { debounce } from 'lodash';

const ClassRecordingsPage = () => {
  const [recordings, setRecordings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({
    student: '',
    trainer: '',
    startDate: null,
    endDate: null,
    room: '',
  });
  const [currentUser, setCurrentUser] = useState(null);
  const [syncing, setSyncing] = useState(false);
  const [syncMessage, setSyncMessage] = useState(null);

  const fetchRecordings = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const formattedFilters = {
        ...filters,
        startDate: filters.startDate ? format(filters.startDate, 'yyyy-MM-dd') : null,
        endDate: filters.endDate ? format(filters.endDate, 'yyyy-MM-dd') : null,
      };
      const response = await listBBBRecordings(formattedFilters);
      setRecordings(response.data);
    } catch (err) {
      console.error('Error fetching recordings:', err);
      setError('Failed to fetch recordings. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const userResponse = await getCurrentUser();
        setCurrentUser(userResponse.data);
      } catch (err) {
        console.error('Error fetching current user:', err);
        setError('Failed to fetch user data. Please try again.');
      }
    };

    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchRecordings();
    }
  }, [currentUser, fetchRecordings]);

  const debouncedFetchRecordings = useCallback(
    debounce(() => {
      fetchRecordings();
    }, 500),
    [fetchRecordings]
  );

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({ ...prevFilters, [name]: value }));
  };

  const handleDateChange = (name, date) => {
    setFilters(prevFilters => ({ ...prevFilters, [name]: date }));
  };

  const handleDelete = async (recordingId) => {
    try {
      await deleteBBBRecording(recordingId);
      fetchRecordings();
    } catch (err) {
      console.error('Error deleting recording:', err);
      setError('Failed to delete recording. Please try again.');
    }
  };

  const handlePlayback = (playbackUrl) => {
    window.open(playbackUrl, '_blank');
  };

  const handleSync = async () => {
    setSyncing(true);
    setSyncMessage(null);
    try {
      const response = await syncBBBRecordings();
      setSyncMessage(`Sync initiated. Task ID: ${response.data.task_id}`);
      setTimeout(() => {
        fetchRecordings();
      }, 5000); // Refresh recordings after 5 seconds
    } catch (err) {
      console.error('Error syncing recordings:', err);
      setSyncMessage('Failed to sync recordings. Please try again.');
    } finally {
      setSyncing(false);
    }
  };

  const renderFilters = () => {
    if (!currentUser) return null;

    if (currentUser.role === 'ADMIN' || currentUser.role === 'MANAGER') {
      return (
        <>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Student"
              name="student"
              value={filters.student}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Trainer"
              name="trainer"
              value={filters.trainer}
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Room"
              name="room"
              value={filters.room}
              onChange={handleFilterChange}
            />
          </Grid>
        </>
      );
    } else if (currentUser.role === 'TRAINER') {
      return (
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            label="Student"
            name="student"
            value={filters.student}
            onChange={handleFilterChange}
          />
        </Grid>
      );
    }
    return null;
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Typography variant="h4" gutterBottom>Class Recordings</Typography>
      <Box my={4}>
        <Grid container spacing={3}>
          {renderFilters()}
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              label="Start Date"
              value={filters.startDate}
              onChange={(date) => handleDateChange('startDate', date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <DatePicker
              label="End Date"
              value={filters.endDate}
              onChange={(date) => handleDateChange('endDate', date)}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={fetchRecordings}>
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Box>

      {currentUser && (currentUser.role === 'ADMIN' || currentUser.role === 'MANAGER') && (
        <Box my={2} display="flex" justifyContent="flex-end">
          <Button 
            variant="contained" 
            color="secondary" 
            startIcon={<SyncIcon />}
            onClick={handleSync}
            disabled={syncing}
          >
            {syncing ? 'Syncing...' : 'Sync Recordings'}
          </Button>
        </Box>
      )}

      {syncMessage && (
        <Box my={2}>
          <Alert severity={syncMessage.includes('Failed') ? 'error' : 'info'}>
            {syncMessage}
          </Alert>
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Room</TableCell>
              <TableCell>Student</TableCell>
              <TableCell>Trainer</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Participants</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recordings.map((recording) => (
              <TableRow key={recording.id}>
                <TableCell>
                  {format(parseISO(recording.creation_date), 'd-MMM-yyyy')}
                </TableCell>
                <TableCell>{recording.meta_data.name}</TableCell>
                <TableCell>{recording.student}</TableCell>
                <TableCell>{recording.trainer}</TableCell>
                <TableCell>
                  {Math.round((parseInt(recording.meta_data.endTime) - parseInt(recording.meta_data.startTime)) / 60000)} minutes
                </TableCell>
                <TableCell>{recording.meta_data.participants}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handlePlayback(recording.playback_url)}>
                    <PlayArrowIcon />
                  </IconButton>
                  {currentUser && (currentUser.role === 'ADMIN' || currentUser.role === 'MANAGER') && (
                    <IconButton onClick={() => handleDelete(recording.id)}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default ClassRecordingsPage;