import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Paper,
  Chip,
  Menu,
  MenuItem,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import dayjs from 'dayjs';
import { 
  listAttendances, 
  createAttendanceReview, 
  updateAttendance,
  getAttendance, 
  addClassContent
} from '../api'; 



const CalendarContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  backdropFilter: 'blur(10px)',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: `
    0 0 10px rgba(255, 165, 0, 0.3),
    0 0 20px rgba(128, 0, 128, 0.3),
    0 0 30px rgba(255, 0, 0, 0.3)
  `,
  background: 'linear-gradient(135deg, rgba(255, 165, 0, 0.1), rgba(128, 0, 128, 0.1), rgba(255, 0, 0, 0.1))',
}));

const DayCell = styled(Paper)(({ theme, isSelected, isToday, status }) => ({
  height: '70px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: 
    isSelected ? 'rgba(255, 255, 255, 0.3)' :
    isToday ? 'rgba(255, 255, 255, 0.1)' :
    'transparent',
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
}));

const StatusText = styled(Typography)(({ theme, status }) => ({
  fontSize: '10px',
  color: 
    status === 'PRESENT' ? theme.palette.success.main :
    status === 'ABSENT' ? theme.palette.error.main :
    status === 'TRAINER_ABSENT' ? theme.palette.warning.main :
    status === 'OFF' ? theme.palette.info.main :
    status === 'COMP' ? theme.palette.secondary.main :
    theme.palette.text.secondary,
}));

const ReviewButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  '&:hover': {
    backgroundColor: theme.palette.error.dark,
  },
}));

const FeedbackChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const AttendanceCalendar = ({ studentId, trainerId, userRole, onRequestReview }) => {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [selectedDate, setSelectedDate] = useState(null);
  const [attendanceData, setAttendanceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [classContent, setClassContent] = useState('');
  const [reviewRemark, setReviewRemark] = useState('');
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'info'
  });

  

  const fetchAttendanceData = useCallback(async () => {
    if (!studentId && !trainerId) return;
    setLoading(true);
    try {
      const startDate = selectedMonth.startOf('month').format('YYYY-MM-DD');
      const endDate = selectedMonth.endOf('month').format('YYYY-MM-DD');
      const response = await listAttendances({
        student: studentId,
        trainer: trainerId,
        start_date: startDate,
        end_date: endDate,
      });
      
      const attendanceMap = {};
      response.data.forEach(attendance => {
        const attendanceDate = dayjs(attendance.timestamp).format('YYYY-MM-DD');
        if (!attendanceMap[attendanceDate] || dayjs(attendance.timestamp).isAfter(dayjs(attendanceMap[attendanceDate].timestamp))) {
          attendanceMap[attendanceDate] = attendance;
        }
      });
      setAttendanceData(attendanceMap);
      
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    } finally {
      setLoading(false);
    }
  }, [selectedMonth, studentId, trainerId]);

  useEffect(() => {
    fetchAttendanceData();
  }, [fetchAttendanceData]);

  const handlePrevMonth = () => {
    setSelectedMonth(selectedMonth.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedMonth(selectedMonth.add(1, 'month'));
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
    const dateString = date.format('YYYY-MM-DD');
    const attendance = attendanceData[dateString];
    if (attendance) {
      setSelectedAttendance(attendance);
      setClassContent(attendance.class_content || '');
      setDetailModalOpen(true);
    }
  };

  const handleUpdateAttendance = async () => {
    if (!selectedAttendance) return;
  
    try {
      if (userRole !== 'TRAINER') {
        throw new Error("Only trainers can update class content.");
      }
  
      const updatedAttendance = await addClassContent(selectedAttendance.id, { 
        class_content: classContent,
      });
      
      setDetailModalOpen(false);
      
      setAttendanceData(prevData => ({
        ...prevData,
        [dayjs(updatedAttendance.timestamp).format('YYYY-MM-DD')]: updatedAttendance
      }));
      
      fetchAttendanceData();
      setSnackbar({ open: true, message: 'Class content updated successfully', severity: 'success' });
    } catch (error) {
      console.error('Error updating class content:', error);
      setSnackbar({ open: true, message: error.message || 'Failed to update class content. Please try again.', severity: 'error' });
    }
  };

  const handleRequestReview = async () => {
    try {
      await createAttendanceReview({
        attendance: selectedAttendance.id,
        trainer: trainerId,
        remark: reviewRemark,
      });
      setReviewModalOpen(false);
      onRequestReview(selectedAttendance);
      setReviewRemark('');
      setSnackbar({ open: true, message: 'Attendance review requested successfully', severity: 'success' });
    } catch (error) {
      console.error('Error requesting review:', error);
      setSnackbar({ open: true, message: 'Failed to request review. Please try again.', severity: 'error' });
    }
  };

  const renderCalendar = () => {
    const startOfMonth = selectedMonth.startOf('month');
    const endOfMonth = selectedMonth.endOf('month');
    const startDate = startOfMonth.startOf('week');
    const endDate = endOfMonth.endOf('week');

    const calendarDays = [];
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    calendarDays.push(
      <Grid container key="weekdays">
        {weekDays.map(day => (
          <Grid item xs key={day}>
            <Typography align="center" variant="subtitle2">{day}</Typography>
          </Grid>
        ))}
      </Grid>
    );

    let week = [];
    for (let day = startDate; day.isBefore(endDate) || day.isSame(endDate); day = day.add(1, 'day')) {
      const dateString = day.format('YYYY-MM-DD');
      const attendance = attendanceData[dateString];
      const isSelected = selectedDate && selectedDate.isSame(day, 'day');
      const isToday = day.isSame(dayjs(), 'day');

      week.push(
        <Grid item xs key={dateString}>
          <DayCell
            isSelected={isSelected}
            isToday={isToday}
            onClick={() => handleDateClick(day)}
          >
            <Typography variant="body2">{day.date()}</Typography>
            {attendance && (
              <StatusText status={attendance.status}>
                {attendance.status}
              </StatusText>
            )}
          </DayCell>
        </Grid>
      );

      if (week.length === 7) {
        calendarDays.push(<Grid container key={day.format('YYYY-MM-DD')}>{week}</Grid>);
        week = [];
      }
    }

    if (week.length > 0) {
      calendarDays.push(<Grid container key={endDate.format('YYYY-MM-DD')}>{week}</Grid>);
    }

    return calendarDays;
  };

  return (
    <CalendarContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <IconButton onClick={handlePrevMonth}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Typography variant="h6">{selectedMonth.format('MMMM YYYY')}</Typography>
        <IconButton onClick={handleNextMonth}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
          <CircularProgress />
        </Box>
      ) : (
        renderCalendar()
      )}

<Dialog 
  open={detailModalOpen} 
  onClose={() => setDetailModalOpen(false)}
  fullWidth
  maxWidth="md" // Try "lg" for an even wider dialog
>
        <DialogTitle>Attendance Details</DialogTitle>
        <DialogContent>
          {selectedAttendance && (
            <Box position="relative">
              <Typography>Date: {dayjs(selectedAttendance.timestamp).format('MMMM D, YYYY')}</Typography>
              <Typography>Status: {selectedAttendance.status}</Typography>
              <Typography>Time: {dayjs(selectedAttendance.timestamp).format('HH:mm:ss')}</Typography>
              {userRole === 'TRAINER' && (
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Class Content: Update what you taught"
                  value={classContent}
                  onChange={(e) => setClassContent(e.target.value)}
                  margin="normal"
                />
              )}
              {selectedAttendance.student_feedback !== 'NO_ACTION' && (
                <Box mt={2}>
                  <Typography variant="subtitle2">Student Feedback:</Typography>
                  <FeedbackChip
                    label={selectedAttendance.student_feedback}
                    color={selectedAttendance.student_feedback === 'ACCEPTED' ? 'success' : 'error'}
                  />
                </Box>
              )}
              {userRole === 'TRAINER' && (
                <>
                <ReviewButton
                  variant="contained"
                  size="small"
                  onClick={() => setReviewModalOpen(true)}
                >
                  Send attendance for review
                </ReviewButton>
                <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                  <strong>Note:</strong> If you think the student has marked incorrect attendance, you can ask for a review.
                </Typography>
              </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailModalOpen(false)}>Close</Button>
          {userRole === 'TRAINER' && (
            <Button onClick={handleUpdateAttendance} color="primary">
              Update Class Content
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={reviewModalOpen} onClose={() => setReviewModalOpen(false)}>
        <DialogTitle>Request Attendance Review</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={5}
            label="Reason for reviews request"
            value={reviewRemark}
            onChange={(e) => setReviewRemark(e.target.value)}
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReviewModalOpen(false)}>Cancel</Button>
          <Button onClick={handleRequestReview} color="primary">
            Submit Review Request
          </Button>
        </DialogActions>
      </Dialog>
    </CalendarContainer>
  );
};

export default AttendanceCalendar;