import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  TextField, 
  Button, 
  Link, 
  Checkbox, 
  FormControlLabel,
  Divider,
  useMediaQuery,
  Snackbar,
  Alert, AlertTitle
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { GoogleIcon, FacebookIcon } from './CustomIcons';
import Content from './Content';
import { SitemarkIcon } from './CustomIcons';
import { login, googleAuth, getCurrentUser, register } from './api';

const FullWidthBox = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const ContentBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(12.5), // 100px in MUI spacing
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4),
  },
}));

const LogoWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4),
    alignSelf: 'center',
  },
  [theme.breakpoints.up('md')]: {
    position: 'absolute',
    top: '20px',
    left: '20px',
  },
}));

const GradientCard = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 400,
  padding: theme.spacing(4),
  borderRadius: '16px',
  background: '#1e1e1e',
  position: 'relative',
  overflow: 'hidden',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '16px',
    padding: '2px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    zIndex: 0,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    '& fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.23)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(255, 255, 255, 0.5)',
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputLabel-root, & .MuiOutlinedInput-input': {
    color: 'white',
  },
}));

const GradientButton = styled(Button)(({ theme }) => ({
  background: 'linear-gradient(45deg, #FF1B6B, #FF930F)',
  color: 'white',
  borderRadius: '12px',
  padding: '12px',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  '&:hover': {
    background: 'linear-gradient(45deg, #FF930F, #FF1B6B)',
  },
}));

const StyledDivider = styled(Divider)({
  margin: '16px 0',
  '&::before, &::after': {
    borderColor: 'rgba(255, 255, 255, 0.3)',
  },
});

const CenteredContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '2rem',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    maxWidth: '50%',
  },
  [theme.breakpoints.down('md')]: {
    order: 2,
  },
}));

const FooterContent = styled(Box)(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  color: 'white',
  padding: theme.spacing(2),
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}));

const FooterLink = styled(Link)({
  color: 'white',
  textDecoration: 'underline',
  '&:hover': {
    color: '#FF930F',
  },
});

const LoginPage = ({ setAuthState }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [city, setCity] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const [googleSDKLoaded, setGoogleSDKLoaded] = useState(false);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [googleSignInAttempted, setGoogleSignInAttempted] = useState(false);
  const [fedCMDisabled, setFedCMDisabled] = useState(false);


  useEffect(() => {
    const loadGoogleSDK = () => {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        setGoogleSDKLoaded(true);
        initializeGoogleSignIn();
      };
      document.body.appendChild(script);
    };

    loadGoogleSDK();

    return () => {
      // Cleanup if needed
    };
  }, []);

  const initializeGoogleSignIn = () => {
    if (window.google) {
      window.google.accounts.id.initialize({
        client_id: "751838028421-db65oi41q570sqhppfmj25l1jc311400.apps.googleusercontent.com",
        callback: handleGoogleResponse,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("googleSignInButton"),
        { 
          type: 'standard',
          theme: 'outline',
          size: 'large',
          text: 'signin_with',
          shape: 'rectangular',
          logo_alignment: 'left',
          width: '100%',
        }
      );
    }
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    try {
      if (isLogin) {
        const loginResponse = await login(username, password);
        const userResponse = await getCurrentUser();
        const userRole = userResponse.data.role;
        setAuthState({ isAuthenticated: true, userRole: userRole, loading: false });
        redirectBasedOnRole(userRole);
      } else {
        if (password !== password2) {
          setError("Passwords don't match");
          setLoading(false);
          return;
        }
        await register({
          username,
          password,
          password2,
          email,
          first_name: firstName,
          last_name: lastName,
          phone,
          city,
        });
        await login(username, password);
        const userResponse = await getCurrentUser();
        const userRole = userResponse.data.role;
        setAuthState({ isAuthenticated: true, userRole: userRole, loading: false });
        redirectBasedOnRole(userRole);
      }
    } catch (error) {
      console.error(isLogin ? 'Login process error:' : 'Registration process error:', error);
      setError(error.response?.data?.non_field_errors?.[0] || 'An unexpected error occurred. Please try again.');
      setLoading(false);
    }
  };

  const handleGoogleResponse = async (response) => {
    try {
      setLoading(true);
      setGoogleSignInAttempted(true);
      console.log('Google response:', response);
      
      if (!response.credential) {
        throw new Error('No credential received from Google');
      }
      
      const backendResponse = await googleAuth(response.credential);
      const { key } = backendResponse.data;
      localStorage.setItem('token', key);
      
      const userResponse = await getCurrentUser();
      const userRole = userResponse.data.role;
      setAuthState({ isAuthenticated: true, userRole: userRole, loading: false });
      redirectBasedOnRole(userRole);
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      setError('Failed to complete Google Sign-In. Please try again.');
      setLoading(false);
    } finally {
      setGoogleSignInAttempted(false);
    }
  };

  const handleGoogleLogin = () => {
    if (window.google && !googleSignInAttempted) {
      try {
        window.google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            // The FedCM prompt is not displayed or skipped
            throw new Error('FedCM prompt not displayed');
          }
        });
      } catch (error) {
        console.error('Google Sign-In Error:', error);
        setFedCMDisabled(true);
      }
    }
  };

  

  

  const redirectBasedOnRole = (userRole) => {
    switch (userRole) {
      case 'ADMIN':
      case 'MANAGER':
      case 'TRAINER':
      case 'STUDENT':
        navigate('/');
        break;
      default:
        setError('Invalid user role');
        setLoading(false);
    }
  };

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setError('');
  };

  const renderContent = () => (
    <ContentWrapper>
      <Content />
    </ContentWrapper>
  );

  const renderSignInCard = () => (
    <GradientCard>
      <Typography component="h1" variant="h4" color="white" gutterBottom>
        {isLogin ? 'Sign in' : 'Register'}
      </Typography>
      <form onSubmit={handleSubmit}>
        <StyledTextField
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="username"
          autoFocus
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        {!isLogin && (
          <>
            <StyledTextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <StyledTextField
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              autoComplete="given-name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <StyledTextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <StyledTextField
              required
              fullWidth
              id="phone"
              label="Phone"
              name="phone"
              autoComplete="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <StyledTextField
              required
              fullWidth
              id="city"
              label="City"
              name="city"
              autoComplete="address-level2"
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </>
        )}
        <StyledTextField
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {!isLogin && (
          <StyledTextField
            required
            fullWidth
            name="password2"
            label="Confirm Password"
            type="password"
            id="password2"
            autoComplete="new-password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
          />
        )}
        {isLogin && (
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            sx={{ color: 'white', marginBottom: 2 }}
          />
        )}
        <GradientButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={loading}
        >
          {loading ? (isLogin ? 'Signing In...' : 'Registering...') : (isLogin ? 'Sign In' : 'Register')}
        </GradientButton>
      </form>
      <Link 
        component="button"
        variant="body2"
        onClick={toggleMode}
        sx={{ color: 'primary.main', textDecoration: 'none', cursor: 'pointer' }}
      >
        {isLogin ? "Don't have an account? Sign Up" : "Already have an account? Sign In"}
      </Link>
      {isLogin && (
        <>
          <StyledDivider>or</StyledDivider>
          <Box
            id="googleSignInButton"
            sx={{
              width: '100%',
              height: '40px',
              marginBottom: 2,
              '& > div': {
                width: '100% !important',
              },
            }}
          />
          <Button
            fullWidth
            variant="outlined"
            startIcon={<FacebookIcon />}
            sx={{ color: 'white', borderColor: 'white' }}
          >
            Sign in with Facebook
          </Button>
        </>
      )}
    </GradientCard>
  );

  const renderFooter = () => (
    <FooterContent>
      <Typography variant="body2" gutterBottom>
        NIL Connect is powered by National Institute Of Language
      </Typography>
      <Typography variant="body2" gutterBottom>
        Copyright 2024 NIL Edutech (P) Limited. All rights reserved.
      </Typography>
      <Typography variant="body2">
        <FooterLink href="#terms">Terms and Conditions</FooterLink> | <FooterLink href="#privacy">Privacy Policy</FooterLink>
      </Typography>
    </FooterContent>
  );

  return (
    <FullWidthBox>
      <ContentBox>
        <LogoWrapper>
          <SitemarkIcon />
        </LogoWrapper>
        <CenteredContent>
          {isSmallScreen ? (
            <>
              {renderSignInCard()}
              {renderContent()}
            </>
          ) : (
            <>
              {renderContent()}
              {renderSignInCard()}
            
          
            </>
          )}
        </CenteredContent>
      </ContentBox>
      {renderFooter()}
      <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
        <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </FullWidthBox>
  );
};

export default LoginPage;