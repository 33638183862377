import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  Button,
  TextField,
  Pagination,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,Chip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { 
  getDashboardStats, 
  listTrainers,
  listUsers,
  listAttendanceReviews,
  updateAttendance,
  updateAttendanceReview,
  listStudentFeedbacks,
  respondToStudentFeedback,
  getTrainerOccupation,
  listCourseHolds,
  approveCourseHold,
  rejectCourseHold, listNotices
} from '../api';
import MessagingComponent from '../components/MessagingComponent';
import { createNotice } from '../api';
import { formatDate } from '../utils/dateUtils';
import NoticeCard from './NoticeCard';

const GlowingCard = styled(Paper)(({ theme }) => ({
  background: '#1e1e1e',
  borderRadius: '10px',
  padding: '2px',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '2px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));

const CardInner = styled(Box)({
  background: '#1e1e1e',
  borderRadius: '8px',
  padding: '20px',
  position: 'relative',
  zIndex: 1,
  height: '100%',
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));


const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  background: '#1e1e1e',
  borderRadius: '10px',
  overflow: 'hidden',
  position: 'relative',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '1px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    zIndex: 0,
  },
}));

const ManagersDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    total_trainers: 0,
    total_attendance_reviews: 0,
    total_student_feedbacks: 0,
    total_course_holds: 0,
  });
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const trainersPerPage = 10;
  const navigate = useNavigate();
  const [trainerOccupiedHours, setTrainerOccupiedHours] = useState({});
  const [courseHolds, setCourseHolds] = useState([]);
  const [courseHoldModalOpen, setCourseHoldModalOpen] = useState(false);
  const [attendanceReviews, setAttendanceReviews] = useState([]);
  const [attendancesToReview, setAttendancesToReview] = useState([]);
  const [notices, setNotices] = useState([]);
  
  const [studentFeedbacks, setStudentFeedbacks] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [feedbackResponse, setFeedbackResponse] = useState('');
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [newNotice, setNewNotice] = useState({
    title: '',
    content: '',
    audience: 'ALL',
    attachments: [],
    links: []
  });

  const handleCreateNotice = async () => {
    try {
      const formData = new FormData();
      formData.append('title', newNotice.title);
      formData.append('content', newNotice.content);
      formData.append('audience', newNotice.audience);
      
      newNotice.attachments.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file);
      });
      
      newNotice.links.forEach((link, index) => {
        formData.append(`links[${index}][url]`, link.url);
        formData.append(`links[${index}][title]`, link.title);
      });
  
      await createNotice(formData);
      setSnackbar({ open: true, message: 'Notice created successfully', severity: 'success' });
      setNoticeModalOpen(false);
      setNewNotice({ title: '', content: '', audience: 'ALL', attachments: [], links: [] });
      fetchData(); // Refresh data to include the new notice
    } catch (error) {
      console.error('Error creating notice:', error);
      setSnackbar({ open: true, message: 'Failed to create notice', severity: 'error' });
    }
  };

const handleFileSelect = (event) => {
  const files = Array.from(event.target.files);
  setNewNotice(prev => ({
    ...prev,
    attachments: [...prev.attachments, ...files]
  }));
};

const handleRemoveFile = (index) => {
  setNewNotice(prev => ({
    ...prev,
    attachments: prev.attachments.filter((_, i) => i !== index)
  }));
};

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const today = new Date().toISOString().split('T')[0];
  
      const [
        dashboardStatsResponse,
        trainersResponse,
        usersResponse,
        attendanceReviewsResponse,
        studentFeedbacksResponse,
        courseHoldsResponse,
        noticesResponse  // Add this line
      ] = await Promise.all([
        getDashboardStats(today),
        listTrainers(),
        listUsers(),
        listAttendanceReviews(),
        listStudentFeedbacks(),
        listCourseHolds(),
        listNotices()
      ]);
  
      setDashboardData({
        total_trainers: trainersResponse.data.length,
        total_attendance_reviews: attendanceReviewsResponse.data.length,
        total_student_feedbacks: studentFeedbacksResponse.data.length,
        total_course_holds: courseHoldsResponse.data.length,
      });
      setTrainers(trainersResponse.data || []);
      setTotalPages(Math.ceil((trainersResponse.data || []).length / trainersPerPage));
      setAttendanceReviews(attendanceReviewsResponse.data || []);
      setStudentFeedbacks(studentFeedbacksResponse.data || []);
      setCourseHolds(courseHoldsResponse.data || []);
      setNotices(noticesResponse.data || []);
  
      // Fetch occupied hours for each trainer
      const occupiedHours = {};
      for (const trainer of trainersResponse.data || []) {
        const occupationResponse = await getTrainerOccupation(trainer.user.id, today);
        occupiedHours[trainer.user.id] = occupationResponse.data.occupied_hours || 0;
      }
      setTrainerOccupiedHours(occupiedHours);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to fetch dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTrainerClick = (trainerId) => {
    navigate(`/trainers/${trainerId}`);
  };

  const handleCourseHoldAction = async (holdId, action) => {
    try {
      if (action === 'approve') {
        await approveCourseHold(holdId);
      } else {
        await rejectCourseHold(holdId);
      }
      setSnackbar({ open: true, message: `Course hold ${action === 'approve' ? 'approved' : 'rejected'} successfully`, severity: 'success' });
      fetchData(); // Refresh data after action
    } catch (error) {
      console.error(`Error ${action}ing course hold:`, error);
      setSnackbar({ open: true, message: `Failed to ${action} course hold`, severity: 'error' });
    }
  };
  const handleUpdateAttendance = async (attendanceId, newStatus) => {
    try {
      await updateAttendance(attendanceId, { status: newStatus });
      setSnackbar({ open: true, message: 'Attendance updated successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error updating attendance:', error);
      setSnackbar({ open: true, message: 'Failed to update attendance', severity: 'error' });
    }
  };

  const filteredTrainers = trainers.filter(trainer =>
    trainer.user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    trainer.user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedTrainers = filteredTrainers.slice(
    (page - 1) * trainersPerPage,
    page * trainersPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpenReviewModal = () => {
    setOpenReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
    setSelectedReview(null);
  };

  const handleOpenFeedbackModal = () => {
    setOpenFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setOpenFeedbackModal(false);
    setSelectedFeedback(null);
    setFeedbackResponse('');
    setFeedbackStatus('');
  };

  const handleUpdateAttendanceReview = async (reviewId, newStatus) => {
    try {
      await updateAttendanceReview(reviewId, { status: newStatus });
      setSnackbar({ open: true, message: 'Attendance review updated successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error updating attendance review:', error);
      setSnackbar({ open: true, message: 'Failed to update attendance review', severity: 'error' });
    }
  };

  const handleApproveAttendance = async (attendanceId) => {
    try {
      await updateAttendance(attendanceId, { status: 'PRESENT' });
      setSnackbar({ open: true, message: 'Attendance approved successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error approving attendance:', error);
      setSnackbar({ open: true, message: 'Failed to approve attendance', severity: 'error' });
    }
  };

  const handleRejectAttendance = async (attendanceId) => {
    try {
      await updateAttendance(attendanceId, { reviewed: true });
      setSnackbar({ open: true, message: 'Attendance rejection confirmed', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error confirming attendance rejection:', error);
      setSnackbar({ open: true, message: 'Failed to confirm attendance rejection', severity: 'error' });
    }
  };

  const handleRespondToFeedback = async () => {
    try {
      await respondToStudentFeedback(selectedFeedback.id, { 
        remarks: feedbackResponse,
        status: feedbackStatus
      });
      setSnackbar({ open: true, message: 'Response to student feedback sent successfully', severity: 'success' });
      handleCloseFeedbackModal();
      fetchData();
    } catch (error) {
      console.error('Error responding to student feedback:', error);
      setSnackbar({ open: true, message: 'Failed to send response to student feedback', severity: 'error' });
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Box my={4}>
        <Typography variant="h4" gutterBottom color="white">
          Management Dashboard
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">
                  Total Trainers
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_trainers}
                </Typography>
              </CardInner>
            </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">
                  Attendance Reviews
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_attendance_reviews}
                </Typography>
                <Button color="primary" onClick={handleOpenReviewModal}>
                  View Details
                </Button>
              </CardInner>
            </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">
                  Student Feedbacks
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_student_feedbacks}
                </Typography>
                <Button color="primary" onClick={handleOpenFeedbackModal}>
                  View Details
                </Button>
              </CardInner>
            </GlowingCard>
          </Grid>
           {/* Course Hold Requests, Create Notice, and Notice Cards side by side */}
           <Grid item xs={12} sm={6} md={4}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">
                  Course Hold Requests
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_course_holds}
                </Typography>
                <Button color="primary" onClick={() => setCourseHoldModalOpen(true)}>
                  View Details
                </Button>
              </CardInner>
            </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">
                  Create Notice
                </Typography>
                <Typography variant="h3" color="white">
                  +
                </Typography>
                <Button color="primary" onClick={() => setNoticeModalOpen(true)}>
                  Post New Notice
                </Button>
              </CardInner>
            </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlowingCard>
              <CardInner>
                <NoticeCard notices={notices} />
              </CardInner>
            </GlowingCard>
          </Grid>
        </Grid>
          

        <StyledTextField
          fullWidth
          label="Search Trainers"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

<StyledTableContainer component={Paper}>
      <Typography variant="h6" gutterBottom color="white" p={2}>
        Trainers
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>S.No</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Contract Type</TableCell>
            <TableCell>Approved Hours</TableCell>
            <TableCell>Salary</TableCell>
            <TableCell>Occupied Hours</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedTrainers.map((trainer, index) => (
            <TableRow key={trainer.id}>
              <TableCell>{(page - 1) * trainersPerPage + index + 1}</TableCell>
              <TableCell>
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => handleTrainerClick(trainer.id)}
                  sx={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: 'inherit',
                    '&:hover': {
                      textDecoration: 'underline',
                    },
                  }}
                >
                  {trainer.user.full_name || trainer.user.username || 'N/A'}
                </Link>
              </TableCell>
              <TableCell>{trainer.user.phone || 'N/A'}</TableCell>
              <TableCell>{trainer.contract_type || 'N/A'}</TableCell>
              <TableCell>{trainer.approved_hours || 'N/A'}</TableCell>
              <TableCell>Private</TableCell>
              <TableCell>{trainerOccupiedHours[trainer.user.id] || 'N/A'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
        <Box mt={2} display="flex" justifyContent="center">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>

        <Box mt={4}>
          <Typography variant="h6" gutterBottom color="white">
            Messaging
          </Typography>
          <MessagingComponent />
        </Box>
        <Dialog open={courseHoldModalOpen} onClose={() => setCourseHoldModalOpen(false)} fullWidth maxWidth="md">
  <DialogTitle>Course Hold Requests</DialogTitle>
  <DialogContent>
    <List>
      {courseHolds.map((hold) => (
        <ListItem key={hold.id}>
          <ListItemText
            primary={`${hold.student_course?.student?.full_name || 'Unknown Student'} - ${hold.student_course?.course?.name || 'Unknown Course'}`}
            secondary={`From: ${hold.start_date} To: ${hold.end_date} - Reason: ${hold.reason}`}
          />
          <Box>
            <Button onClick={() => handleCourseHoldAction(hold.id, 'approve')} color="primary">
              Approve
            </Button>
            <Button onClick={() => handleCourseHoldAction(hold.id, 'reject')} color="secondary">
              Reject
            </Button>
          </Box>
        </ListItem>
      ))}
    </List>
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setCourseHoldModalOpen(false)} color="primary">
      Close
    </Button>
  </DialogActions>
</Dialog>
        {/* Attendance Review Modal */}
        <Dialog open={openReviewModal} onClose={handleCloseReviewModal} fullWidth maxWidth="md">
          <DialogTitle>Attendance Reviews</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Attendance ID</TableCell>
                  <TableCell>Trainer</TableCell>
                  <TableCell>Remark</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {attendanceReviews.map((review) => (
                  <TableRow key={review.id}>
                    <TableCell>{review.attendance}</TableCell>
                    <TableCell>{review.trainer}</TableCell>
                    <TableCell>{review.remark}</TableCell>
                    <TableCell>{review.status}</TableCell>
                    <TableCell>
                      {review.status === 'PENDING' && (
                        <>
                          <Button onClick={() => handleUpdateAttendanceReview(review.id, 'APPROVED')} color="primary">
                            Approve
                          </Button>
                          <Button onClick={() => handleUpdateAttendanceReview(review.id, 'REJECTED')} color="secondary">
                            Reject
                          </Button>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseReviewModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Student Feedback Modal */}
        <Dialog open={openFeedbackModal} onClose={handleCloseFeedbackModal} fullWidth maxWidth="md">
          <DialogTitle>Student Feedbacks</DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Student</TableCell>
                  <TableCell>Course</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Topic</TableCell>
                  <TableCell>Feedback</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {studentFeedbacks.map((feedback) => (
                  <TableRow key={feedback.id}>
                    <TableCell>{feedback.student.full_name}</TableCell>
                    <TableCell>{feedback.course.name}</TableCell>
                    <TableCell>{feedback.feedback_type}</TableCell>
                    <TableCell>{feedback.topic}</TableCell>
                    <TableCell>{feedback.content.substring(0, 50)}...</TableCell>
                    <TableCell>{feedback.status}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setSelectedFeedback(feedback);
                          setFeedbackResponse(feedback.admin_remarks || '');
                          setFeedbackStatus(feedback.status);
                        }}
                        color="primary"
                      >
                        Respond
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFeedbackModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={noticeModalOpen} onClose={() => setNoticeModalOpen(false)} fullWidth maxWidth="md">
  <DialogTitle>Create New Notice</DialogTitle>
  <DialogContent>
    <TextField
      fullWidth
      label="Title"
      value={newNotice.title}
      onChange={(e) => setNewNotice({ ...newNotice, title: e.target.value })}
      margin="normal"
    />
    <TextField
      fullWidth
      label="Content"
      value={newNotice.content}
      onChange={(e) => setNewNotice({ ...newNotice, content: e.target.value })}
      margin="normal"
      multiline
      rows={4}
    />
    <FormControl fullWidth margin="normal">
      <InputLabel>Audience</InputLabel>
      <Select
        value={newNotice.audience}
        onChange={(e) => setNewNotice({ ...newNotice, audience: e.target.value })}
      >
        <MenuItem value="ALL">All</MenuItem>
        <MenuItem value="STUDENTS">Students</MenuItem>
        <MenuItem value="STUDENTS_TRAINERS">Students and Trainers</MenuItem>
        <MenuItem value="ADMINS_MANAGERS">Admins and Managers</MenuItem>
      </Select>
    </FormControl>
    
    {/* File upload section */}
    <input
      accept="image/*,application/pdf"
      style={{ display: 'none' }}
      id="raised-button-file"
      multiple
      type="file"
      onChange={handleFileSelect}
    />
    <label htmlFor="raised-button-file">
      <Button variant="contained" component="span" color="primary">
        Upload Attachments
      </Button>
    </label>
    
    {/* File preview section */}
    {newNotice.attachments.length > 0 && (
      <Box mt={2}>
        <Typography variant="subtitle1">Attachments:</Typography>
        {newNotice.attachments.map((file, index) => (
          <Chip
            key={index}
            label={file.name}
            onDelete={() => handleRemoveFile(index)}
            color="primary"
            variant="outlined"
            style={{ margin: '4px' }}
          />
        ))}
      </Box>
    )}
    
    {/* You can add link inputs here if needed */}
  </DialogContent>
  <DialogActions>
    <Button onClick={() => setNoticeModalOpen(false)}>Cancel</Button>
    <Button 
      onClick={handleCreateNotice} 
      color="primary"
      disabled={!newNotice.title || !newNotice.content}
    >
      Create Notice
    </Button>
  </DialogActions>
</Dialog>

        {/* Feedback Response Modal */}
        <Dialog open={!!selectedFeedback} onClose={() => setSelectedFeedback(null)} fullWidth maxWidth="sm">
          <DialogTitle>Respond to Feedback</DialogTitle>
          <DialogContent>
            {selectedFeedback && (
              <>
                <Typography variant="body1" gutterBottom>
                  Student: {selectedFeedback.student.full_name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Course: {selectedFeedback.course.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Type: {selectedFeedback.feedback_type}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Topic: {selectedFeedback.topic}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Feedback: {selectedFeedback.content}
                </Typography>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={feedbackStatus}
                    onChange={(e) => setFeedbackStatus(e.target.value)}
                  >
                    <MenuItem value="PENDING">Pending</MenuItem>
                    <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
                    <MenuItem value="RESOLVED">Resolved</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Your Response"
                  value={feedbackResponse}
                  onChange={(e) => setFeedbackResponse(e.target.value)}
                  margin="normal"
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedFeedback(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleRespondToFeedback} color="primary" disabled={!feedbackResponse.trim()}>
              Send Response
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default ManagersDashboard;