import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Box,
  TextField,
  Button,
  Card,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  CircularProgress,
  Snackbar,
  Alert,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CommentIcon from '@mui/icons-material/Comment';
import PushPinIcon from '@mui/icons-material/PushPin';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import SendIcon from '@mui/icons-material/Send';
import {
  listTeamUpdates,
  createTeamUpdate,
  likeTeamUpdate,
  commentOnTeamUpdate,
  pinUnpinTeamUpdate,
  deleteTeamUpdate,
  listUsers,
  createUpdateAttachment
} from '../api';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderRadius: '12px',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
    backgroundColor: theme.palette.background.paper,
  },
}));

const FileInput = styled('input')({
  display: 'none',
});

const PreviewContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}));

const FilePreview = styled(Box)(({ theme }) => ({
  width: 100,
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  overflow: 'hidden',
}));

const TeamUpdatesPage = () => {
  const [updates, setUpdates] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [managers, setManagers] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [newUpdate, setNewUpdate] = useState('');
  const [files, setFiles] = useState([]);
  const [previewFiles, setPreviewFiles] = useState([]);
  const [links, setLinks] = useState([]);
  const [linkDialogOpen, setLinkDialogOpen] = useState(false);
  const [newLink, setNewLink] = useState({ url: '', title: '' });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [newComments, setNewComments] = useState({});

  const fetchUpdates = useCallback(async () => {
    try {
      const response = await listTeamUpdates();
      setUpdates(response.data);
    } catch (err) {
      console.error('Error fetching team updates:', err);
      setError('Failed to fetch team updates. Please try again.');
    }
  }, []);

  const fetchUsersByRole = useCallback(async (role, setterFunction) => {
    try {
      const response = await listUsers({ roles: [role], page_size: 100 }); // Increase page_size if you have more than 100 users per role
      setterFunction(response.results || []);
    } catch (err) {
      console.error(`Error fetching ${role}s:`, err);
      setterFunction([]);
    }
  }, []);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([
          fetchUpdates(),
          fetchUsersByRole('ADMIN', setAdmins),
          fetchUsersByRole('MANAGER', setManagers),
          fetchUsersByRole('TRAINER', setTrainers),
        ]);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('An error occurred while fetching data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchAllData();
  }, [fetchUpdates, fetchUsersByRole]);


  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);
    setFiles(selectedFiles);
    
    const encodedFiles = await Promise.all(selectedFiles.map(async (file) => {
      const base64 = await convertToBase64(file);
      return {
        file: base64,
        is_image: file.type.startsWith('image/'),
      };
    }));
    
    setPreviewFiles(encodedFiles);
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleAddLink = () => {
    if (newLink.url && newLink.title) {
      setLinks([...links, newLink]);
      setNewLink({ url: '', title: '' });
      setLinkDialogOpen(false);
    }
  };

  const handleCreateUpdate = async () => {
    try {
      const updateData = {
        content: newUpdate,
      };
  
      const response = await createTeamUpdate(updateData, files, links);
      console.log('Create update response:', response);
  
      setNewUpdate('');
      setFiles([]);
      setPreviewFiles([]);
      setLinks([]);
      fetchUpdates();
      setSnackbar({ open: true, message: 'Update posted successfully!', severity: 'success' });
    } catch (err) {
      console.error('Error creating update:', err.response?.data || err.message);
      setSnackbar({ open: true, message: 'Failed to post update. Please try again.', severity: 'error' });
    }
  };

  const handleLike = async (updateId) => {
    try {
      await likeTeamUpdate(updateId);
      fetchUpdates();
    } catch (err) {
      console.error('Error liking update:', err);
      setSnackbar({ open: true, message: 'Failed to like update. Please try again.', severity: 'error' });
    }
  };

  const handleComment = async (updateId) => {
    const comment = newComments[updateId];
    if (!comment) return;

    try {
      await commentOnTeamUpdate(updateId, { content: comment });
      setNewComments(prev => ({ ...prev, [updateId]: '' }));
      fetchUpdates();
      setSnackbar({ open: true, message: 'Comment posted successfully!', severity: 'success' });
    } catch (err) {
      console.error('Error posting comment:', err);
      setSnackbar({ open: true, message: 'Failed to post comment. Please try again.', severity: 'error' });
    }
  };

  const handlePinUnpin = async (updateId) => {
    try {
      await pinUnpinTeamUpdate(updateId);
      fetchUpdates();
    } catch (err) {
      console.error('Error pinning/unpinning update:', err);
      setSnackbar({ open: true, message: 'Failed to pin/unpin update. Please try again.', severity: 'error' });
    }
  };

  const handleDelete = async (updateId) => {
    try {
      await deleteTeamUpdate(updateId);
      fetchUpdates();
      setSnackbar({ open: true, message: 'Update deleted successfully.', severity: 'success' });
    } catch (err) {
      console.error('Error deleting update:', err);
      setSnackbar({ open: true, message: 'Failed to delete update. Please try again.', severity: 'error' });
    }
  };

  const renderAttachment = (attachment) => {
    if (attachment.is_image) {
      return (
        <img 
          src={attachment.file}
          alt={`Attachment ${attachment.id}`} 
          style={{ maxWidth: '100%', maxHeight: 200, objectFit: 'cover' }} 
        />
      );
    } else {
      return (
        <Button
          startIcon={<AttachFileIcon />}
          href={attachment.file}
          target="_blank"
          rel="noopener noreferrer"
        >
          Download Attachment
        </Button>
      );
    }
  };

  const renderUserList = (users, role) => {
    if (!users || users.length === 0) {
      return null;
    }

    return (
      <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          {role}s
        </Typography>
        <List>
          {users.map((user) => (
            <ListItem key={user.id}>
              <ListItemAvatar>
                <Avatar src={user.profile_image} alt={user.username}>
                  {user.full_name ? user.full_name.charAt(0) : ''}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={user.full_name || user.username}
                secondary={user.email}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
    );
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Typography variant="h4" gutterBottom>
            Team Updates
          </Typography>
          <Box mb={4}>
            <StyledTextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              placeholder="What's on your mind?"
              value={newUpdate}
              onChange={(e) => setNewUpdate(e.target.value)}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <label htmlFor="file-input">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<AttachFileIcon />}
                  >
                    Attach Files
                  </Button>
                </label>
                <FileInput
                  id="file-input"
                  type="file"
                  multiple
                  accept="*/*"
                  onChange={handleFileChange}
                />
                
              </Box>
              <Button
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                onClick={handleCreateUpdate}
                disabled={!newUpdate.trim() && previewFiles.length === 0 && links.length === 0}
              >
                Post Update
              </Button>
            </Box>
            {previewFiles.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle2">
                  Attached files:
                </Typography>
                {previewFiles.map((file, index) => (
                  <Typography key={index} variant="body2">
                    {files[index].name} ({file.is_image ? 'Image' : 'File'})
                  </Typography>
                ))}
              </Box>
            )}
            {links.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle2">
                  Attached links:
                </Typography>
                {links.map((link, index) => (
                  <Typography key={index} variant="body2">
                    {link.title}: {link.url}
                  </Typography>
                ))}
              </Box>
            )}
          </Box>
          {updates.map((update) => (
            <StyledCard key={update.id}>
              <CardContent>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar src={update.author.profile_image} alt={update.author.username}>
                    {update.author.full_name.charAt(0)}
                  </Avatar>
                  <Box ml={2}>
                    <Typography variant="subtitle1">{update.author.full_name}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {new Date(update.created_at).toLocaleString()}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" paragraph>
                  {update.content}
                </Typography>
                {update.attachments.map((attachment, index) => (
                  <Box key={index} mb={2}>
                    {renderAttachment(attachment)}
                  </Box>
                ))}
                {update.links.map((link, index) => (
                  <Box key={index} mb={1}>
                    <Button href={link.url} target="_blank" rel="noopener noreferrer">
                      {link.title}
                    </Button>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <IconButton onClick={() => handleLike(update.id)}>
                  <ThumbUpAltIcon color={update.likes > 0 ? 'primary' : 'inherit'} />
                </IconButton>
                <Typography variant="body2">{update.likes} Likes</Typography>
                <IconButton>
                  <CommentIcon />
                </IconButton>
                <Typography variant="body2">{update.comments.length} Comments</Typography>
                <IconButton onClick={() => handlePinUnpin(update.id)}>
                  <PushPinIcon color={update.is_pinned ? 'secondary' : 'inherit'} />
                </IconButton>
                <IconButton onClick={() => handleDelete(update.id)}>
                  <DeleteIcon />
                </IconButton>
              </CardActions>
              <Divider />
              <List>
                {update.comments.map((comment) => (
                  <ListItem key={comment.id} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar src={comment.author.profile_image} alt={comment.author.username}>
                        {comment.author.full_name.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={comment.author.full_name}
                      secondary={<>
                        <Typography component="span" variant="body2" color="textPrimary">
                          {comment.content}
                        </Typography>
                        <br />
                        <Typography component="span" variant="caption" color="textSecondary">
                          {new Date(comment.created_at).toLocaleString()}
                        </Typography>
                      </>}
                    />
                  </ListItem>
                ))}
              </List>
              <Box p={2}>
              <StyledTextField
                fullWidth
                variant="outlined"
                placeholder="Write a comment..."
                value={newComments[update.id] || ''}
                onChange={(e) => setNewComments(prev => ({ ...prev, [update.id]: e.target.value }))}
              />
              <Button
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                onClick={() => handleComment(update.id)}
                disabled={!newComments[update.id]}
                sx={{ mt: 1 }}
              >
                Post Comment
              </Button>
            </Box>
          </StyledCard>
        ))}
      </Grid>
      <Grid item xs={12} md={4}>
          <Typography variant="h5" gutterBottom>
            Team Members
          </Typography>
          {renderUserList(admins, 'Admin')}
          {renderUserList(managers, 'Manager')}
          {renderUserList(trainers, 'Trainer')}
        </Grid>
      </Grid>
    <Dialog open={linkDialogOpen} onClose={() => setLinkDialogOpen(false)}>
      <DialogTitle>Add Link</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="URL"
          type="url"
          fullWidth
          value={newLink.url}
          onChange={(e) => setNewLink({ ...newLink, url: e.target.value })}
        />
        <TextField
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          value={newLink.title}
          onChange={(e) => setNewLink({ ...newLink, title: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setLinkDialogOpen(false)}>Cancel</Button>
        <Button onClick={handleAddLink}>Add</Button>
      </DialogActions>
    </Dialog>
    <Snackbar
      open={snackbar.open}
      autoHideDuration={6000}
      onClose={() => setSnackbar({ ...snackbar, open: false })}
    >
      <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
        {snackbar.message}
      </Alert>
    </Snackbar>
  </Container>
);
};

export default TeamUpdatesPage;