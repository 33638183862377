import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box, Tab, Tabs,
  CircularProgress,
  Alert,
  Snackbar,
  Button,
  TextField,
  Pagination,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Chip
} from '@mui/material';
import TabPanel from '../components/TabPanel';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { 
  getDashboardStats, 
  listTrainers,
  listUsers,
  listAttendanceReviews,
  updateAttendance,
  updateAttendanceReview,
  listStudentFeedbacks,
  respondToStudentFeedback,
  getTrainerOccupation,
  listCourseHolds,
  approveCourseHold,
  rejectCourseHold,
  listNotices,
  listLeaveRequests,
  approveLeaveRequest,
  rejectLeaveRequest,
  getLeaveRequestHistory,
  createNotice, processAttendanceReview, getUser,
  getCourse, getStudentCourse,deleteNotice, getCurrentUser
  
} from '../api';
import MessagingComponent from '../components/MessagingComponent';
import { formatDate } from '../utils/dateUtils';
import NoticeCard from './NoticeCard';
import {
  getAttendanceReviewHistory,
  getStudentFeedbackHistory,
  getCourseHoldHistory
} from '../api';

const RequestBlock = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  background: 'rgba(255, 255, 255, 0.05)',
  backdropFilter: 'blur(5px)',
  borderRadius: theme.shape.borderRadius,
  border: '1px solid rgba(255, 255, 255, 0.1)',
}));

const HeaderTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  color: theme.palette.primary.main,
}));

const GlowingCard = styled(Paper)(({ theme }) => ({
  background: '#1e1e1e',
  borderRadius: '10px',
  padding: '2px',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '2px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));

const CardInner = styled(Box)({
  background: '#1e1e1e',
  borderRadius: '8px',
  padding: '20px',
  position: 'relative',
  zIndex: 1,
  height: '100%',
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
}));


const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginTop: theme.spacing(4),
  background: '#1e1e1e',
  borderRadius: '10px',
  overflow: 'hidden',
  position: 'relative',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '1px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
    zIndex: 0,
  },
}));

const AdminDashboard = () => {
  const [dashboardData, setDashboardData] = useState({
    total_trainers: 0,
    total_attendance_reviews: 0,
    total_student_feedbacks: 0,
    total_course_holds: 0,
  });
  const [trainers, setTrainers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const trainersPerPage = 10;
  const navigate = useNavigate();
  const [trainerOccupiedHours, setTrainerOccupiedHours] = useState({});
  const [courseHolds, setCourseHolds] = useState([]);
  const [courseHoldModalOpen, setCourseHoldModalOpen] = useState(false);
  const [attendanceReviews, setAttendanceReviews] = useState([]);
  const [notices, setNotices] = useState([]);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [leaveRequestModalOpen, setLeaveRequestModalOpen] = useState(false);
  const [leaveRemarks, setLeaveRemarks] = useState('');
  const [selectedLeaveRequest, setSelectedLeaveRequest] = useState(null);
  const [studentFeedbacks, setStudentFeedbacks] = useState([]);
  const [openReviewModal, setOpenReviewModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [feedbackResponse, setFeedbackResponse] = useState('');
  const [feedbackStatus, setFeedbackStatus] = useState('');
  const [noticeModalOpen, setNoticeModalOpen] = useState(false);
  const [trainerNames, setTrainerNames] = useState({});
  const [leaveRequestHistory, setLeaveRequestHistory] = useState([]);
  const [reviewHistoryModalOpen, setReviewHistoryModalOpen] = useState(false);
  const [feedbackHistoryModalOpen, setFeedbackHistoryModalOpen] = useState(false);
  const [courseHoldHistoryModalOpen, setCourseHoldHistoryModalOpen] = useState(false);
  const [reviewHistory, setReviewHistory] = useState([]);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [courseHoldHistory, setCourseHoldHistory] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [newNotice, setNewNotice] = useState({
    title: '',
    content: '',
    audience: 'ALL',
    attachments: [],
    links: []
  });

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const response = await getCurrentUser();
        console.log("User data received:", response);
        
        // Access the nested data object
        const userData = response.data;
        
        if (userData && userData.role) {
          setUserRole(userData.role);
          console.log("User role set to:", userData.role);
        } else {
          console.error("User role not found in the response");
          setUserRole(''); // Set to empty string or some default value
        }
      } catch (error) {
        console.error('Error fetching user role:', error);
        setUserRole(''); // Set to empty string or some default value
      }
    };
  
    fetchUserRole();
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const today = new Date().toISOString().split('T')[0];
      const leaveRequestsResponse = await listLeaveRequests();
      const leaveRequestHistoryResponse = await getLeaveRequestHistory();
      const [
        dashboardStatsResponse,
        trainersResponse,
        usersResponse,
        attendanceReviewsResponse,
        studentFeedbacksResponse,
        courseHoldsResponse,
        
        noticesResponse,
      ] = await Promise.all([
        getDashboardStats(today),
        listTrainers(),
        listUsers(),
        listAttendanceReviews(),
        listStudentFeedbacks(),
        listCourseHolds(),
        listNotices(),
        listLeaveRequests()
      ]);

      setDashboardData({
        total_trainers: trainersResponse.data.length,
        total_attendance_reviews: attendanceReviewsResponse.data.length,
        total_student_feedbacks: studentFeedbacksResponse.data.length,
        total_course_holds: courseHoldsResponse.data.length,
        total_leave_requests: leaveRequestsResponse.data.length,
      });
      setTrainers(Array.isArray(trainersResponse.data) ? trainersResponse.data : []);
      setTotalPages(Math.ceil((trainersResponse.data || []).length / trainersPerPage));
      setAttendanceReviews(attendanceReviewsResponse.data || []);
      setStudentFeedbacks(studentFeedbacksResponse.data || []);
      setCourseHolds(courseHoldsResponse.data || []);
      setNotices(noticesResponse.data || []);
      setLeaveRequests(leaveRequestsResponse.data || []);
      setLeaveRequestHistory(leaveRequestHistoryResponse.data || []);
  
      // Fetch occupied hours for each trainer
      const occupiedHours = {};
      for (const trainer of trainersResponse.data || []) {
        const occupationResponse = await getTrainerOccupation(trainer.user.id, today);
        occupiedHours[trainer.user.id] = occupationResponse.data.occupied_hours || 0;
      }

      const detailedFeedbacks = await Promise.all(studentFeedbacksResponse.data.map(async (feedback) => {
        const [studentResponse, courseResponse] = await Promise.all([
          getUser(feedback.student),
          getCourse(feedback.course)
        ]);
        return {
          ...feedback,
          studentName: studentResponse.data.full_name || studentResponse.data.username,
          courseName: courseResponse.data.name
        };
      }));

      

      setStudentFeedbacks(detailedFeedbacks);

      const detailedReviews = await Promise.all(attendanceReviewsResponse.data.map(async (review) => {
        const trainerResponse = await getUser(review.trainer);
        return {
          ...review,
          trainerName: trainerResponse.data.full_name || trainerResponse.data.username,
        };
      }));

      setAttendanceReviews(detailedReviews);
      
      const detailedCourseHolds = await Promise.all(courseHoldsResponse.data.map(async (hold) => {
        const [studentCourseResponse, requestedByUser] = await Promise.all([
          getStudentCourse(hold.student_course),
          getUser(hold.requested_by)
        ]);
        return {
          ...hold,
          studentName: studentCourseResponse.data.student.full_name || studentCourseResponse.data.student.username || 'Unknown Student',
          courseName: studentCourseResponse.data.course.name || 'Unknown Course',
          requestedByName: requestedByUser.data.full_name || requestedByUser.data.username || 'Unknown User'
        };
      }));
      setCourseHolds(detailedCourseHolds);

      const trainerNameMap = {};
      trainersResponse.data.forEach(trainer => {
        trainerNameMap[trainer.user.id] = trainer.user.full_name || trainer.user.username || 'Unknown';
      });
      setTrainerNames(trainerNameMap);
      
      setTrainerOccupiedHours(occupiedHours);
      setLeaveRequests(leaveRequestsResponse.data || []);
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError('Failed to fetch dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleTrainerClick = (trainerId) => {
    navigate(`/trainers/${trainerId}`);
  };
  const handleDeleteNotice = async (noticeId) => {
    try {
      await deleteNotice(noticeId);
      setNotices(notices.filter(notice => notice.id !== noticeId));
      // Optionally, show a success message
    } catch (error) {
      console.error('Error deleting notice:', error);
      // Handle error (show error message to user)
    }
  };
  const fetchReviewHistory = useCallback(async () => {
    try {
      const response = await getAttendanceReviewHistory();
      const detailedHistory = await Promise.all(response.data.map(async (review) => {
        const [student, trainer] = await Promise.all([
          getUser(review.student),
          getUser(review.trainer)
        ]);
        return {
          ...review,
          studentName: student.data.full_name || student.data.username,
          trainerName: trainer.data.full_name || trainer.data.username
        };
      }));
      setReviewHistory(detailedHistory);
    } catch (error) {
      console.error('Error fetching review history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch review history', severity: 'error' });
    }
  }, []);

  const fetchFeedbackHistory = useCallback(async () => {
    try {
      const response = await getStudentFeedbackHistory();
      const detailedHistory = await Promise.all(response.data.map(async (feedback) => {
        const [student, course, respondedBy] = await Promise.all([
          getUser(feedback.student),
          getCourse(feedback.course),
          getUser(feedback.responded_by)
        ]);
        return {
          ...feedback,
          studentName: student.data.full_name || student.data.username,
          courseName: course.data.name,
          responderName: respondedBy.data.full_name || respondedBy.data.username
        };
      }));
      setFeedbackHistory(detailedHistory);
    } catch (error) {
      console.error('Error fetching feedback history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch feedback history', severity: 'error' });
    }
  }, []);

  const fetchCourseHoldHistory = useCallback(async () => {
    try {
      const response = await getCourseHoldHistory();
      setCourseHoldHistory(response.data);
    } catch (error) {
      console.error('Error fetching course hold history:', error);
      setSnackbar({ open: true, message: 'Failed to fetch course hold history', severity: 'error' });
    }
  }, []);

  const handleLeaveRequestAction = async (action) => {
    if (!selectedLeaveRequest) return;
  
    try {
      if (action === 'approve') {
        await approveLeaveRequest(selectedLeaveRequest.id, leaveRemarks);
      } else {
        await rejectLeaveRequest(selectedLeaveRequest.id, leaveRemarks);
      }
      setSnackbar({ open: true, message: `Leave request ${action}ed successfully`, severity: 'success' });
      setSelectedLeaveRequest(null);
      setLeaveRemarks('');
      fetchData();
    } catch (error) {
      console.error(`Error ${action}ing leave request:`, error);
      setSnackbar({ open: true, message: `Failed to ${action} leave request`, severity: 'error' });
    }
  };

  const handleCourseHoldAction = async (holdId, action) => {
    try {
      if (action === 'approve') {
        await approveCourseHold(holdId);
      } else {
        await rejectCourseHold(holdId);
      }
      setSnackbar({ open: true, message: `Course hold ${action === 'approve' ? 'approved' : 'rejected'} successfully`, severity: 'success' });
      fetchData();
    } catch (error) {
      console.error(`Error ${action}ing course hold:`, error);
      setSnackbar({ open: true, message: `Failed to ${action} course hold`, severity: 'error' });
    }
  };

  const handleUpdateAttendance = async (attendanceId, studentId, reviewId, newStatus, reviewDecision) => {
    try {
      // Ensure studentId is a number
      const studentIdNumber = parseInt(studentId, 10);
  
      // Update attendance
      await updateAttendance(attendanceId, { 
        status: newStatus,
        student: studentIdNumber  // Send as a number
      });
  
      // Process review
      await processAttendanceReview(reviewId, { status: reviewDecision });
  
      setSnackbar({ open: true, message: 'Attendance and review updated successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error updating attendance and review:', error);
      setSnackbar({ open: true, message: 'Failed to update attendance and review', severity: 'error' });
    }
  };

  const handleProcessReview = async (reviewId, newStatus) => {
    try {
      await processAttendanceReview(reviewId, newStatus);
      setSnackbar({ open: true, message: 'Review processed successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error processing review:', error);
      setSnackbar({ open: true, message: 'Failed to process review', severity: 'error' });
    }
  };

  const filteredTrainers = trainers.filter(trainer =>
    trainer.user.full_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    trainer.user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedTrainers = filteredTrainers.slice(
    (page - 1) * trainersPerPage,
    page * trainersPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleUpdateAttendanceReview = async (reviewId, newStatus) => {
    try {
      await updateAttendanceReview(reviewId, { status: newStatus });
      setSnackbar({ open: true, message: 'Attendance review updated successfully', severity: 'success' });
      fetchData();
    } catch (error) {
      console.error('Error updating attendance review:', error);
      setSnackbar({ open: true, message: 'Failed to update attendance review', severity: 'error' });
    }
  };

  const handleRespondToFeedback = async () => {
    try {
      await respondToStudentFeedback(selectedFeedback.id, { 
        remarks: feedbackResponse,
        status: feedbackStatus
      });
      setSnackbar({ open: true, message: 'Response to student feedback sent successfully', severity: 'success' });
      handleCloseFeedbackModal();
      fetchData();
    } catch (error) {
      console.error('Error responding to student feedback:', error);
      setSnackbar({ open: true, message: 'Failed to send response to student feedback', severity: 'error' });
    }
  };

  const handleCreateNotice = async () => {
    try {
      const formData = new FormData();
      formData.append('title', newNotice.title);
      formData.append('content', newNotice.content);
      formData.append('audience', newNotice.audience);
      
      newNotice.attachments.forEach((file, index) => {
        formData.append(`attachments[${index}]`, file);
      });
      
      newNotice.links.forEach((link, index) => {
        formData.append(`links[${index}][url]`, link.url);
        formData.append(`links[${index}][title]`, link.title);
      });
  
      await createNotice(formData);
      setSnackbar({ open: true, message: 'Notice created successfully', severity: 'success' });
      setNoticeModalOpen(false);
      setNewNotice({ title: '', content: '', audience: 'ALL', attachments: [], links: [] });
      fetchData();
    } catch (error) {
      console.error('Error creating notice:', error);
      setSnackbar({ open: true, message: 'Failed to create notice', severity: 'error' });
    }
  };

  const handleFileSelect = (event) => {
    const files = Array.from(event.target.files);
    setNewNotice(prev => ({
      ...prev,
      attachments: [...prev.attachments, ...files]
    }));
  };

  const handleRemoveFile = (index) => {
    setNewNotice(prev => ({
      ...prev,
      attachments: prev.attachments.filter((_, i) => i !== index)
    }));
  };

  const handleOpenReviewModal = () => {
    setOpenReviewModal(true);
  };

  const handleCloseReviewModal = () => {
    setOpenReviewModal(false);
    setSelectedReview(null);
  };

  const handleOpenFeedbackModal = () => {
    setOpenFeedbackModal(true);
  };

  const handleCloseFeedbackModal = () => {
    setOpenFeedbackModal(false);
    setSelectedFeedback(null);
    setFeedbackResponse('');
    setFeedbackStatus('');
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Container maxWidth="sm">
        <Typography color="error" align="center">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Box my={4}>
        <Typography variant="h4" gutterBottom color="white">
          Admin Dashboard
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">
                  Total Trainers
                </Typography>
                <Typography variant="h3" color="white">
                  {dashboardData.total_trainers}
                </Typography>
                <Typography variant="subtitle1" color="white" mt={2}>
                  Leave Requests: {dashboardData.total_leave_requests}
                </Typography>
                <Button color="primary" onClick={() => setLeaveRequestModalOpen(true)}>
                  View Leave Requests
                </Button>
              </CardInner>
            </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={4}>
          <GlowingCard>
    <CardInner>
      <Typography variant="h6" gutterBottom color="white">
        Attendance Reviews
      </Typography>
      <Typography variant="h3" color="white">
        {dashboardData.total_attendance_reviews}
      </Typography>
      <Button color="primary" onClick={handleOpenReviewModal}>
        View Details
      </Button>
      <Button color="secondary" onClick={() => {
        setReviewHistoryModalOpen(true);
        fetchReviewHistory();
      }}>
        View History
      </Button>
    </CardInner>
  </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={4}>
          <GlowingCard>
    <CardInner>
      <Typography variant="h6" gutterBottom color="white">
        Student Feedbacks
      </Typography>
      <Typography variant="h3" color="white">
        {dashboardData.total_student_feedbacks}
      </Typography>
      <Button color="primary" onClick={handleOpenFeedbackModal}>
        View Details
      </Button>
      <Button color="secondary" onClick={() => {
        setFeedbackHistoryModalOpen(true);
        fetchFeedbackHistory();
      }}>
        View History
      </Button>
    </CardInner>
  </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
          <GlowingCard>
    <CardInner>
      <Typography variant="h6" gutterBottom color="white">
        Course Hold Requests
      </Typography>
      <Typography variant="h3" color="white">
        {dashboardData.total_course_holds}
      </Typography>
      <Button color="primary" onClick={() => setCourseHoldModalOpen(true)}>
        View Details
      </Button>
      <Button color="secondary" onClick={() => {
        setCourseHoldHistoryModalOpen(true);
        fetchCourseHoldHistory();
      }}>
        View History
      </Button>
    </CardInner>
  </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlowingCard>
              <CardInner>
                <Typography variant="h6" gutterBottom color="white">
                  Create Notice
                </Typography>
                <Typography variant="h3" color="white">
                  +
                </Typography>
                <Button color="primary" onClick={() => setNoticeModalOpen(true)}>
                  Post New Notice
                </Button>
              </CardInner>
            </GlowingCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <GlowingCard>
              <CardInner>
              <NoticeCard 
    notices={notices} 
    userRole={userRole} 
    onDelete={handleDeleteNotice} 
  />
              </CardInner>
            </GlowingCard>
          </Grid>
        </Grid>

        <StyledTextField
          fullWidth
          label="Search Trainers"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />

        <StyledTableContainer component={Paper}>
          <Typography variant="h6" gutterBottom color="white" p={2}>
            Trainers
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Contract Type</TableCell>
                <TableCell>Approved Hours</TableCell>
                <TableCell>Salary</TableCell>
                <TableCell>Occupied Hours</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedTrainers.map((trainer, index) => (
                <TableRow key={trainer.id}>
                  <TableCell>{(page - 1) * trainersPerPage + index + 1}</TableCell>
                  <TableCell>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => handleTrainerClick(trainer.id)}
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        color: 'inherit',
                        '&:hover': {
                          textDecoration: 'underline',
                        },
                      }}
                    >
                      {trainer.user.full_name || trainer.user.username || 'N/A'}
                    </Link>
                  </TableCell>
                  <TableCell>{trainer.user.phone || 'N/A'}</TableCell>
                  <TableCell>{trainer.contract_type || 'N/A'}</TableCell>
                  <TableCell>{trainer.approved_hours || 'N/A'}</TableCell>
                  <TableCell>{trainer.salary || 'N/A'}</TableCell>
                  <TableCell>{trainerOccupiedHours[trainer.user.id] || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
        <Box mt={2} display="flex" justifyContent="center">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            color="primary"
          />
        </Box>

        <Box mt={4}>
          <Typography variant="h6" gutterBottom color="white">
            Messaging
          </Typography>
          <MessagingComponent />
        </Box>

        <Dialog 
        open={courseHoldModalOpen} 
        onClose={() => setCourseHoldModalOpen(false)} 
        fullWidth 
        maxWidth="md"
        PaperProps={{
          style: {
            backgroundColor: 'rgba(18, 18, 18, 0.9)',
            backdropFilter: 'blur(10px)',
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h4" color="primary">Course Hold Requests</Typography>
        </DialogTitle>
        <DialogContent>
          <List>
            {courseHolds.map((hold) => (
              <RequestBlock key={hold.id} elevation={3}>
                <HeaderTypography variant="h5">
                {hold.requestedByName} - {hold.courseName}
                </HeaderTypography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">
                      <strong>Student Course ID:</strong> {hold.student_course}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Start Date:</strong> {hold.start_date}
                    </Typography>
                    <Typography variant="body2">
                      <strong>End Date:</strong> {hold.end_date}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography variant="body2">
                      <strong>Requested by:</strong> {hold.requestedByName} (ID: {hold.requested_by})
                    </Typography>
                    <Typography variant="body2">
                      <strong>Created at:</strong> {new Date(hold.created_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Updated at:</strong> {new Date(hold.updated_at).toLocaleString()}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">
                      <strong>Reason:</strong> {hold.reason}
                    </Typography>
                  </Grid>
                </Grid>
                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Chip 
                    label={hold.status} 
                    color={hold.status === 'PENDING' ? 'warning' : 'success'} 
                    variant="outlined"
                  />
                  {hold.status === 'PENDING' && (
                    <Box>
                      <Button 
                        onClick={() => handleCourseHoldAction(hold.id, 'approve')} 
                        color="primary" 
                        variant="contained" 
                        size="small" 
                        style={{ marginRight: '8px' }}
                      >
                        Approve
                      </Button>
                      <Button 
                        onClick={() => handleCourseHoldAction(hold.id, 'reject')} 
                        color="secondary" 
                        variant="contained" 
                        size="small"
                      >
                        Reject
                      </Button>
                    </Box>
                  )}
                </Box>
              </RequestBlock>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCourseHoldModalOpen(false)} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

        {/* Attendance Review Modal */}
      <Dialog open={openReviewModal} onClose={() => setOpenReviewModal(false)} fullWidth maxWidth="md">
        <DialogTitle>Attendance Reviews</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Review ID</TableCell>
                <TableCell>Attendance ID</TableCell>
                <TableCell>Student</TableCell>
                <TableCell>Trainer</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Current Status</TableCell>
                <TableCell>Remark</TableCell>
                <TableCell>Review Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {attendanceReviews.map((review) => (
                <TableRow key={review.id}>
                  <TableCell>{review.id}</TableCell>
                  <TableCell>{review.attendance.id}</TableCell>
                  <TableCell>{review.attendance.student}</TableCell>
                  <TableCell>{review.trainerName}</TableCell>
                  <TableCell>{dayjs(review.attendance.date).format('MMMM D, YYYY')}</TableCell>
                  <TableCell>{review.attendance.status}</TableCell>
                  <TableCell>{review.remark}</TableCell>
                  <TableCell>{review.status}</TableCell>
                  <TableCell>
                    {review.status === 'PENDING' && (
                      <>
                        <Button 
                          onClick={() => handleProcessReview(review.id, 'APPROVED')} 
                          color="primary"
                        >
                          Approve
                        </Button>
                        <Button 
                          onClick={() => handleProcessReview(review.id, 'REJECTED')} 
                          color="secondary"
                        >
                          Reject
                        </Button>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenReviewModal(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

        {/* Student Feedback Modal */}
      <Dialog open={openFeedbackModal} onClose={handleCloseFeedbackModal} fullWidth maxWidth="md">
        <DialogTitle>Student Feedbacks</DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Course</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Topic</TableCell>
                <TableCell>Feedback</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentFeedbacks.map((feedback) => (
                <TableRow key={feedback.id}>
                  <TableCell>{feedback.studentName}</TableCell>
                  <TableCell>{feedback.courseName}</TableCell>
                  <TableCell>{feedback.feedback_type}</TableCell>
                  <TableCell>{feedback.topic}</TableCell>
                  <TableCell>{feedback.content.substring(0, 50)}...</TableCell>
                  <TableCell>{feedback.status}</TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        setSelectedFeedback(feedback);
                        setFeedbackResponse(feedback.admin_remarks || '');
                        setFeedbackStatus(feedback.status);
                      }}
                      color="primary"
                    >
                      Respond
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFeedbackModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

        {/* Create Notice Modal */}
        <Dialog open={noticeModalOpen} onClose={() => setNoticeModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>Create New Notice</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              label="Title"
              value={newNotice.title}
              onChange={(e) => setNewNotice({ ...newNotice, title: e.target.value })}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Content"
              value={newNotice.content}
              onChange={(e) => setNewNotice({ ...newNotice, content: e.target.value })}
              margin="normal"
              multiline
              rows={4}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Audience</InputLabel>
              <Select
                value={newNotice.audience}
                onChange={(e) => setNewNotice({ ...newNotice, audience: e.target.value })}
              >
                <MenuItem value="ALL">All</MenuItem>
                <MenuItem value="STUDENTS">Students</MenuItem>
                <MenuItem value="STUDENTS_TRAINERS">Students and Trainers</MenuItem>
                <MenuItem value="ADMINS_MANAGERS">Admins and Managers</MenuItem>
              </Select>
            </FormControl>
            
            <input
              accept="image/*,application/pdf"
              style={{ display: 'none' }}
              id="raised-button-file"
              multiple
              type="file"
              onChange={handleFileSelect}
            />
            <label htmlFor="raised-button-file">
              <Button variant="contained" component="span" color="primary">
                Upload Attachments
              </Button>
            </label>
            
            {newNotice.attachments.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle1">Attachments:</Typography>
                {newNotice.attachments.map((file, index) => (
                  <Chip
                    key={index}
                    label={file.name}
                    onDelete={() => handleRemoveFile(index)}
                    color="primary"
                    variant="outlined"
                    style={{ margin: '4px' }}
                  />
                ))}
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setNoticeModalOpen(false)}>Cancel</Button>
            <Button 
              onClick={handleCreateNotice} 
              color="primary"
              disabled={!newNotice.title || !newNotice.content}
            >
              Create Notice
            </Button>
          </DialogActions>
        </Dialog>

        {/* Leave Requests Modal */}
        <Dialog open={leaveRequestModalOpen} onClose={() => setLeaveRequestModalOpen(false)} fullWidth maxWidth="md">
          <DialogTitle>Leave Requests</DialogTitle>
          <DialogContent>
            <Tabs value={0} onChange={() => {}}>
              <Tab label="Pending Requests" />
              <Tab label="Request History" />
            </Tabs>
            <TabPanel value={0} index={0}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Trainer</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveRequests.map((request) => (
                    <TableRow key={request.id}>
                      <TableCell>{request.user.full_name}</TableCell>
                      <TableCell>{new Date(request.start_date).toLocaleDateString()}</TableCell>
                      <TableCell>{new Date(request.end_date).toLocaleDateString()}</TableCell>
                      <TableCell>{request.reason}</TableCell>
                      <TableCell>{request.status}</TableCell>
                      <TableCell>
                        {request.status === 'PENDING' && (
                          <Button onClick={() => setSelectedLeaveRequest(request)} color="primary">
                            Take Action
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabPanel>
            <TabPanel value={0} index={1}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Trainer</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Reason</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Admin Remarks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaveRequestHistory.map((request) => (
                    <TableRow key={request.id}>
                      <TableCell>{request.user.full_name}</TableCell>
                      <TableCell>{new Date(request.start_date).toLocaleDateString()}</TableCell>
                      <TableCell>{new Date(request.end_date).toLocaleDateString()}</TableCell>
                      <TableCell>{request.reason}</TableCell>
                      <TableCell>{request.status}</TableCell>
                      <TableCell>{request.admin_remarks}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabPanel>
          </DialogContent>
          <DialogActions>
          <Button onClick={() => setLeaveRequestModalOpen(false)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* Leave Action Dialog */}
        <Dialog open={!!selectedLeaveRequest} onClose={() => setSelectedLeaveRequest(null)} fullWidth maxWidth="sm">
          <DialogTitle>Leave Request Action</DialogTitle>
          <DialogContent>
            {selectedLeaveRequest && (
              <>
                <Typography variant="body1" gutterBottom>
                  Trainer: {selectedLeaveRequest.user.full_name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  From: {new Date(selectedLeaveRequest.start_date).toLocaleDateString()} To: {new Date(selectedLeaveRequest.end_date).toLocaleDateString()}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Reason: {selectedLeaveRequest.reason}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Remarks"
                  value={leaveRemarks}
                  onChange={(e) => setLeaveRemarks(e.target.value)}
                  margin="normal"
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedLeaveRequest(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={() => handleLeaveRequestAction('approve')} color="primary">
              Approve
            </Button>
            <Button onClick={() => handleLeaveRequestAction('reject')} color="secondary">
              Reject
            </Button>
          </DialogActions>
        </Dialog>

        {/* Feedback Response Modal */}
        <Dialog open={!!selectedFeedback} onClose={() => setSelectedFeedback(null)} fullWidth maxWidth="sm">
          <DialogTitle>Respond to Feedback</DialogTitle>
          <DialogContent>
            {selectedFeedback && (
              <>
                <Typography variant="body1" gutterBottom>
                  Student: {selectedFeedback.student.full_name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Course: {selectedFeedback.course.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Type: {selectedFeedback.feedback_type}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Topic: {selectedFeedback.topic}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  Feedback: {selectedFeedback.content}
                </Typography>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={feedbackStatus}
                    onChange={(e) => setFeedbackStatus(e.target.value)}
                  >
                    <MenuItem value="PENDING">Pending</MenuItem>
                    <MenuItem value="IN_PROGRESS">In Progress</MenuItem>
                    <MenuItem value="RESOLVED">Resolved</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  variant="outlined"
                  label="Your Response"
                  value={feedbackResponse}
                  onChange={(e) => setFeedbackResponse(e.target.value)}
                  margin="normal"
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedFeedback(null)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleRespondToFeedback} color="primary" disabled={!feedbackResponse.trim()}>
              Send Response
            </Button>
          </DialogActions>
        </Dialog> 
    {/* Attendance Review History Modal */}
    <Dialog open={reviewHistoryModalOpen} onClose={() => setReviewHistoryModalOpen(false)} fullWidth maxWidth="md">
      <DialogTitle>Attendance Review History</DialogTitle>
      <DialogContent>
        <List>
          {reviewHistory.map((review) => (
            <ListItem key={review.id}>
              <ListItemText
                primary={`Review for Student: ${review.studentName} (ID: ${review.student})`}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textPrimary">
                      Trainer: {review.trainerName} (ID: {review.trainer})<br />
                      Status: {review.status}<br />
                      Remark: {review.remark}<br />
                      Processed at: {new Date(review.processed_at).toLocaleString()}<br />
                      Processed by ID: {review.processed_by}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setReviewHistoryModalOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>

    {/* Student Feedback History Modal */}
    <Dialog open={feedbackHistoryModalOpen} onClose={() => setFeedbackHistoryModalOpen(false)} fullWidth maxWidth="md">
      <DialogTitle>Student Feedback History</DialogTitle>
      <DialogContent>
        <List>
          {feedbackHistory.map((feedback) => (
            <ListItem key={feedback.id}>
              <ListItemText
                primary={`Feedback from ${feedback.studentName} (ID: ${feedback.student})`}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textPrimary">
                      Course: {feedback.courseName} (ID: {feedback.course})<br />
                      Type: {feedback.feedback_type}<br />
                      Topic: {feedback.topic}<br />
                      Content: {feedback.content}<br />
                      Admin Remarks: {feedback.admin_remarks}<br />
                      Responded by: {feedback.responderName} (ID: {feedback.responded_by})<br />
                      Created at: {new Date(feedback.created_at).toLocaleString()}<br />
                      Resolved at: {new Date(feedback.resolved_at).toLocaleString()}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setFeedbackHistoryModalOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>

    {/* Course Hold History Modal */}
    <Dialog open={courseHoldHistoryModalOpen} onClose={() => setCourseHoldHistoryModalOpen(false)} fullWidth maxWidth="md">
      <DialogTitle>Course Hold History</DialogTitle>
      <DialogContent>
        <List>
          {courseHoldHistory.map((hold) => (
            <ListItem key={hold.id}>
              <ListItemText
                primary={`Hold for ${hold.student.full_name} (ID: ${hold.student.id})`}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="textPrimary">
                      Start Date: {hold.start_date}<br />
                      End Date: {hold.end_date}<br />
                      Reason: {hold.reason}<br />
                      Status: {hold.status}<br />
                      Created at: {new Date(hold.created_at).toLocaleString()}<br />
                      Group Course Trainers: {hold.student.group_course_trainers.map(trainer => 
                        `${trainer.full_name} (ID: ${trainer.id})`
                      ).join(', ')}
                    </Typography>
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setCourseHoldHistoryModalOpen(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default AdminDashboard;