import React, { useState, useEffect, useCallback } from 'react';
import {
  Container,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  CircularProgress,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Input,
  IconButton,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { listCoordinators, createCoordinator, updateCoordinator, deleteCoordinator } from '../api';

function CoordinatorsPage() {
  const [coordinators, setCoordinators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [openModal, setOpenModal] = useState(false);
  const [editingCoordinator, setEditingCoordinator] = useState(null);
  const [formData, setFormData] = useState({ name: '', phone: '', profile_image: null });

  const fetchCoordinators = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await listCoordinators();
      setCoordinators(Array.isArray(response) ? response : []);
    } catch (error) {
      console.error('Error fetching coordinators:', error);
      setError('Failed to fetch coordinators. Please try again later.');
      setCoordinators([]);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCoordinators();
  }, [fetchCoordinators]);

  const handleOpenModal = (coordinator = null) => {
    setEditingCoordinator(coordinator);
    setFormData(coordinator || { name: '', phone: '', profile_image: null });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditingCoordinator(null);
    setFormData({ name: '', phone: '', profile_image: null });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormData(prev => ({ ...prev, profile_image: e.target.files[0] }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const submitData = new FormData();
      submitData.append('name', formData.name);
      submitData.append('phone', formData.phone);
      if (formData.profile_image instanceof File) {
        submitData.append('profile_image', formData.profile_image);
      }

      if (editingCoordinator) {
        await updateCoordinator(editingCoordinator.id, submitData);
        setSnackbar({ open: true, message: 'Coordinator updated successfully', severity: 'success' });
      } else {
        await createCoordinator(submitData);
        setSnackbar({ open: true, message: 'Coordinator created successfully', severity: 'success' });
      }
      handleCloseModal();
      fetchCoordinators();
    } catch (error) {
      console.error('Error submitting coordinator:', error);
      setSnackbar({ open: true, message: 'Failed to submit coordinator. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this coordinator?')) {
      setLoading(true);
      try {
        await deleteCoordinator(id);
        setSnackbar({ open: true, message: 'Coordinator deleted successfully', severity: 'success' });
        fetchCoordinators();
      } catch (error) {
        console.error('Error deleting coordinator:', error);
        setSnackbar({ open: true, message: 'Failed to delete coordinator. Please try again.', severity: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          Coordinators
        </Typography>
        <Button
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal()}
          variant="contained"
          color="primary"
        >
          Add Coordinator
        </Button>
      </Box>

      {coordinators.length === 0 ? (
        <Alert severity="info">No coordinators found. Add a new coordinator to get started.</Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Profile Image</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {coordinators.map((coordinator) => (
                <TableRow key={coordinator.id}>
                  <TableCell>{coordinator.name}</TableCell>
                  <TableCell>{coordinator.phone}</TableCell>
                  <TableCell>
                    {coordinator.profile_image ? (
                      <img src={coordinator.profile_image} alt={coordinator.name} style={{ width: 50, height: 50, borderRadius: '50%' }} />
                    ) : (
                      'No image'
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpenModal(coordinator)} color="primary">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(coordinator.id)} color="error">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>{editingCoordinator ? 'Edit Coordinator' : 'Add New Coordinator'}</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Name"
              type="text"
              fullWidth
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <TextField
              margin="dense"
              name="phone"
              label="Phone"
              type="text"
              fullWidth
              value={formData.phone}
              onChange={handleInputChange}
              required
            />
            <Input
              type="file"
              onChange={handleFileChange}
              style={{ marginTop: '1rem' }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button type="submit" color="primary">
              {editingCoordinator ? 'Update' : 'Add'}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default CoordinatorsPage;