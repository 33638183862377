import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow,
  CircularProgress,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField, Alert
} from '@mui/material';
import { getCoursesEndingSoon, listCoordinators, sendMessage } from '../api';

const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric'
  }).replace(/ /g, '-');
};

const CourseCompletionPage = () => {
  const [coursesEndingSoon, setCoursesEndingSoon] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coordinators, setCoordinators] = useState([]);
  const [selectedCoordinator, setSelectedCoordinator] = useState('');
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [message, setMessage] = useState('');
  const [subject, setSubject] = useState('');
  const [sending, setSending] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [coursesResponse, coordinatorsResponse] = await Promise.all([
          getCoursesEndingSoon(),
          listCoordinators()
        ]);
        setCoursesEndingSoon(coursesResponse.data);
        setCoordinators(coordinatorsResponse);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCoordinatorChange = (event) => {
    setSelectedCoordinator(event.target.value);
  };

  const handleOpenMessageModal = (student) => {
    setSelectedStudent(student);
    setMessageModalOpen(true);
  };

  const handleSendMessage = async () => {
    if (!subject.trim() || !message.trim()) {
      setError('Both subject and message are required.');
      return;
    }
    setError('');
    setSending(true);
    try {
      await sendMessage({
        recipient: selectedStudent.id,
        subject: subject,
        body: message
      });
      handleCloseMessageModal();
      // You might want to show a success notification here
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message. Please try again.');
    }
    setSending(false);
  };

  const handleCloseMessageModal = () => {
    setMessageModalOpen(false);
    setSelectedStudent(null);
    setSubject('');
    setMessage('');
    setError('');
  };

  const filteredCourses = selectedCoordinator
    ? coursesEndingSoon.filter(student => student.coordinator && student.coordinator.id === selectedCoordinator)
    : coursesEndingSoon;

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper sx={{ p: 3, mt: 3 }}>
      <Typography variant="h4" gutterBottom>
        Courses Ending Soon
      </Typography>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="coordinator-select-label">Filter by Coordinator</InputLabel>
        <Select
          labelId="coordinator-select-label"
          id="coordinator-select"
          value={selectedCoordinator}
          label="Filter by Coordinator"
          onChange={handleCoordinatorChange}
        >
          <MenuItem value="">
            <em>All Coordinators</em>
          </MenuItem>
          {coordinators.map((coordinator) => (
            <MenuItem key={coordinator.id} value={coordinator.id}>
              {coordinator.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Student</TableCell>
              <TableCell>Coordinator</TableCell>
              <TableCell>Course</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Trainer</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredCourses.map((student) => (
              student.ending_courses.map((course, index) => (
                <TableRow key={`${student.student.id}-${index}`}>
                  {index === 0 && (
                    <TableCell rowSpan={student.ending_courses.length}>
                      {student.student.full_name}
                    </TableCell>
                  )}
                  {index === 0 && (
                    <TableCell rowSpan={student.ending_courses.length}>
                      {student.coordinator ? student.coordinator.name : 'N/A'}
                    </TableCell>
                  )}
                  <TableCell>{course.course_name}</TableCell>
                  <TableCell>{formatDate(course.end_date)}</TableCell>
                  <TableCell>{course.trainer ? course.trainer.full_name : 'N/A'}</TableCell>
                  {index === 0 && (
                    <TableCell rowSpan={student.ending_courses.length}>
                      <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handleOpenMessageModal(student.student)}
                      >
                        Send Message
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={messageModalOpen} onClose={handleCloseMessageModal} fullWidth maxWidth="sm">
        <DialogTitle>Send Message to {selectedStudent?.full_name}</DialogTitle>
        <DialogContent>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
          <TextField
            autoFocus
            margin="dense"
            id="subject"
            label="Subject"
            type="text"
            fullWidth
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            margin="dense"
            id="message"
            label="Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessageModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSendMessage} color="primary" disabled={sending}>
            {sending ? <CircularProgress size={24} /> : 'Send'}
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default CourseCompletionPage;