import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  CircularProgress,
  Button,
  TextField,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Snackbar,
  Alert,
  Avatar,ListItemIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  getUser,
  getStudentCourses,
  getStudyMaterialsForStudent,
  listStudentFeedbacks1,
  getCourseHoldHistory1,
  updateUserMe,
  getStudentAttendances,
  createCourseHold
} from '../api';
import AttendanceCalendar from '../components/AttendanceCalendar';
import { Description as DescriptionIcon } from '@mui/icons-material';


const GlowingCard = styled(Paper)(({ theme }) => ({
  background: '#1e1e1e',
  borderRadius: '10px',
  padding: '2px',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '2px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));




const CardInner = styled(Box)({
  background: '#1e1e1e',
  borderRadius: '8px',
  padding: '20px',
  position: 'relative',
  zIndex: 1,
  height: '100%',
});

const StudentDetails = () => {
    const { studentId } = useParams();
    const [studentData, setStudentData] = useState(null);
    const [courseData, setCourseData] = useState([]);
    const [studyMaterials, setStudyMaterials] = useState([]);
    const [feedbackHistory, setFeedbackHistory] = useState([]);
    const [holdHistory, setHoldHistory] = useState([]);
    const [attendanceData, setAttendanceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [updatedProfile, setUpdatedProfile] = useState({});
    const [selectedMaterial, setSelectedMaterial] = useState(null);

    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
    const [tabValue, setTabValue] = useState(0);
    const [newHold, setNewHold] = useState({ start_date: null, end_date: null, reason: '' });
  
    const fetchStudentData = useCallback(async () => {
      setLoading(true);
      setError(null);
  
      const apiCalls = [
        { call: () => getUser(studentId), setter: setStudentData },
        { call: () => getStudentCourses(studentId), setter: setCourseData },
        { call: () => getStudyMaterialsForStudent(studentId), setter: setStudyMaterials },
        { call: () => listStudentFeedbacks1(studentId), setter: setFeedbackHistory },
        { call: () => getCourseHoldHistory1(studentId), setter: setHoldHistory },
        { call: () => getStudentAttendances({ student: studentId }), setter: setAttendanceData }
      ];
  
      try {
        await Promise.all(
          apiCalls.map(async ({ call, setter }) => {
            try {
              const response = await call();
              if (response && response.data) {
                setter(response.data);
              } else {
                console.warn('No data received for one of the API calls');
                setter([]);  // Set empty array as fallback
              }
            } catch (err) {
              console.error('Error in individual API call:', err);
              setter([]);  // Set empty array on error
            }
          })
        );
      } catch (err) {
        console.error('Error fetching student data:', err);
        setError('Failed to fetch student data. Please try again later.');
      } finally {
        setLoading(false);
      }
    }, [studentId]);
  
    useEffect(() => {
      if (studentId) {
        fetchStudentData();
      }
    }, [fetchStudentData, studentId]);

    const handleEditProfile = () => {
      if (!studentData) return;
      
      setEditMode(true);
      setUpdatedProfile({
        first_name: studentData.first_name || '',
        last_name: studentData.last_name || '',
        email: studentData.email || '',
        phone: studentData.phone || '',
        city: studentData.city || '',
        about_me: studentData.about_me || '',
        date_of_birth: studentData.date_of_birth || dayjs().format('YYYY-MM-DD'),
      });
    };

    const handleMaterialClick = (material) => {
        setSelectedMaterial(material);
      };
    
      const handleCloseMaterialDialog = () => {
        setSelectedMaterial(null);
      };

    const handleSaveProfile = async () => {
      try {
        const response = await updateUserMe(updatedProfile);
        if (response && response.data) {
          setStudentData({ ...studentData, ...response.data });
          setEditMode(false);
          setSnackbar({ open: true, message: 'Profile updated successfully', severity: 'success' });
        } else {
          throw new Error('No data received from update');
        }
      } catch (err) {
        console.error('Error updating profile:', err);
        setSnackbar({ 
          open: true, 
          message: 'Failed to update profile. Please try again.', 
          severity: 'error' 
        });
      }
    };

    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    const handleApplyHold = async () => {
      try {
        const response = await createCourseHold({
          student: studentId,
          start_date: newHold.start_date.format('YYYY-MM-DD'),
          end_date: newHold.end_date.format('YYYY-MM-DD'),
          reason: newHold.reason,
        });
        if (response && response.data) {
          setHoldHistory([...holdHistory, response.data]);
          setSnackbar({ open: true, message: 'Hold applied successfully', severity: 'success' });
          setNewHold({ start_date: null, end_date: null, reason: '' });
        }
      } catch (error) {
        console.error('Error applying hold:', error);
        setSnackbar({ open: true, message: 'Failed to apply hold', severity: 'error' });
      }
    };

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Container maxWidth="sm">
          <Alert severity="error">{error}</Alert>
        </Container>
      );
    }

    return (
      <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
        <Box my={4}>
          <Typography variant="h4" gutterBottom color="white">Student Details</Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <GlowingCard>
                <CardInner>
                  <Typography variant="h6" gutterBottom>Profile</Typography>
                  <Box display="flex" justifyContent="center" mb={2}>
                    <Avatar
                      src={studentData.profile_image}
                      alt={studentData.full_name}
                      sx={{ width: 100, height: 100 }}
                    />
                  </Box>
                  {editMode ? (
                    <>
                      <TextField
                        fullWidth
                        label="First Name"
                        value={updatedProfile.first_name}
                        onChange={(e) => setUpdatedProfile({ ...updatedProfile, first_name: e.target.value })}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Last Name"
                        value={updatedProfile.last_name}
                        onChange={(e) => setUpdatedProfile({ ...updatedProfile, last_name: e.target.value })}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Email"
                        value={updatedProfile.email}
                        onChange={(e) => setUpdatedProfile({ ...updatedProfile, email: e.target.value })}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="Phone"
                        value={updatedProfile.phone}
                        onChange={(e) => setUpdatedProfile({ ...updatedProfile, phone: e.target.value })}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="City"
                        value={updatedProfile.city}
                        onChange={(e) => setUpdatedProfile({ ...updatedProfile, city: e.target.value })}
                        margin="normal"
                      />
                      <TextField
                        fullWidth
                        label="About Me"
                        value={updatedProfile.about_me}
                        onChange={(e) => setUpdatedProfile({ ...updatedProfile, about_me: e.target.value })}
                        margin="normal"
                        multiline
                        rows={3}
                      />
                      <DatePicker
                        label="Date of Birth"
                        value={dayjs(updatedProfile.date_of_birth)}
                        onChange={(date) => setUpdatedProfile({ ...updatedProfile, date_of_birth: date.format('YYYY-MM-DD') })}
                        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
                      />
                      <Button onClick={handleSaveProfile} variant="contained" color="primary" style={{ marginTop: '10px' }}>
                        Save Changes
                      </Button>
                    </>
                  ) : (
                    <>
                      <Typography><strong>Name:</strong> {`${studentData.full_name}`}</Typography>
                      <Typography><strong>Email:</strong> {studentData.email}</Typography>
                      <Typography><strong>Phone:</strong> {studentData.phone}</Typography>
                      <Typography><strong>City:</strong> {studentData.city}</Typography>
                      <Typography><strong>About:</strong> {studentData.about_me}</Typography>
                      <Typography><strong>Date of Birth:</strong> {studentData.date_of_birth ? dayjs(studentData.date_of_birth).format('MMMM D, YYYY') : 'Not set'}</Typography>
                      <Button onClick={handleEditProfile} variant="outlined" color="primary" style={{ marginTop: '10px' }}>
                        Edit Profile
                      </Button>
                    </>
                  )}
                </CardInner>
              </GlowingCard>
            </Grid>
            <Grid item xs={12} md={8}>
              <GlowingCard>
                <CardInner>
                  <Typography variant="h6" gutterBottom>Course Information</Typography>
                  <Grid container spacing={2}>
                    {courseData.map((course) => (
                      <Grid item xs={12} sm={6} key={course.id}>
                        <Box mb={2}>
                          <Typography><strong>Course:</strong> {course.course.name}</Typography>
                          <Typography><strong>Start Date:</strong> {dayjs(course.start_date).format('MMMM D, YYYY')}</Typography>
                          <Typography><strong>End Date:</strong> {dayjs(course.end_date).format('MMMM D, YYYY')}</Typography>
                          <Typography><strong>Class Time:</strong> {course.class_time || 'Not specified'}</Typography>
                          <Typography><strong>Trainer:</strong> {course.trainer ? course.trainer.full_name : 'Not assigned'}</Typography>
                          <Box mt={1}>
                            <Button 
                              variant="contained" 
                              color="primary" 
                              onClick={() => window.open(course.bbb_join_url, '_blank')}
                            >
                              Join Class Room
                            </Button>
                          </Box>
                          <Divider style={{ margin: '10px 0' }} />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardInner>
              </GlowingCard>
            </Grid>
          </Grid>
          
          <Box mt={4}>
            <Tabs value={tabValue} onChange={handleTabChange} centered>
              <Tab label="Class Content" />
              <Tab label="Study Materials" />
              <Tab label="Feedback History" />
              <Tab label="Hold History" />
              <Tab label="Attendance History" />
            </Tabs>
            
            <TabPanel value={tabValue} index={0}>
              <Typography variant="h6" gutterBottom>Class Content</Typography>
              <List>
                {attendanceData.map((attendance) => (
                  <ListItem key={attendance.id}>
                    <ListItemText
                      primary={`Date: ${dayjs(attendance.timestamp).format('MMMM D, YYYY HH:mm')}`}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="textPrimary">
                            Class Content: {attendance.class_content || 'No content available'}
                          </Typography>
                          <br />
                          <Typography component="span" variant="body2" color="textSecondary">
                            Student Feedback: {attendance.student_feedback}
                          </Typography>
                        </>
                      }
                    />
                    <Chip 
                      label={attendance.status} 
                      color={attendance.status === 'PRESENT' ? 'success' : 'default'}
                    />
                  </ListItem>
                ))}
              </List>
            </TabPanel>
            
            <TabPanel value={tabValue} index={1}>
          <Typography variant="h6" gutterBottom>Study Materials</Typography>
          <List>
            {studyMaterials.map((material) => (
              <ListItem 
                key={material.id} 
                button 
                onClick={() => handleMaterialClick(material)}
              >
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText 
                  primary={material.topic} 
                  secondary={`Available until: ${dayjs(material.expiry_date).format('MMMM D, YYYY')}`}
                />
              </ListItem>
            ))}
          </List>
        </TabPanel>
          
          <TabPanel value={tabValue} index={2}>
            <Typography variant="h6" gutterBottom>Feedback History</Typography>
            <List>
              {feedbackHistory.map((feedback) => (
                <ListItem key={feedback.id}>
                  <ListItemText
                    primary={feedback.topic}
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="textPrimary">
                          {feedback.content}
                        </Typography>
                        <br />
                        <Typography component="span" variant="body2" color="textSecondary">
                          Status: {feedback.status}
                        </Typography>
                      </>
                    }
                  />
                  <Chip label={feedback.status} color={feedback.status === 'RESOLVED' ? 'success' : 'default'} />
                </ListItem>
              ))}
            </List>
          </TabPanel>
          
          <TabPanel value={tabValue} index={3}>
            <Typography variant="h6" gutterBottom>Hold History</Typography>
            <List>
              {holdHistory.map((hold) => (
                <ListItem key={hold.id}>
                  <ListItemText
                    primary={`Hold from ${dayjs(hold.start_date).format('MMMM D, YYYY')} to ${dayjs(hold.end_date).format('MMMM D, YYYY')}`}
                    secondary={`Reason: ${hold.reason}`}
                  />
                  <Chip label={hold.status} color={hold.status === 'APPROVED' ? 'success' : 'default'} />
                </ListItem>
              ))}
            </List>
            <Box mt={2}>
              <Typography variant="h6" gutterBottom>Apply New Hold</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Hold Start Date"
                    value={newHold.start_date}
                    onChange={(date) => setNewHold({ ...newHold, start_date: date })}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label="Hold End Date"
                    value={newHold.end_date}
                    onChange={(date) => setNewHold({ ...newHold, end_date: date })}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Hold Reason"
                    value={newHold.reason}
                    onChange={(e) => setNewHold({ ...newHold, reason: e.target.value })}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button 
                    onClick={handleApplyHold} 
                    color="primary" 
                    variant="contained" 
                    disabled={!newHold.start_date || !newHold.end_date || !newHold.reason}
                  >
                    Apply Hold
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </TabPanel>
          
          <TabPanel value={tabValue} index={4}>
            <Typography variant="h6" gutterBottom>Attendance History</Typography>
            <AttendanceCalendar
              studentId={studentId}
              trainerId={courseData[0]?.trainer?.id}
              userRole="TRAINER"
              onRequestReview={() => {}}
            />
          </TabPanel>
        </Box>
      </Box>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>{/* Study Material Modal */}
      <Dialog open={Boolean(selectedMaterial)} onClose={handleCloseMaterialDialog} fullWidth maxWidth="md">
        <DialogTitle>
          {selectedMaterial?.topic}
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedMaterial?.course?.name}</Typography>
          <Typography variant="body2">
            Available until: {dayjs(selectedMaterial?.expiry_date).format('MMMM D, YYYY')}
          </Typography>
          <List>
            {selectedMaterial?.files.map((file) => (
              <ListItem key={file.id}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={file.file_name} />
                <Box>
                  {file.file_type.startsWith('image/') ? (
                    <img src={file.file} alt={file.file_name} style={{ maxWidth: '100%', maxHeight: '300px' }} />
                  ) : file.file_type === 'application/pdf' ? (
                    <iframe src={file.file} width="100%" height="500px" title={file.file_name} />
                  ) : (
                    <Typography variant="body2">Preview not available for this file type.</Typography>
                  )}
                </Box>
                <Button href={file.file} target="_blank" rel="noopener noreferrer">
                  Download
                </Button>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMaterialDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

const TabPanel = (props) => {
const { children, value, index, ...other } = props;

return (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={3}>
        {children}
      </Box>
    )}
  </div>
);
};

export default StudentDetails;