import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AttachFile, Link as LinkIcon, Delete as DeleteIcon, ZoomIn } from '@mui/icons-material';
import dayjs from 'dayjs';

const GlassCard = styled(Box)(({ theme }) => ({
  background: 'rgba(30, 30, 30, 0.5)',
  borderRadius: '10px',
  padding: '20px',
  backdropFilter: 'blur(10px)',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const NoticeExcerpt = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '16px',
  color: 'white',
});

const ViewButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  color: 'white',
  padding: '4px 12px',
  fontSize: '0.75rem',
  minWidth: 'auto',
  '&:hover': {
    background: theme.palette.primary.dark,
  },
}));

const GlassDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'rgba(30, 30, 30, 0.5)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    color: 'white',
  },
}));

const ImagePreview = styled('img')({
  maxWidth: '100%',
  maxHeight: '200px',
  objectFit: 'contain',
  marginTop: '8px',
});

const NoticeCard = ({ notices, userRole, onDelete }) => {
  const [selectedNotice, setSelectedNotice] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const handleNoticeClick = (notice) => {
    setSelectedNotice(notice);
  };

  const handleCloseNotice = () => {
    setSelectedNotice(null);
  };

  const handleDeleteClick = (event, notice) => {
    event.stopPropagation();
    setSelectedNotice(notice);
    setDeleteConfirmOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedNotice) {
      console.log('Deleting notice with ID:', selectedNotice.id);
      onDelete(selectedNotice.id);
      setDeleteConfirmOpen(false);
      setSelectedNotice(null);
    } else {
      console.error('No notice selected for deletion');
    }
  };

  const formatDate = (dateString) => {
    return dayjs(dateString).format('MMMM D, YYYY h:mm A');
  };

  const isImageFile = (filename) => {
    return /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(filename);
  };

  const handleImageClick = (url) => {
    console.log("Image clicked:", url);
    setImagePreviewUrl(url);
  };

  useEffect(() => {
    if (selectedNotice) {
      console.log("Selected Notice:", selectedNotice);
      console.log("Attachments:", selectedNotice.attachments);
    }
  }, [selectedNotice]);

 
  const isAdminOrManager = userRole === 'ADMIN' || userRole === 'MANAGER';

  useEffect(() => {
    console.log('Current user role in NoticeCard:', userRole);
    console.log('Is Admin or Manager:', isAdminOrManager);
    console.log('Delete button should be visible:', isAdminOrManager);
  }, [userRole, isAdminOrManager]);

  return (
    <GlassCard>
      <Typography variant="h6" color="white" gutterBottom>Notices</Typography>
      {notices && notices.length > 0 ? (
        <>
          {notices.slice(0, 3).map((notice) => (
            <NoticeExcerpt key={notice.id}>
              <Typography variant="body2" component="span" sx={{ flex: 1, marginRight: '8px' }}>
                {notice.title}
              </Typography>
              <Box>
                <ViewButton onClick={() => handleNoticeClick(notice)}>
                  View
                </ViewButton>
                {isAdminOrManager && (
                  <IconButton 
                    size="small" 
                    onClick={(e) => handleDeleteClick(e, notice)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </NoticeExcerpt>
          ))}
          {notices.length > 3 && (
            <Button variant="outlined" color="primary" onClick={() => handleNoticeClick(notices[3])} fullWidth>
              View More Notices
            </Button>
          )}
        </>
      ) : (
        <Typography variant="body2" color="white">No notices available</Typography>
      )}

<GlassDialog open={!!selectedNotice} onClose={handleCloseNotice} maxWidth="md" fullWidth>
        <DialogTitle>{selectedNotice?.title}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" color="textSecondary" gutterBottom>
            Posted by: {selectedNotice?.author.full_name} on {formatDate(selectedNotice?.created_at)}
          </Typography>
          <Typography variant="body1" paragraph>
            {selectedNotice?.content}
          </Typography>
          
          {selectedNotice?.attachments && selectedNotice.attachments.length > 0 && (
  <Box mt={2}>
    <Typography variant="subtitle1">Attachments:</Typography>
    <List>
      {selectedNotice.attachments.map((attachment, index) => {
        const isImage = isImageFile(attachment.image); // Check if the attachment is an image
        return (
          <ListItem key={attachment.id}>
            <ListItemIcon>
              {isImage ? <ZoomIn /> : <AttachFile />}
            </ListItemIcon>
            <ListItemText primary={`Attachment ${index + 1}`} />
            {isImage ? (
              <IconButton onClick={() => handleImageClick(attachment.image)}>
                <ZoomIn />
              </IconButton>
            ) : (
              <Button href={attachment.image} download variant="contained" color="primary">
                Download
              </Button>
            )}
          </ListItem>
        );
      })}
    </List>
    <Box mt={2} display="flex" flexWrap="wrap" gap={2}>
      {selectedNotice.attachments
        .filter(attachment => isImageFile(attachment.image))
        .map((attachment) => (
          <Box key={attachment.id} sx={{ position: 'relative', width: 100, height: 100 }}>
            <img
              src={attachment.image}
              alt={`Attachment ${attachment.id}`}
              style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
              onClick={() => handleImageClick(attachment.image)}
            />
            <Typography variant="caption" sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.5)', color: 'white', padding: '2px', textAlign: 'center' }}>
              {`Attachment ${attachment.id}`}
            </Typography>
          </Box>
        ))}
    </Box>
  </Box>
)}



          {selectedNotice?.links && selectedNotice.links.length > 0 && (
            <Box mt={2}>
              <Typography variant="subtitle1">Links:</Typography>
              <List>
                {selectedNotice.links.map((link, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <LinkIcon />
                    </ListItemIcon>
                    <ListItemText primary={link.title} />
                    <Button href={link.url} target="_blank" rel="noopener noreferrer" variant="contained" color="primary">
                      Open Link
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNotice} color="primary" variant="contained">
            Close
          </Button>
          {isAdminOrManager && (
            <Button onClick={(e) => handleDeleteClick(e, selectedNotice)} color="error" variant="contained">
              Delete
            </Button>
          )}
        </DialogActions>
      </GlassDialog>

      <Dialog open={deleteConfirmOpen} onClose={() => setDeleteConfirmOpen(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this notice?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!imagePreviewUrl} onClose={() => setImagePreviewUrl(null)} maxWidth="md" fullWidth>
        <DialogContent>
          <img src={imagePreviewUrl} alt="Preview" style={{ width: '100%', height: 'auto' }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setImagePreviewUrl(null)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </GlassCard>
  );
};

export default NoticeCard;