import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography, Grid, Chip, Divider, Box, Paper } from '@mui/material';
import { getUser } from '../api'; // Assuming you have an API function to fetch user details

const AuditDetailsModal = ({ open, onClose, audit, onAddRemarks }) => {
  const [newRemarks, setNewRemarks] = useState('');
  const [auditorName, setAuditorName] = useState('');

  useEffect(() => {
    if (audit && audit.auditor) {
      fetchAuditorName(audit.auditor);
    }
  }, [audit]);

  const fetchAuditorName = async (auditorId) => {
    try {
      const response = await getUser(auditorId);
      const userData = response.data;
      setAuditorName(`${userData.full_name}`);
    } catch (error) {
      console.error('Error fetching auditor name:', error);
      setAuditorName('Unknown');
    }
  };

  const handleAddRemarks = () => {
    onAddRemarks(audit.id, newRemarks);
    setNewRemarks('');
  };

  const renderAuditField = (label, value) => (
    <Grid item xs={4}>
      <Typography variant="body2"><strong>{label}:</strong> {value || 'N/A'}</Typography>
    </Grid>
  );

  const renderParameterChip = (label, value) => (
    <Grid item xs={12} sm={6} md={4}>
      <Chip 
        label={`${label}: ${value}`}
        size="small" 
        color={value === 'YES' ? 'success' : value === 'NO' ? 'error' : 'default'}
        sx={{ 
          width: '100%', 
          justifyContent: 'flex-start', 
          height: 'auto', 
          '& .MuiChip-label': { 
            display: 'block', 
            whiteSpace: 'normal', 
            textAlign: 'left',
            padding: '8px'
          } 
        }}
      />
    </Grid>
  );

  const getScoreColor = (score) => {
    if (score < 7) return 'error.main';
    if (score < 10) return 'warning.main';
    return 'success.main';
  };

  if (!audit) return null;

  return (
    <Dialog 
      open={open} 
      onClose={onClose} 
      maxWidth="md" 
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: 'calc(100% - 64px)',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <DialogTitle>Audit Details</DialogTitle>
      <DialogContent sx={{ overflowY: 'auto' }}>
        <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
          <Grid container spacing={2} alignItems="center">
            {renderAuditField('Audit Date', audit.audit_date ? new Date(audit.audit_date).toLocaleDateString() : 'N/A')}
            {renderAuditField('Class Date', audit.class_date ? new Date(audit.class_date).toLocaleDateString() : 'N/A')}
            {renderAuditField('Student', audit.student_name || 'Not specified')}
            {renderAuditField('Course', audit.course ? audit.course.name : 'Not specified')}
            {renderAuditField('Auditor', auditorName)}
          </Grid>
        </Paper>

        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Typography variant="h6" gutterBottom>Parameters</Typography>
            <Grid container spacing={2} justifyContent="flex-start">
              {renderParameterChip('Grammar/Theory', audit.grammar_theory_covered)}
              {renderParameterChip('Vocabulary', audit.vocabulary_covered)}
              {renderParameterChip('Speaking', audit.speaking_activity)}
              {renderParameterChip('Feedback', audit.feedback_shared)}
              {renderParameterChip('Assessment', audit.assessment_of_student)}
              {renderParameterChip('Error Rectification', audit.error_rectification_done)}
              {renderParameterChip('Webcam', audit.webcam_on)}
              {renderParameterChip('Portal', audit.class_on_portal)}
              {renderParameterChip('Login Time', audit.login_on_time)}
              {renderParameterChip('Full Duration', audit.full_class_duration)}
              {renderParameterChip('Session Time', audit.session_on_time)}
              {renderParameterChip('Whiteboard', audit.use_of_whiteboard)}
              {renderParameterChip('Study Material', audit.study_material_shared)}
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box 
              bgcolor={getScoreColor(audit.overall_score)}
              color="white"
              p={2}
              borderRadius={2}
              textAlign="center"
              width="100%"
            >
              <Typography variant="h6">Overall Score</Typography>
              <Typography variant="h2">{audit.overall_score}</Typography>
            </Box>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />

        <Typography variant="h6" gutterBottom><strong>Feedback:</strong></Typography>
        <Typography variant="body1">{audit.feedback || 'No feedback provided'}</Typography>

        {audit.trainer_remarks && (
          <Paper elevation={3} sx={{ p: 2, my: 2, bgcolor: 'background.default' }}>
            <Typography variant="h6" gutterBottom><strong>Trainer Remarks:</strong></Typography>
            <Typography variant="body1">{audit.trainer_remarks}</Typography>
          </Paper>
        )}
        
        <TextField
          fullWidth
          margin="normal"
          label="Add New Remarks"
          multiline
          rows={3}
          value={newRemarks}
          onChange={(e) => setNewRemarks(e.target.value)}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button onClick={handleAddRemarks} color="primary" disabled={!newRemarks.trim()}>
          Add Remarks
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AuditDetailsModal;