import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Paper,
  Box,
  CircularProgress,
  Alert,
  Chip,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import dayjs from 'dayjs';
import { 
  getTrainer, 
  listStudentCourses, 
  getUser, 
  getTrainerOccupation, 
  addTrainerRemarks, 
  getTrainerAudit, 
  getAttendance, 
  updateAttendance, 
  getCurrentUser, 
  getTrainerGroupCourses, 
  listTrainerAudits, 
  createTrainerAudit, changeAttendanceStatus, createAttendance
} from '../api';
import AttendanceOverviewModal from './AttendanceOverviewModal';
import UpdateSalaryModal from './UpdateSalaryModal';
import AddEditTrainerModal from '../components/AddEditTrainerModal';
import SalaryHistoryModal from './SalaryHistoryModal';
import AddAuditModal from '../components/AddAuditModal';
import AuditHistoryModal from '../components/AuditHistoryModal';
import AuditDetailsModal from '../components/AuditDetailsModal';

const GlowingCard = styled(Paper)(({ theme }) => ({
  background: '#1e1e1e',
  borderRadius: '10px',
  padding: '2px',
  position: 'relative',
  overflow: 'hidden',
  height: '100%',
  boxShadow: `
    0 0 10px rgba(0, 255, 255, 0.3),
    0 0 20px rgba(255, 0, 255, 0.3),
    0 0 30px rgba(255, 255, 0, 0.3)
  `,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderRadius: '10px',
    padding: '2px',
    background: 'linear-gradient(45deg, #00ffff, #ff00ff, #ffff00)',
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    maskComposite: 'exclude',
  },
}));

const CardInner = styled(Box)({
  background: '#1e1e1e',
  borderRadius: '8px',
  padding: '20px',
  position: 'relative',
  zIndex: 1,
  height: '100%',
});

const CardContent = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

const QRImageContainer = styled(Box)({
  position: 'relative',
  width: '100px',
  height: '100px',
  position: 'absolute',
  top: '20px',
  right: '20px',
});

const QRImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

const WarningOverlay = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(255, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '14px',
  lineHeight: '1.2',
});

const CalendarModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '900px',
  background: '#121212',
  boxShadow: '0 0 24px rgba(255, 255, 255, 0.1)',
  borderRadius: '20px',
  padding: '20px',
  display: 'flex',
  color: 'white',
}));

const DetailView = styled(Box)(({ theme }) => ({
  width: '250px',
  borderRight: `1px solid rgba(255, 255, 255, 0.1)`,
  paddingRight: '20px',
}));

const CalendarContainer = styled(Box)({
  flex: 1,
  marginLeft: '20px',
});

const DayCell = styled(Paper)(({ theme, isSelected, isToday, status }) => ({
  height: '70px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: 
    isSelected ? 'rgba(255, 255, 255, 0.2)' :
    isToday ? 'rgba(255, 255, 255, 0.1)' :
    '#1e1e1e',
  color: 'white',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
  },
}));

const WeekDayHeader = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  padding: theme.spacing(1),
  color: 'rgba(255, 255, 255, 0.7)',
}));

const StatusText = styled(Typography)(({ status }) => ({
  fontSize: '10px',
  color: 
    status === 'PRESENT' ? '#4CAF50' :
    status === 'TRAINER_ABSENT' ? '#FFC107' :
    status === 'OFF' ? '#2196F3' :
    status === 'COMP' ? '#9C27B0' :
    status === 'ABSENT' ? '#F44336' : 
    'inherit',
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: '900px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};


function AttendanceCalendarModal({ open, onClose, studentId, studentName, courseInfo, trainerId }) {
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [attendanceData, setAttendanceData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [anchorEl, setAnchorEl] = useState(null);
  const [editingDate, setEditingDate] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const fetchAttendanceData = useCallback(async () => {
    if (!studentId) {
      console.error('fetchAttendanceData called with no studentId');
      return;
    }
    setLoading(true);
    try {
      const startDate = selectedMonth.startOf('month').format('YYYY-MM-DD');
      const endDate = selectedMonth.endOf('month').format('YYYY-MM-DD');
      console.log('Fetching attendance data:', { studentId, startDate, endDate });
      const response = await getAttendance({
        student: studentId,
        start_date: startDate,
        end_date: endDate
      });
      console.log('Attendance data received:', response);
      const attendanceMap = {};
      (response?.data || []).forEach(attendance => {
        if (attendance && attendance.timestamp) {
          const date = dayjs(attendance.timestamp).format('YYYY-MM-DD');
          attendanceMap[date] = attendance;
        }
      });
      setAttendanceData(attendanceMap);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    } finally {
      setLoading(false);
    }
  }, [studentId, selectedMonth]);

  useEffect(() => {
    if (open && studentId) {
      fetchAttendanceData();
    }
  }, [open, fetchAttendanceData, studentId]);

  const handlePreviousMonth = () => {
    setSelectedMonth(prev => prev.subtract(1, 'month'));
  };

  const handleNextMonth = () => {
    setSelectedMonth(prev => prev.add(1, 'month'));
  };

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleStatusClick = (event, date) => {
    setAnchorEl(event.currentTarget);
    setEditingDate(date);
  };

  const handleStatusChange = async (newStatus) => {
    setAnchorEl(null);
    if (editingDate && studentId) {
      try {
        const date = editingDate.format('YYYY-MM-DD');
        
        // First, try to get the attendance ID for the given date
        const attendanceResponse = await getAttendance({
          student: studentId,
          date: date,
          course: courseInfo.id
        });

        const attendancePayload = {
          student: studentId,
          course: courseInfo.id,
          student_course: courseInfo.studentCourseId,
          status: newStatus,
          trainer: trainerId,
          date: date,
        };

        let response;
        if (attendanceResponse.data && attendanceResponse.data.length > 0) {
          // Update existing attendance
          const attendanceId = attendanceResponse.data[0].id;
          response = await updateAttendance(attendanceId, attendancePayload);
        } else {
          // Create new attendance
          response = await createAttendance(attendancePayload);
        }

        setAttendanceData(prev => ({
          ...prev,
          [date]: {
            ...response.data,
            status: newStatus,
          }
        }));

        setSnackbar({ open: true, message: 'Attendance updated successfully', severity: 'success' });
      } catch (error) {
        console.error('Error updating attendance:', error);
        setSnackbar({ open: true, message: 'Failed to update attendance', severity: 'error' });
      }
    }
    setEditingDate(null);
  };

  
  const renderCalendar = () => {
    const startOfMonth = selectedMonth.startOf('month');
    const endOfMonth = selectedMonth.endOf('month');
    const startDate = startOfMonth.startOf('week');
    const endDate = endOfMonth.endOf('week');

    const calendarDays = [];
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    calendarDays.push(
      <Grid container key="weekdays">
        {weekDays.map(day => (
          <Grid item xs key={day}>
            <WeekDayHeader>{day}</WeekDayHeader>
          </Grid>
        ))}
      </Grid>
    );

    let week = [];
    for (let day = startDate; day.isBefore(endDate) || day.isSame(endDate); day = day.add(1, 'day')) {
      const dateString = day.format('YYYY-MM-DD');
      const attendance = attendanceData[dateString] || {};
      const isSelected = selectedDate.isSame(day, 'day');
      const isToday = day.isSame(dayjs(), 'day');

      week.push(
        <Grid item xs key={dateString}>
          <DayCell 
            isSelected={isSelected}
            isToday={isToday}
            onClick={() => handleDateClick(day)}
          >
            <Typography variant="body2">{day.date()}</Typography>
            <StatusText 
              status={attendance.status}
              onClick={(e) => handleStatusClick(e, day)}
            >
              {attendance.status || 'N/A'}
            </StatusText>
          </DayCell>
        </Grid>
      );

      if (week.length === 7) {
        calendarDays.push(<Grid container key={day.format('YYYY-MM-DD')}>{week}</Grid>);
        week = [];
      }
    }

    if (week.length > 0) {
      calendarDays.push(<Grid container key={endDate.format('YYYY-MM-DD')}>{week}</Grid>);
    }

    return calendarDays;
  };

  const renderDetailView = () => {
    const dateString = selectedDate.format('YYYY-MM-DD');
    const attendance = attendanceData[dateString] || {};
    
    return (
      <DetailView>
        <Typography variant="h2" gutterBottom>{selectedDate.date()}</Typography>
        <Typography variant="h6" gutterBottom>{attendance.status || 'N/A'}</Typography>
        <Typography variant="body2" gutterBottom>{selectedDate.format('MMMM YYYY')}</Typography>
        <List>
          <ListItem>
            <ListItemText primary="Time" secondary={attendance.timestamp || 'N/A'} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Course" secondary={(courseInfo && courseInfo.name) || 'N/A'} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Trainer" secondary={(courseInfo && courseInfo.trainer && courseInfo.trainer.name) || 'N/A'} />
          </ListItem>
        </List>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="outlined"
          fullWidth
          style={{ marginTop: '20px', color: 'white', borderColor: 'white' }}
        >
          Add Notification
        </Button>
      </DetailView>
    );
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="attendance-calendar-modal"
      aria-describedby="attendance-calendar-for-student"
    >
      <Box sx={modalStyle}>
        <CalendarModalContent>
          {renderDetailView()}
          <CalendarContainer>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
              <IconButton onClick={handlePreviousMonth} disabled={selectedMonth.isBefore(dayjs().subtract(3, 'month'))}>
                <ArrowBackIosNewIcon style={{ color: 'white' }} />
              </IconButton>
              <Typography variant="h6" style={{ color: 'white' }}>{selectedMonth.format('MMMM YYYY')}</Typography>
              <IconButton onClick={handleNextMonth} disabled={selectedMonth.isAfter(dayjs())}>
                <ArrowForwardIosIcon style={{ color: 'white' }} />
              </IconButton>
            </Box>
            {loading ? (
              <CircularProgress style={{ color: 'white' }} />
            ) : (
              renderCalendar()
            )}
          </CalendarContainer>
          </CalendarModalContent>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleStatusChange('PRESENT')}>Present</MenuItem>
          <MenuItem onClick={() => handleStatusChange('ABSENT')}>Absent</MenuItem>
          <MenuItem onClick={() => handleStatusChange('TRAINER_ABSENT')}>Trainer Absent</MenuItem>
          <MenuItem onClick={() => handleStatusChange('OFF')}>Off</MenuItem>
          <MenuItem onClick={() => handleStatusChange('COMP')}>Comp</MenuItem>
        </Menu>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
}

function TrainersPage() {
  const [trainer, setTrainer] = useState(null);
  const [students, setStudents] = useState([]);
  const [groupCourses, setGroupCourses] = useState([]);
  const [occupation, setOccupation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attendanceModalOpen, setAttendanceModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [attendanceOverviewOpen, setAttendanceOverviewOpen] = useState(false);
  const [currentUserRole, setCurrentUserRole] = useState(null);
  const { id } = useParams();
  const [updateSalaryModalOpen, setUpdateSalaryModalOpen] = useState(false);
  const navigate = useNavigate();
  
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [salaryHistoryModalOpen, setSalaryHistoryModalOpen] = useState(false);
  const [addAuditModalOpen, setAddAuditModalOpen] = useState(false);
  const [auditDetailsModalOpen, setAuditDetailsModalOpen] = useState(false);
  const [auditHistoryModalOpen, setAuditHistoryModalOpen] = useState(false);
  const [audits, setAudits] = useState([]);
  const [selectedAudit, setSelectedAudit] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });

  const dummyQRImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/QR_Code_Example.svg/1200px-QR_Code_Example.svg.png";

  const fetchData = useCallback(async () => {
    if (!id) {
      console.error('No trainer ID provided');
      navigate('/');
      return;
    }
  
    setLoading(true);
    setError(null);
    try {
      const trainerResponse = await getTrainer(id);
      const trainer = trainerResponse.data ?? null;
      setTrainer(trainer);

      if (!trainer || !trainer.user || !trainer.user.id) {
        throw new Error('Trainer user data is missing or incomplete');
      }

      const [currentUserResponse, groupCoursesResponse, studentsResponse, occupationResponse] = await Promise.all([
        getCurrentUser(),
        getTrainerGroupCourses(trainer.user.id),
        listStudentCourses({ trainer: trainer.user.id }),
        getTrainerOccupation(trainer.user.id, new Date().toISOString().split('T')[0])
      ]);

      setCurrentUserRole(currentUserResponse.data.role);
      setGroupCourses(groupCoursesResponse.data);
      setOccupation(occupationResponse.data ?? null);

      const studentsWithUserData = await Promise.all(
        (studentsResponse.data ?? []).map(async (studentCourse) => {
          try {
            const userResponse = await getUser(studentCourse.student);
            return {
              ...studentCourse,
              studentData: userResponse.data ?? {}
            };
          } catch (error) {
            console.error(`Failed to fetch data for student ${studentCourse.student}:`, error);
            return studentCourse;
          }
        })
      );

      setStudents(studentsWithUserData);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [id, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const fetchAudits = useCallback(async () => {
    if (trainer && trainer.user && trainer.user.id) {
      try {
        const response = await listTrainerAudits(trainer.user.id);
        setAudits(response.data);
      } catch (error) {
        console.error('Error fetching audits:', error);
      }
    }
  }, [trainer]);

  useEffect(() => {
    if (trainer) {
      fetchAudits();
    }
  }, [trainer, fetchAudits]);

  const handleAddAudit = async (auditData) => {
    try {
      await createTrainerAudit({ ...auditData, trainer: trainer.user.id });
      setAddAuditModalOpen(false);
      fetchAudits();
      setSnackbar({ open: true, message: 'Audit added successfully', severity: 'success' });
    } catch (error) {
      console.error('Error adding audit:', error);
      setSnackbar({ open: true, message: 'Failed to add audit', severity: 'error' });
    }
  };

  const handleAttendanceClick = (student) => {
    console.log('handleAttendanceClick called with student:', student);
    if (student && student.studentData && student.studentData.id) {
      console.log('Opening attendance modal for student:', student);
      setSelectedStudent(student);
      setAttendanceModalOpen(true);
    } else {
      console.error('Invalid student data:', student);
      setSnackbar({ open: true, message: 'Invalid student data', severity: 'error' });
    }
  };

  const handleEditTrainer = () => {
    setEditModalOpen(true);
  };

  const handleSaveTrainer = (updatedTrainerData) => {
    setTrainer(prevTrainer => ({
      ...prevTrainer,
      ...updatedTrainerData,
      user: {
        ...prevTrainer.user,
        ...updatedTrainerData.user
      }
    }));
    fetchData();
    setSnackbar({ open: true, message: 'Trainer details updated successfully', severity: 'success' });
  };

  const handleViewAuditDetails = (audit) => {
    console.log('Viewing audit details:', audit);
    setSelectedAudit(audit);
    setAuditDetailsModalOpen(true);
  };

  const handleAddRemarks = async (auditId, remarks) => {
    console.log('Adding remarks for audit:', auditId, remarks);
    try {
      await addTrainerRemarks(auditId, remarks);
      console.log('Remarks added successfully');
      const updatedAudit = await getTrainerAudit(auditId);
      setSelectedAudit(updatedAudit.data);
      fetchAudits();
      setSnackbar({ open: true, message: 'Remarks added successfully', severity: 'success' });
    } catch (error) {
      console.error('Error adding remarks:', error);
      setSnackbar({ open: true, message: 'Failed to add remarks', severity: 'error' });
    }
  };

  const handleCloseAttendanceModal = () => {
    console.log('Closing attendance modal');
    setAttendanceModalOpen(false);
    setSelectedStudent(null);
  };

  const studentMap = students.reduce((acc, student) => {
    if (student.studentData && student.studentData.id) {
      acc[student.studentData.id] = {
        name: student.studentData.full_name || student.studentData.username || 'N/A',
        classTime: student.class_time || 'N/A'
      };
    }
    return acc;
  }, {});

  const handleOpenSalaryHistory = () => {
    setSalaryHistoryModalOpen(true);
  };

  const handleStudentClick = (studentCourse) => {
    console.log('Student clicked:', studentCourse);
    console.log('Navigating to:', `/student-details/${studentCourse.studentData.id}`);
    navigate(`/student-details/${studentCourse.studentData.id}`);
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" height="calc(100vh - 64px)">
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">{error}</Alert>
      </Container>
    );
  }

  if (!trainer) {
    return (
      <Container>
        <Alert severity="warning">No trainer data available.</Alert>
      </Container>
    );
  }

  return (
    <>
      <Container maxWidth="lg" sx={{ paddingBottom: '400px' }}>
        <Box my={4}>
          <Typography variant="h4" gutterBottom>Trainer Details</Typography>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={() => setAttendanceOverviewOpen(true)}
            sx={{ mb: 2, mr: 2 }}
          >
            Attendance Overview
          </Button>
          {(currentUserRole === 'ADMIN' || currentUserRole === 'MANAGER') && (
            <>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => setUpdateSalaryModalOpen(true)}
                sx={{ mb: 2, mr: 2 }}
              >
                Update Salary
              </Button>
              <Button 
                variant="contained" 
                color="info" 
                onClick={handleEditTrainer}
                sx={{ mb: 2, mr: 2 }}
              >
                Edit Trainer Details
              </Button>
              <Button 
                variant="contained" 
                color="success" 
                onClick={handleOpenSalaryHistory}
                sx={{ mb: 2 }}
              >
                Salary History
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setAddAuditModalOpen(true)}
                sx={{ mb: 2, mr: 2 }}
              >
                Add Audit
              </Button>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => setAuditHistoryModalOpen(true)}
                sx={{ mb: 2 }}
              >
                Audit History
              </Button>
            </>
          )}
          
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <GlowingCard>
                <CardInner>
                  <Typography variant="h6" gutterBottom>Profile</Typography>
                  <Divider style={{ marginBottom: '15px' }} />
                  <CardContent>
                    <Typography><strong>Full Name:</strong> {trainer.user?.full_name ?? 'N/A'}</Typography>
                    <Typography><strong>Email:</strong> {trainer.user?.email ?? 'N/A'}</Typography>
                    <Box>
                      <strong>Contract Type: </strong>
                      <Chip 
                        label={trainer.contract_type ?? 'N/A'}
                        color={trainer.contract_type === 'SALARIED' ? 'success' : 'error'}
                        size="small"
                      />
                    </Box>
                    <Typography><strong>Phone:</strong> {trainer.user?.phone ?? 'N/A'}</Typography>
                    <Typography><strong>Approved Hours:</strong> {trainer.approved_hours ?? 'N/A'}</Typography>
                    <Typography><strong>Occupied Hours:</strong> {occupation?.occupied_hours ?? 'N/A'}</Typography>
                  </CardContent>
                </CardInner>
              </GlowingCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <GlowingCard>
                <CardInner>
                  <Typography variant="h6" gutterBottom>Financial Details</Typography>
                  <Divider style={{ marginBottom: '15px' }} />
                  <CardContent>
                    <Typography>
                      <strong>Salary:</strong> {currentUserRole === 'MANAGER' ? 'Private' : (trainer.salary ?? 'N/A')}
                    </Typography>
                    <Typography><strong>Bank Name:</strong> {trainer.bank_name ?? 'N/A'}</Typography>
                    <Typography><strong>Account Number:</strong> {trainer.account_number ?? 'N/A'}</Typography>
                    <Typography><strong>IFSC Code:</strong> {trainer.ifsc_code ?? 'N/A'}</Typography>
                  </CardContent>
                  <QRImageContainer>
                    <QRImage 
                      src={trainer.upi_qr_image || dummyQRImage} 
                      alt="UPI QR Code" 
                    />
                    {!trainer.upi_qr_image && (
                      <WarningOverlay>
                        <Typography variant="body2">
                          NOT REAL
                          <br />
                          DO NOT SCAN
                        </Typography>
                      </WarningOverlay>
                    )}
                  </QRImageContainer>
                </CardInner>
              </GlowingCard>
            </Grid>
            <Grid item xs={12} md={4}>
              <GlowingCard>
                <CardInner>
                  <Typography variant="h6" gutterBottom>Meeting Links</Typography>
                  <Divider style={{ marginBottom: '15px' }} />
                  <CardContent>
                    <Typography><strong>Google Meet:</strong> {trainer.google_meet_link ?? 'N/A'}</Typography>
                    <Typography><strong>Zoom:</strong> {trainer.zoom_meeting_link ?? 'N/A'}</Typography>
                  </CardContent>
                </CardInner>
              </GlowingCard>
            </Grid>
          </Grid>

          <Box mt={4}>
            <Typography variant="h5" gutterBottom>Assigned Students (Individual Courses)</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>Coordinator</TableCell>
                    <TableCell>Course</TableCell>
                    <TableCell>Start Date</TableCell>
                    <TableCell>End Date</TableCell>
                    <TableCell>Class Time</TableCell>
                    <TableCell>Attendance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.length > 0 ? (
                    students.map((studentCourse) => (
                      <TableRow key={studentCourse.id}>
                        <TableCell>
                          <Button
                            variant="text"
                            onClick={() => handleStudentClick(studentCourse)}
                          >
                            {studentCourse.studentData?.full_name || `Student ID: ${studentCourse.student}`}
                          </Button>
                        </TableCell>
                        <TableCell>{studentCourse.studentData?.phone ?? 'N/A'}</TableCell>
                        <TableCell>{studentCourse.studentData?.coordinator?.name ?? 'N/A'}</TableCell>
                        <TableCell>{studentCourse.course?.name ?? 'N/A'}</TableCell>
                        <TableCell>{studentCourse.start_date ? new Date(studentCourse.start_date).toLocaleDateString() : 'N/A'}</TableCell>
                        <TableCell>{studentCourse.end_date ? new Date(studentCourse.end_date).toLocaleDateString() : 'N/A'}</TableCell>
                        <TableCell>{studentCourse.class_time ?? 'N/A'}</TableCell>
                        <TableCell>
                          <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => handleAttendanceClick(studentCourse)}
                          >
                            Attendance
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={8} align="center">No individual students assigned to this trainer</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box mt={4}>
            <Typography variant="h5" gutterBottom>Group Course Assignments</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Course Name</TableCell>
                    <TableCell>Class Time</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupCourses.length > 0 ? (
                    groupCourses.map((course) => (
                      <TableRow key={course.id}>
                        <TableCell>{course.name}</TableCell>
                        <TableCell>{course.class_time}</TableCell>
                        <TableCell>{course.class_duration}</TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => window.open(course.bbb_join_url, '_blank')}
                            disabled={!course.bbb_join_url}
                          >
                            Join Room
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4} align="center">No group courses assigned to this trainer</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
        
        <AttendanceCalendarModal
  open={attendanceModalOpen}
  onClose={handleCloseAttendanceModal}
  studentId={selectedStudent?.studentData?.id}
  studentName={selectedStudent?.studentData?.full_name || selectedStudent?.studentData?.username || 'Student'}
  courseInfo={{
    id: selectedStudent?.course?.id,
    studentCourseId: selectedStudent?.id,
    name: selectedStudent?.course?.name
  }}
  trainerId={trainer?.user?.id}
/>
        
        <AttendanceOverviewModal
          open={attendanceOverviewOpen}
          onClose={() => setAttendanceOverviewOpen(false)}
          trainerId={trainer?.user?.id}
          studentMap={studentMap}
        />
        
        <AddAuditModal
          open={addAuditModalOpen}
          onClose={() => setAddAuditModalOpen(false)}
          onSubmit={handleAddAudit}
        />
        
        <AuditHistoryModal
          open={auditHistoryModalOpen}
          onClose={() => setAuditHistoryModalOpen(false)}
          audits={audits}
          onViewDetails={handleViewAuditDetails}
        />
        
        {selectedAudit && (
          <AuditDetailsModal
            open={auditDetailsModalOpen}
            onClose={() => {
              console.log('Closing AuditDetailsModal');
              setAuditDetailsModalOpen(false);
              setSelectedAudit(null);
            }}
            audit={selectedAudit}
            onAddRemarks={handleAddRemarks}
          />
        )}
        
        <UpdateSalaryModal
          open={updateSalaryModalOpen}
          onClose={() => setUpdateSalaryModalOpen(false)}
          trainerId={trainer?.user?.id}
          trainerName={trainer?.user?.full_name}
        />
        
        <AddEditTrainerModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          trainer={trainer}
          onSave={handleSaveTrainer}
        />
        
        <SalaryHistoryModal
          open={salaryHistoryModalOpen}
          onClose={() => setSalaryHistoryModalOpen(false)}
          trainerId={trainer?.user?.id}
          trainerName={trainer?.user?.full_name}
        />

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}

export default TrainersPage;